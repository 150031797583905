import React from "react";
import {convertStringToLatex,resetTex,initMathJax} from "services/utility";
import '../../../../QuestionwiseTable.scss'

const QuestionwiseTable = (props) => {

    console.log("props_in_question_wise",props)
    //initMathJax();

    const createMarkupForOptions = (ques) => {
        var value = ques.questionText;
        if (ques.additional_info.has_latex === "Y") {
            initMathJax();
            value = convertStringToLatex(value);
            console.log('value_ques', value)
        }
        return { __html: value };
    };

    let stylesFordangerouslySetInnerHTML = { fontFamily: "Poppins" };

    return (
        <div className="px-3" style={{fontSize: "22px",fontWeight: 600,lineHeight: "21px"}}>
            <div className="row py-2 pb-4">Questions</div>
            <div className="row mt-2" style={{maxHeight: "500px",overflowY: "scroll"}}>
                <table className="table table-borderless">
                    <thead style={{background: "#F3F6F9",borderRadius: "6px",position: "sticky",top: 0, zIndex: 99}}>
                        <tr style={{font: "normal normal 600 12px/18px Poppins",color: "#B5B5C3"}}>
                            <th scope='col'>SR. NO.</th>
                            <th scope='col'>QUESTIONS</th>
                            <th scope='col'>ACCURACY</th>
                            <th scope='col'>CORRECT</th>
                            <th scope='col'>INCORRECT</th>
                            <th scope='col' />
                        </tr>
                    </thead>
                    {props.questionsList && props.questionsList?.map((ques,index) => {
                        return (
                            <tbody key={index}>
                                <tr>
                                <td style={{fontSize: "clamp(0.8rem ,2.5vw, 1rem)", fontWeight: 400}}>{index+1}</td>

                                    <td style={{fontSize: "clamp(0.8rem ,2.5vw, 1rem)", fontWeight: 400, textTransform: 'none', minWidth: '300px'}}> {ques.additional_info.has_latex === "Y" ?
                                        ques.image ?
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "flex-start",
                                                    width: "100%",
                                                }}
                                            >
                                                <div
                                                    className="qest-text"
                                                    style={{display: "flex",flexWrap: "wrap"}}
                                                />
                                                <img src={ques.image} alt="" />
                                            </div>
                                            :
                                            <div
                                                className="qest-text"
                                                dangerouslySetInnerHTML={createMarkupForOptions(ques)}
                                                style={stylesFordangerouslySetInnerHTML}
                                            />
                                        : ques.questionText} </td>
                                <td style={{fontSize: "clamp(0.8rem ,2.5vw, 1rem)", fontWeight: 400}}>{ques.accuracy.toFixed(0)}%</td>
                                <td style={{fontSize: "clamp(0.8rem ,2.5vw, 1rem)", fontWeight: 400}}>{ques.correct_responses}</td>
                                <td style={{fontSize: "clamp(0.8rem ,2.5vw, 1rem)", fontWeight: 400}}>{ques.incorrect_responses}</td>
                                <td className='pt-extra-small' style={{fontWeight: "600",color: "#F8A364", cursor: "pointer"}}>View details</td>
                                </tr>
                            </tbody>
                        )
                    })}
                </table>
            </div>
        </div>
    )
}
export default QuestionwiseTable