// import Graph from "../common/graph";
import {
  Accordion,
  AccordionContext,
  Card,
  Dropdown,
  ProgressBar,
  useAccordionButton,
} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
// import GraphForUser from "../common/graph_for_user";
import Loader from "components/common/loader";
import React from "react";
import GraphForUser from "components/common/graph_for_user";
import ChapterWiseGraph from "components/common/chapterwisegraph";
import TeacherStuChapterWiseGraph from "components/common/teacherstuchapterwisegraph";
import TeacherGraphForUser from "components/common/teacher_graph_for_user";
import {useTranslation} from "react-i18next";
import store from "../../../../../store/store";

function ReportingDashboardComponent(props) {
  const [className, setClassName] = useState(props.className);
  const [subjectName, setSubjectName] = useState(props.subjectName);

  const [dropdownLoading, setDropdownLoading] = useState(true);
  const [chapterWisePerformance, setChapterWisePerformance] = useState([]);
  const [arrayForSubjectSelection, setArrayForSubjectSelection] = useState([]);
  const [selectedTopicChapter, setselectedTopicChapter] = useState([]);
  const [selectedTopicList, setSelectedTopicList] = useState([]);

  const [youData, setYouData] = useState([]);

  var {user} = store.getState();

  const {t} = useTranslation();
  useEffect(() => {
    if (props.userDetails.length !== 0) {
      getUserChapterTopic();
    }
  }, [props.userDetails]);
  const getUserChapterTopic = async () => {
    const res = await fetch(
      `https://b7rd2d9uye.execute-api.ap-south-1.amazonaws.com/prod/chapter-topic-analysis?language=${
        user.lang_pref
      }&school_id=${props.school_id}&board_id=${
        props.userDetails.board_name
      }&grade_id=${encodeURIComponent(props.selectedGradeId)}&user_id=${
        props.userDetails.user_id
      }&subject_id=${props.selectedSubjectId}`
    );
    const data = await res.json();

    // debugger
    if (data.statusCode === 200) {
      setChapterWisePerformance([data.chapter_topic_details]);
      setDropdownLoading(false);
    }
  };
  const handleSubChange = (e, value) => {
    let original_value = [...arrayForSubjectSelection];
    let selected_chapter_listing = [...selectedTopicChapter];
    // debugger

    if (e.target.checked) {
      let chapter_info = value.chapter_performance_graph;
      chapter_info.chapter_id = value.chapter_id;
      chapter_info.translated_chapter_name = value.translated_chapter_name;
      chapter_info.type = "chapter";

      let total_topics = value.topics.length;
      let completed_topics = 0;

      value.topics.map((val, inde) => {
        if (val.topic_performance_graph.topic_performance.progress === 100) {
          completed_topics = completed_topics + 1;
        }
      });

      // let chapter_progress = (completed_topics / total_topics) * 100
      let chapter_progress = 100;
      chapter_info.chapter_performance.progress = chapter_progress;

      original_value.push(chapter_info);

      selected_chapter_listing.push({chapter_id: value.chapter_id});
      original_value = original_value.filter((value, index) => {
        return original_value[index].type !== "topic";
      });
      setSelectedTopicList([]);
    } else {
      if (original_value.filter((x) => x.type === "chapter").length === 1) {
        original_value = [];
        selected_chapter_listing = [];
        setSelectedTopicList([]);
        setYouData([]);
      } else {
        original_value.splice(
          original_value.findIndex(
            ({chapter_id}) => chapter_id == value.chapter_id
          ),
          1
        );
        selected_chapter_listing.splice(
          selected_chapter_listing.findIndex(
            ({chapter_id}) => chapter_id == value.chapter_id
          ),
          1
        );
      }
    }
    setArrayForSubjectSelection(original_value);
    setselectedTopicChapter(selected_chapter_listing);
  };

  const handleTopicChange = (e, value, chapter) => {
    let original_value = [...arrayForSubjectSelection];
    let selectedTopics = [...selectedTopicList];
    if (e.target.checked) {
      if (chapter.chapter_id !== selectedTopicChapter[0]?.chapter_id) {
        var if_chapter_selected = selectedTopicChapter.find(
          (e) => e.chapter_id === chapter.chapter_id
        );
        if (!if_chapter_selected) {
          alert("You can not select topics from the different chapter");
          return;
        }
        selectedTopics = [];
        original_value = original_value.filter((value, index) => {
          return original_value[index].type !== "topic";
        });
      }
      original_value = original_value.filter((value, index) => {
        return (
          original_value[index].chapter_id === chapter.chapter_id ||
          original_value[index].type !== "chapter"
        );
      });

      if (value.topic_name === "Treasure") {
        selectedTopics = [];
        let chapter_info = value;
        chapter_info.type = "Treasure";
        original_value.push(chapter_info);

        let data_p = original_value.filter((e) => {
          return e.type !== "topic";
        });
        original_value = data_p;

        if (
          !arrayForSubjectSelection.find(
            (e) => e.chapter_id === chapter.chapter_id
          )
        ) {
          chapter_info = chapter.chapter_performance_graph;
          chapter_info.chapter_id = chapter.chapter_id;
          chapter_info.chapter_name = chapter.chapter_name;
          chapter_info.type = "chapter";
          original_value.push(chapter_info);
        }
        setselectedTopicChapter([{chapter_id: chapter.chapter_id}]);
        selectedTopics.push(value);
      } else {
        if (selectedTopics.find((e) => e.topic_name === "Treasure")) {
          selectedTopics = [];
        }
        let chapter_info = value;
        chapter_info.type = "topic";
        original_value.push(chapter_info);

        let data_p = original_value.filter((e) => {
          return e.type !== "Treasure";
        });
        original_value = data_p;

        if (
          !arrayForSubjectSelection.find(
            (e) => e.chapter_id === chapter.chapter_id
          )
        ) {
          chapter_info = chapter.chapter_performance_graph;
          chapter_info.chapter_id = chapter.chapter_id;
          chapter_info.chapter_name = chapter.chapter_name;
          chapter_info.type = "chapter";
          original_value.push(chapter_info);
        }
        setselectedTopicChapter([{chapter_id: chapter.chapter_id}]);
        selectedTopics.push(value);
      }

      setselectedTopicChapter([{chapter_id: chapter.chapter_id}]);
      selectedTopics.push(value);
    } else {
      original_value.splice(
        original_value.findIndex(
          ({chapter_id}) => chapter_id == value.chapter_id
        ),
        1
      );
      selectedTopics.splice(
        selectedTopics.findIndex(({topic_id}) => topic_id == value.topic_id),
        1
      );
    }
    setArrayForSubjectSelection(original_value);
    setSelectedTopicList(selectedTopics);
  };

  if (className !== props.className) {
    setClassName(props.className);
  }
  if (subjectName !== props.subjectName) {
    setSubjectName(props.subjectName);
  }

  const onClickHandler = (e) => {
    const hiddenElement =
      e.currentTarget.parentElement.getElementsByClassName("collapse");
    e.target.className.indexOf("dd-arrow showing") > -1
      ? e.target.classList.remove("showing")
      : e.target.classList.add("showing");
    for (var i = 0; i < hiddenElement.length; i++) {
      hiddenElement[i].className.indexOf("collapse show") > -1
        ? hiddenElement[i].classList.remove("show")
        : hiddenElement[i].classList.add("show");
    }
  };

  const ContextAwareToggle = ({children, eventKey, callback, level}) => {
    const {activeEventKey} = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <button
        type={`button `}
        className={`${isCurrentEventKey ? "active" : ""}`}
        onClick={decoratedOnClick}
      >
        <svg
          width="18"
          height="15"
          viewBox="0 0 18 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.3932 0.60599L17.3932 5.92263L9.01212 14.4145L0.631023 5.92263L0.631023 0.605989L9.01212 9.13477L17.3932 0.60599Z"
            fill="#D1D1D1"
          />
        </svg>
      </button>
    );
  };
  const setYouDataCall = (data) => {
    // debugger
    if (youData.toString() !== data.toString()) {
      // debugger
      setYouData(data);
    }
  };

  console.log("arrayForSubjectSelection", arrayForSubjectSelection);
  return (
    <>
      <div className="container-fluid" style={{position: "relative"}}>
        {props.subjectName !== "all" && (
          <Dropdown
            className={`custom_studen-class-selection teacher  dropdown ${
              dropdownLoading ? "disabled" : ""
            } ${chapterWisePerformance.length === 0 ? "disabled" : ""}`}
          >
            <Dropdown.Toggle id="dropdown-basic">
              <span>{t("dashboard.select_chapter")}</span>
              <div className="dash_drop_btn_list">
                {dropdownLoading ? (
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="60px"
                    height="60px"
                    viewBox="0 0 80 30"
                    style={{textAlign: "center", margin: "auto"}}
                    className="m-auto text-center"
                  >
                    <circle cx={10} cy={10} r={10} fill="#f16b24">
                      <animate
                        attributeName="cx"
                        from={10}
                        to={40}
                        dur="0.5s"
                        calcMode="spline"
                        keySplines="0.42 0 0.58 1"
                        keyTimes="0;1"
                        repeatCount="indefinite"
                      />
                    </circle>
                    <circle cx={10} cy={10} r={0} fill="#e4ac13">
                      <animate
                        attributeName="r"
                        from={0}
                        to={10}
                        dur="0.5s"
                        calcMode="spline"
                        keySplines="0.42 0 0.58 1"
                        keyTimes="0;1"
                        repeatCount="indefinite"
                      />
                    </circle>
                    <circle cx={40} cy={10} r={10} fill="#f16b24">
                      <animate
                        attributeName="cx"
                        from={40}
                        to={70}
                        dur="0.5s"
                        calcMode="spline"
                        keySplines="0.42 0 0.58 1"
                        keyTimes="0;1"
                        repeatCount="indefinite"
                      />
                    </circle>
                    <circle cx={70} cy={10} r={10} fill="#f16b24">
                      <animate
                        attributeName="r"
                        from={10}
                        to={0}
                        dur="0.5s"
                        calcMode="spline"
                        keySplines="0.42 0 0.58 1"
                        keyTimes="0;1"
                        repeatCount="indefinite"
                      />
                    </circle>
                  </svg>
                ) : (
                  <svg
                    width="18"
                    height="15"
                    viewBox="0 0 18 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.3932 0.60599L17.3932 5.92263L9.01212 14.4145L0.631023 5.92263L0.631023 0.605989L9.01212 9.13477L17.3932 0.60599Z"
                      fill="#D1D1D1"
                    />
                  </svg>
                )}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Accordion defaultActiveKey="0">
                {chapterWisePerformance.map((subject, index) => {
                  return (
                    <div key={index}>
                      {subject.map((chapter, chapter_index) => {
                        let is_valid =
                          chapter.chapter_performance_graph.chapter_performance
                            .total_score === 0
                            ? false
                            : true;
                        return (
                          <Card key={chapter.chapter_name}>
                            <Card.Header>
                              <label
                                style={{fontWeight: "bold"}}
                                className={`${!is_valid ? "lil_ligher" : ""}`}
                              >
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    handleSubChange(e, chapter);
                                  }}
                                  checked={
                                    selectedTopicChapter.find(
                                      (e) => e.chapter_id === chapter.chapter_id
                                    )
                                      ? true
                                      : false
                                  }
                                  disabled={!is_valid}
                                />
                                {chapter.translated_chapter_name}
                              </label>
                              {/* <ContextAwareToggle eventKey={chapter.chapter_name} level="chapter"></ContextAwareToggle> */}
                            </Card.Header>
                            {/* <Accordion.Collapse eventKey={chapter.chapter_name}>
                                                        <Accordion defaultActiveKey="0">
                                                            {chapter.topics.map((topic, topic_index) => {
                                                                return (
                                                                    <Card key={topic.topic_name}>
                                                                        <Card.Header>
                                                                            <label className={`${topic.topic_performance_graph.topic_performance.total_score === 0 ? 'lil_ligher' : ""}`}>
                                                                                <input onChange={(e) => { handleTopicChange(e, topic, chapter) }} type="checkbox" checked={selectedTopicList.find(e => e.topic_id === topic.topic_id) ? true : false} disabled={topic.topic_performance_graph.topic_performance.total_score === 0} />
                                                                                {topic.topic_name}
                                                                            </label>
                                                                        </Card.Header>
                                                                    </Card>
                                                                )
                                                            })}
                                                        </Accordion>
                                                    </Accordion.Collapse> */}
                          </Card>
                        );
                      })}
                    </div>
                  );
                })}
              </Accordion>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {(() => {
          if (arrayForSubjectSelection.length === 0) {
            return (
              <div className="showing_results">
                {t("dashboard.overall_performance")}
              </div>
            );
          } else if (arrayForSubjectSelection.length > 0) {
            let isTopic = arrayForSubjectSelection.find(
              (e) => e.type === "topic"
            );
            if (isTopic) {
              let topic_length = arrayForSubjectSelection.filter(
                (e) => e.type === "topic"
              ).length;
              let chapter = arrayForSubjectSelection.find(
                (e) => e.type === "chapter"
              );
              let first_selected_topic = arrayForSubjectSelection.find(
                (e) => e.type === "topic"
              );
              return (
                <div>
                  {/*{t("dashboard.showing_result_for")}  {chapter.translated_chapter_name} {` > `} {first_selected_topic.topic_name} {topic_length > 1 ? " and " + (topic_length - 1) + " more" : ""}*/}
                </div>
              );
            } else {
              let isTreasure = arrayForSubjectSelection.find(
                (e) => e.type === "Treasure"
              );
              if (isTreasure) {
                return (
                  <div className="showing_results">
                    {user.lang_pref === "mr" ? (
                      <>
                        {arrayForSubjectSelection[0].translated_chapter_name}{" "}
                        {">"} धड्यासाठी परिणाम दाखवत आहे {` > Treasure`}
                      </>
                    ) : (
                      <>
                        {t("dashboard.showing_result_for")}{" "}
                        {arrayForSubjectSelection[0].translated_chapter_name}{" "}
                        {` > Treasure`}
                      </>
                    )}
                    {/*{t("dashboard.showing_result_for")} {arrayForSubjectSelection[0].translated_chapter_name} {` > Treasure`}*/}
                  </div>
                );
              } else {
                return (
                  <div className="showing_results">
                    {user.lang_pref === "mr" ? (
                      <>
                        {arrayForSubjectSelection[0].translated_chapter_name}{" "}
                        {">"} धड्यासाठी परिणाम दाखवत आहे
                      </>
                    ) : (
                      <>
                        {t("dashboard.showing_result_for")}{" "}
                        {arrayForSubjectSelection[0].translated_chapter_name}
                      </>
                    )}
                    {arrayForSubjectSelection.length > 1
                      ? " and " +
                        (arrayForSubjectSelection.length - 1) +
                        " more"
                      : ""}
                    {/*{t("dashboard.showing_result_for")} {arrayForSubjectSelection[0].translated_chapter_name} {arrayForSubjectSelection.length > 1 ? " and " + (arrayForSubjectSelection.length - 1) + " more" : ""}*/}
                  </div>
                );
              }
            }
          }
        })()}
        {arrayForSubjectSelection.length !== 0 ? (
          <TeacherStuChapterWiseGraph
            className={className}
            subjectName={props.subjectName}
            arrayForSubjectSelection={arrayForSubjectSelection}
            setYouDataCall={setYouDataCall}
            selectedBatch={props.selectedBatch}
            userDetails={props.userDetails}
          />
        ) : (
          props.userPerformanceGraph && (
            <TeacherGraphForUser
              subjectName={props.subjectName}
              userPerformanceGraph={props.userPerformanceGraph}
              userDetails={props.userDetails}
              selectedBatch={props.selectedBatch}
            />
          )
        )}
        {/* <GraphForUser */}

        {props.subjectName !== "all" && (
          <div className="performance_section">
            <div className="performance_section_heading">
              <span>
                {props.userDetails.fullname !== "" &&
                props.userDetails.fullname !== " "
                  ? props.userDetails.fullname
                  : props.userDetails.user_id}
              </span>{" "}
              {t("super_admin_dashboard.performance")}
            </div>
            {console.log(props.userDetails)}
            <div className="section_scrollable">
              <table className="table table-bordered performance_section_table">
                <thead>
                  <tr>
                    <th>{t("dashboard.chapter_name")}</th>
                    <th>{t("dashboard.total_score")}</th>
                    <th>{t("dashboard.progress")}</th>
                  </tr>
                </thead>
                {/* {console.log(chapterWisePerformance[0])} */}
                {chapterWisePerformance.length > 0 &&
                  chapterWisePerformance[0].map((val, index) => {
                    // debugger
                    // console.log(index, val.chapter_name, val.progress)
                    return (
                      <tbody>
                        <tr
                          key={"chapter-" + index}
                          className="d-down"
                          onClick={onClickHandler}
                        >
                          <td className="dd-arrow">
                            {index + 1}.{" "}
                            {val.translated_chapter_name.toLowerCase()}
                            <span>
                              {/* <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.1767 0.612128L12.1767 4.30802L6.35059 10.2112L0.524434 4.30801L0.524434 0.612127L6.35059 6.54095L12.1767 0.612128Z" fill="#9C9C9C"></path>
                                                        </svg> */}
                            </span>
                          </td>
                          <td>
                            <div className="progressandper">
                              <span>
                                {Math.floor(
                                  val.chapter_performance_graph
                                    .chapter_performance.total_score
                                )}
                                %
                              </span>
                              <ProgressBar
                                now={
                                  val.chapter_performance_graph
                                    .chapter_performance.total_score
                                }
                              />
                            </div>
                          </td>
                          {val.chapter_performance_graph.chapter_performance
                            .total_score > 0 ? (
                            <td>
                              <div className="progressandper">
                                <span>100%</span>
                                <ProgressBar now={100} />
                              </div>
                            </td>
                          ) : (
                            <td>
                              <div className="progressandper">
                                <span>
                                  {Math.floor(
                                    val.chapter_performance_graph
                                      .chapter_performance.progress
                                  )}
                                  %
                                </span>
                                <ProgressBar
                                  now={
                                    val.chapter_performance_graph
                                      .chapter_performance.progress
                                  }
                                />
                              </div>
                            </td>
                          )}
                        </tr>
                        {val.topics.map((tval, tindex) => {
                          return (
                            <tr key={"topic-" + tindex} className="collapse">
                              <td>
                                {index + 1}.{tindex + 1}.{" "}
                                {tval.topic_name.toLowerCase()}
                              </td>
                              <td>
                                <div className="progressandper">
                                  <span>
                                    {Math.floor(
                                      tval.topic_performance_graph
                                        .topic_performance.total_score
                                    )}
                                    %
                                  </span>
                                  <ProgressBar
                                    now={
                                      tval.topic_performance_graph
                                        .topic_performance.total_score
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="progressandper">
                                  <span>
                                    {Math.floor(
                                      tval.topic_performance_graph
                                        .topic_performance.progress
                                    )}
                                    %
                                  </span>
                                  <ProgressBar
                                    now={
                                      tval.topic_performance_graph
                                        .topic_performance.progress
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    );
                  })}
              </table>
            </div>
          </div>
        )}
        {/* <Loader showLoader={props.showLoader} /> */}
      </div>
    </>
  );
}
export default ReportingDashboardComponent;
