import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { showErrorMessage } from "services/utility";
import { withTranslation } from "react-i18next";
import '../onboarding.scss'
export class SelectBoard extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    selectedBoard: "",
    spinnerForFeed: this.props.spinnerForFeed,
  };

  spinnerState() {
    this.setState({ spinnerForFeed: true });
  }

  onProceed(e) {
    e.preventDefault();
    if (this.state.selectedBoard.length > 0) {
      this.spinnerState();
      this.props.selectedboard(this.state.selectedBoard);
    } else {
      showErrorMessage(
        this.props.t("onboarding.select_board.please_select_board"),
        1
      );
    }
  }

  render() {
    let selectedBoardState = (value) => {
      this.setState({ selectedBoard: value });
    };

    let disabledButton={ height: "40px", backgroundColor: "#A5A5A5", border: "none", borderRadius: "8px"}
    let enabledButton={ height: "40px", backgroundColor: "#FC8643", cursor: "pointer", border: "none", borderRadius: "8px", color: "#ffffff"}
    return (
      <div className="uk-cover-container uk-flex uk-flex-center uk-flex-middle uk-overflow-hidden uk-light">
        <div className="uk-flex uk-flex-center uk-flex-middle uk-position-z-index">
        <div style={{width: "min(90vw, 400px)",borderRadius: "2rem",backgroundColor: "#fff",padding: "0.7rem 2rem"}}>
          <div className='text-left my-4' style={{font: "normal normal 500 1.2rem/1.6rem Poppins",color: "#FC8643"}}>Select Board</div>
          <div style={{display: "grid",placeItems:"center"}}>
              {["CBSE"].map((element,i) => {
                return (
                  <div
                    key={i}
                    //style={{ border: "1px solid #969696",borderRadius: "8px", height: "2.8rem", position: "relative", display: "grid", placeItems: "center"}}
                    onClick={(e) => {
                      selectedBoardState(element)
                    }}
                    className="input_label_container"
                  >
                    <input
                      type="radio"
                      name="radio1"
                      id={element}
                      defaultValue={element}
                    />
                    <label className="mb-0 label_styles px-3" htmlFor={element} 
                    //style={{color: "#767676", border: "none", display: "grid", placeItems: "center", width: "100%", height: "100%"}}
                    >{element}</label>
                  </div>
                )
              })}
          </div>
          {!this.props.spinnerForFeed ? 
                <button
                  type="submit"
                  className="w-100 mt-3"
                  style={this.state.selectedBoard.length >0 ? enabledButton : disabledButton }
                  disabled={this.state.selectedBoard.length >0 ? false : true }
                  onClick={(e) => {this.onProceed(e)}}
                >
                  Proceed
                </button>
                :
                <div className="text-center mt-2" style={{height: "3rem", borderRadius: "8px"}}>
                  <Spinner
                    animation="border"
                    style={{ color: "#1b1f5f" }}
                    size="lg"
                  />
                </div>
              }
              <div className='mt-3' style={{font: "normal normal 600 0.7rem/1.2rem Poppins", color: "#2B2B2B"}}>
                Please choose your school's board carefully because you cannot change this later.
              </div>
        </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SelectBoard);
