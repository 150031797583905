import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { showErrorMessage } from "services/utility";
import { withTranslation } from "react-i18next";
import '../onboarding.scss'
export class SelectGrades extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    selectedBatch: "",
    spinnerForFeed: this.props.spinnerForFeed,
  };
  // componentWillReceiveProps() {
  //   this.setState({ spinnerForFeed: this.props.spinnerForFeed });
  // }


  spinnerState() {
    this.setState({ spinnerForFeed: true });
  }

  onProceed(e) {
    e.preventDefault();
    if (this.state.selectedBatch.length > 0) {
      this.spinnerState();
      this.props.selectedbatch(this.state.selectedBatch);
    } else {
      showErrorMessage(
        this.props.t("onboarding.select_grade.please_select_grade"),
        1
      );
    }
  }

  disabledButton = { height: "40px", backgroundColor: "#A5A5A5", border: "none", borderRadius: "8px" }
  enabledButton = { height: "40px", backgroundColor: "#FC8643", cursor: "pointer", border: "none", borderRadius: "8px", color: "#ffffff" }

  render() {
    let selectedBatchState = (value) => {
      this.setState({ selectedBatch: value });
    };
    var batchArr = []
    if (this.props.selectedSchool == "4442") {
      batchArr = ["MA", "MB", "MC", "MD", "ME", "MF"];
    } else {
      batchArr = ["WA", "WB", "WC"];
    }

    return (
      <div className="uk-cover-container uk-flex uk-flex-center uk-flex-middle uk-overflow-hidden uk-light">
        <div className="uk-flex uk-flex-center uk-flex-middle uk-position-z-index">
          <div style={{ width: "min(90vw, 400px)", borderRadius: "2rem", backgroundColor: "#fff", padding: "0.7rem 2rem" }}>
            <div className='text-left my-4' style={{ font: "normal normal 500 1.2rem/1.6rem Poppins", color: "#FC8643" }}>Select Batch</div>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(4,1fr)", gap: '0.5rem' }}>
              {batchArr.map((element, i) => {
                return (
                  <div
                    key={i}
                    //style={{ border: "1px solid #969696",borderRadius: "8px", height: "2.8rem", position: "relative", display: "grid", placeItems: "center"}}
                    onClick={(e) => {
                      selectedBatchState(element)
                    }}
                    className="input_label_container"
                  >
                    <input
                      type="radio"
                      name="radio1"
                      id={element}
                      defaultValue={element}
                    />
                    <label className="mb-0 label_styles" htmlFor={element}
                    //style={{color: "#767676", border: "none", display: "grid", placeItems: "center", width: "100%", height: "100%"}}
                    >{element}</label>
                  </div>
                )
              })}
            </div>
            {!this.props.spinnerForFeed ?
              <button
                type="submit"
                className="w-100 mt-3"
                style={this.state.selectedBatch.length > 0 ? this.enabledButton : this.disabledButton}
                disabled={this.state.selectedBatch.length > 0 ? false : true}
                onClick={(e) => { this.onProceed(e) }}
              >
                Proceed
              </button>
              :
              <div className="text-center mt-2" style={{ height: "3rem", borderRadius: "8px" }}>
                <Spinner
                  animation="border"
                  style={{ color: "#1b1f5f" }}
                  size="lg"
                />
              </div>
            }
            <div className='mt-3' style={{ font: "normal normal 600 0.7rem/1.2rem Poppins", color: "#2B2B2B" }}>
              Please select the batch you want to access. Choose carefully because you cannot change this later.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SelectGrades);
