import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers";
import logger from "redux-logger";
import freeze from "redux-freeze";
import promise from "redux-promise-middleware";
import { secureStorage } from "services/utility";
import CircularJSON from "circular-json";
import throttle from "lodash/throttle";

// let middlewares = [];

// //for promises, since we are using axios for networking
// middlewares.push(promise);

// // for async operations, network calls
// middlewares.push(thunk);

// //smart console logging of actions
// middlewares.push(logger);

// // add freeze dev middleware
// // this prevents state from being mutated anywhere in the app during dev
// if (process.env.NODE_ENV !== "production") {
//     middlewares.push(freeze);
// }

// saves the current state of the application to localstorage for preventing state to be lost
function saveStateTOLocalStorage(state) {
  const serilizedState = CircularJSON.stringify(state);
  secureStorage.setItem("reduxstate", serilizedState);
}

function loadFromLocalStorage() {
  const serilizedState = secureStorage.getItem("reduxstate");
  if (serilizedState === null) return undefined;
  return typeof serilizedState === "object"
    ? serilizedState
    : JSON.parse(serilizedState);
}

const persistedState = loadFromLocalStorage();

const middleware = window.__REDUX_DEVTOOLS_EXTENSION__
  ? compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__())
  : applyMiddleware(thunk);

// Note: this API requires redux@>=3.1.0
const store = createStore(rootReducer, persistedState, middleware);

store.subscribe(() => {
    saveStateTOLocalStorage(store.getState())
});

export default store;
