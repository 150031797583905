import React,{useEffect,useState} from 'react'
import {showErrorMessage} from "services/utility";
import api from "services/api";
import config from "pwaConfig.json";
import {Spinner} from 'reactstrap'
import {withTranslation} from "react-i18next";
import logo_dark from "assets/images/logo/logo-dark.svg";

const SelectSchoolNew = (props) => {
  const [selectedSchool,setSelectedSchool] = useState("")
  const [spinnerForFeed,setSpinnerForFeed] = useState(false)
  const [allSchools,setAllSchools] = useState([])

  useEffect(() => {
    getSchools()
  },[])

  const getSchools = async () => {
    setSpinnerForFeed(true)
    try {
      await api.get(`${config.apiEndPoint}/schoollist?schoolChainId=${config.schoolChainId}`).then((res) => {
        setAllSchools([])
        let sortedArray = res.data.sort((a,b) => {
          if(a.school_name < b.school_name) {
            return -1
          }
        })
        setAllSchools(sortedArray)
        setSpinnerForFeed(false)
      })
    }
    catch(e) {
      setSpinnerForFeed(true)
    }
  }

  console.log("allSchools_list",allSchools)

  const handleSelectSchool = (e) => {
    console.log("selected_school",e.target.value)
    setSelectedSchool(e.target.value)
  }

  const handleSchoolSelection = (e) => {
    let selectedSchoolId = allSchools.find((sch) => sch.school_name === e.target.value)
    setSelectedSchool(selectedSchoolId?.id)
  }

  const onProceed = (e) => {
    e.preventDefault()
    if(selectedSchool !== "") {
      setSpinnerForFeed(true)
      props.selectedSchoolFunc(selectedSchool)
    } else {
      showErrorMessage("Kindly select a school",3);
    }
  }

  let disabledButton = {height: "40px",backgroundColor: "#A5A5A5",border: "none",borderRadius: "8px"}
  let enabledButton = {height: "40px",backgroundColor: "#FC8643",cursor: "pointer",border: "none",borderRadius: "8px",color: "#ffffff"}

  console.log("selectedSchool_is",selectedSchool)

  return (
    <div>
      <div className="row no-gutters">
        <div className="col-md-12 no-gutters" style={{display: "grid",placeItems: "center"}}>
          <div style={{width: "min(90vw, 400px)",backgroundColor: "#fff",borderRadius: "1.5rem"}}>
            <div className="row no-gutters my-auto ">
              <div className="cardtitle col-sm-7 mx-2">Select School</div>
            </div>
            <div className="row m-2 justify-content-center">
              <div className="col-sm-10 ">
                <div className="form-group ">
                  <div>
                    <form onSubmit={onProceed}>
                      <div className="d-flex justify-content-start" style={{color: "#767676",font: "normal normal 400 0.7rem/1.2rem Poppins"}}>School</div>
                      {/*<select onChange={handleSelectSchool} style={{ padding: "0.5rem 0.5rem", marginBottom: "1rem", marginInline: "auto", width: "100%", color: "black", borderRadius: "8px", height: "2.5rem" }}>
                        <option selected disabled>Select </option>
                        {allSchools.map((d) => {
                          return (
                            <option key={d.id} value={d.id}>{d.school_name}</option>
                          )
                        })}
                      </select>*/}
                      {/*<input
                        list="schools"
                        placeholder='Search school'
                        onChange={(e) => handleSchoolSelection(e)}
                        style={{padding: "0.5rem",marginBottom: "1rem",marginInline: "auto",width: "100%",color: "black",borderRadius: "8px",height: "2.5rem",border: "1px solid #969696"}} />
                      <datalist id="schools">
                        {allSchools.map((d) => {
                          return (
                            <option key={d.id}>{d.school_name}</option>
                          )
                        })}
                      </datalist>*/}
                      <select 
                        onChange={(e) => handleSchoolSelection(e)}
                        style={{padding: "0.5rem",marginBottom: "1rem",marginInline: "auto",width: "100%",color: "black",borderRadius: "8px",height: "2.5rem",border: "1px solid #969696"}}
                      >
                         <option disabled selected>Select School</option>
                          {allSchools.map((d) => {
                          return (
                            <option key={d.id}>{d.school_name}</option>
                          )
                        })}
                        </select>
                      {!spinnerForFeed && (
                        <button
                          type="submit"
                          className="w-100 mt-3"
                          style={selectedSchool ? enabledButton : disabledButton}
                          disabled={selectedSchool ? false : true}
                        >
                          Proceed
                        </button>
                      )}
                      {spinnerForFeed && (
                        <div className="text-center mt-2">
                          <Spinner
                            animation="border"
                            style={{color: "#1b1f5f"}}
                            size="lg"
                          />
                        </div>
                      )}

                    </form>

                    <div className='mt-3' style={{font: "normal normal 600 0.7rem/1.2rem Poppins",color: "#2B2B2B"}}>
                      Please choose your school's name carefully because you cannot change this later.
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(SelectSchoolNew)