import React, { Fragment } from "react";
import html from "is-html";
import Interweave from "interweave";
import { parse, stringify } from "qs";
import queryString from "query-string";
import secureStorage from "./secureStorage";
import config from "pwaConfig";
import { message, notification } from "antd";
import { remove as removeUser } from "reducers/user/actions";
import { remove as removeDataContent } from "reducers/DataContent/actions";
import store from "./../store/store";
import { login } from "./../reducers/auth/actions";
import setAuthToken from "../actions/setAuthToken";
import { setGlobal } from "reactn";
import {
  getSelectedBoardAndGradeFromLocal,
} from "services/dataServices/userProfileService";
import {
  getUserContentIfAvailable,
  saveSubjectDataToLocalStorage,
  saveFeedRailDataToLocalStorage,
  getLRSContent,
  saveLRSSyncDataToLocalStorage,
} from "services/dataServices/userContentService";
import hmacSHA256 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";
import moment from "moment";
import { sign } from "tweetnacl";
import api from "services/api";

import biologyImg from "../assets/images/default/biology.svg";
import chemistryImg from "../assets/images/default/chemistry.svg";
import mathsImg from "../assets/images/default/maths.svg";
import physicsImg from "../assets/images/default/physics.svg";
import scienceImg from "../assets/images/default/science.svg";
import cyberImg from "../assets/images/default/cyber.svg";
import englishImg from "../assets/images/default/english.svg";
import historyImg from "../assets/images/default/history.svg";
import economicsImg from "../assets/images/default/economics.svg";
import geographyImg from "../assets/images/default/geography.svg";
import politicalImg from "../assets/images/default/political.svg";
import cricketImg from "../assets/images/default/cricket.svg";
import sampleFeedImg from "../assets/images/sample-quiz.svg";
import pro1 from "../assets/images/profile/pro-1.png";
import pro2 from "../assets/images/profile/pro-2.png";
import pro3 from "../assets/images/profile/pro-3.png";
import pro4 from "../assets/images/profile/pro-4.png";
import pro5 from "../assets/images/profile/pro-5.png";
import pro6 from "../assets/images/profile/pro-6.png";
import pro7 from "../assets/images/profile/pro-7.png";
import pro8 from "../assets/images/profile/pro-8.png";

export * from "./secureStorage";

message.config({
  top: 70,
  duration: 2,
  maxCount: 3,
  rtl: true,
});



export const calculateXPs=()=>{
  let state=store.getState();
  let user=state.user;

  var xp_points = 0

    if (user.subjectDetails) {
      for (let i = 0; i < user.subjectDetails.length; i++) {
        if (user.subjectDetails[i].chapter) {
          for (let j = 0; j < user.subjectDetails[i].chapter.length; j++) {
            if (user.subjectDetails[i].chapter[j].topic) {
              xp_points =
                xp_points + user.subjectDetails[i].chapter[j].topic.length * 15

              if (user.subjectDetails[i].chapter[j].knowledge_currency > 0) {
                xp_points = xp_points + 100
              }
            }
          }
        }
      }
    }

    return xp_points
}

export const calculateDiamonds=()=>{
  let state=store.getState();
  let user=state.user;
  var diamonds = 0
    if (user.subjectDetails) {
      for (let i = 0; i < user.subjectDetails.length; i++) {
        if (user.subjectDetails[i].chapter) {
          for (let j = 0; j < user.subjectDetails[i].chapter.length; j++) {
            if (user.subjectDetails[i].chapter[j].topic) {
              for (
                let k = 0;
                k < user.subjectDetails[i].chapter[j].topic.length;
                k++
              ) {
                if (
                  user.subjectDetails[i].chapter[j].topic[k].learning_currency
                ) {
                  diamonds =
                    diamonds +
                    Math.round(
                      user.subjectDetails[i].chapter[j].topic[k]
                        .learning_currency / 10
                    )
                }
              }
              if (user.subjectDetails[i].chapter[j].knowledge_currency > 0) {
                diamonds =
                  diamonds +
                  Math.round(
                    (user.subjectDetails[i].chapter[j].knowledge_currency *
                      user.subjectDetails[i].chapter[j].topic.length * 4) /
                      10
                  )
              }
            }
          }
        }
      }
    }

    return diamonds
}



export const clearAllStoreData = () => {
  store.dispatch(removeUser());
  store.dispatch(removeDataContent());
  // store.dispatch({});
};
export const getEncodedJWTToken = (payload) => {
  var jwt = require("jsonwebtoken");
  var token = jwt.sign(payload, config.jwtSecretKey);
  return token;
};

export const showSuccessMessage = (msg, duration = 3) => {
  return message.success(msg, duration);
};

export const showErrorMessage = (msg, duration = 5) => {
  return message.error(msg, duration);
};

export const showWarningMessage = (msg, duration = 3) => {
  return message.warning(msg, duration);
};

export const showNoInternetAlert = () => {
  showWarningMessage("You are offline", 0.5);
};

export const showInternetAlert = () => {
  showSuccessMessage("You are connected to Internet", 0.5);
};

export const parseQueryParams = (str) =>
  parse(str, { ignoreQueryPrefix: true });

// export const checkChallengeString = (challenge, string) => {
//   if (!challenge || !string) return false;

//   const hasher = crypto.getHasher("md5");
//   const hash = crypto.hmac(config.secret, string, hasher);

//   // console.log({ challenge, string, hash });
//   return challenge === hash;
// };

export const titleCase = (str) => {
  if (!str) return "";
  return str.toLowerCase().replace(/(^|\s)\S/g, (t) => t.toUpperCase());
};

export const getLanguages = () => {
  const langs = [
    {
      label: "English",
      value: "en",
    },
    {
      label: "Marathi",
      value: "mr",
    },
    {
      label: "Gujarati",
      value: "gu",
    },
    {
      label: "Hindi",
      value: "hi",
    },
    {
      label: "Tamil",
      value: "ta",
    },
    {
      label: "Telugu",
      value: "te",
    },
  ];
  const langsAvailable = ["en", "mr", "gu", "hi", "ta", "te"];

  return langs;
};

export const getAvailableLanguages = () => {
  const langs = ["en", "mr", "gu", "hi", "ta", "te"];

  return langs;
};

export const parseLanguage = (lang = "en") => {
  const languages = getLanguages();
  return languages.find((l) => l.value === lang)?.label || "English";
};

export const checkLanguagePresence = (lang) => {
  const languages = getAvailableLanguages();
  return languages.includes(lang);
};

// export const showSuccessMessage = (msg, duration = 3) => {
//   return message.success(msg, duration);
// };

// export const showErrorMessage = (msg, duration = 5) => {
//   return message.error(msg, duration);
// };

// export const showWarningMessage = (msg, duration = 3) => {
//   return message.warning(msg, duration);
// };

// export const showNoInternetAlert = () => {
//   showWarningMessage("You are offline", 0.5);
// };

// export const showInternetAlert = () => {
//   showSuccessMessage("You are connected to Internet", 0.5);
// };

export const showNotification = (
  msg,
  desc,
  duration = 3,
  placement = "topLeft"
) => {
  return notification.info({
    message: `${msg}`,
    description: `${desc}`,
    placement,
    duration,
  });
};

export const getUTFDecodedText = (text, isHtml = true) => {
  let htmlCheck = html(text);
  if (text && text !== undefined && text != "") {
    var unicodeConverter = /\\u([\d\w]{4})/gi;
    var text = text.replace(unicodeConverter, function (match, grp) {
      return String.fromCharCode(parseInt(grp, 16));

      //
    });
    let htmlCheck = html(text);
    //console.log("Return", text);
    if (htmlCheck) {
      // return <div dangerouslySetInnerHTML={{ __html: unescape(text) }}></div>;
      return (
        <Interweave
          content={text
            .replace(/\\n/g, " ")
            .replace(/\\r/g, " ")
            .replace(/\\t/g, " ")
            .replace(/&colon/g, ":")}
        />
      );
    } else {
      return text
        .replace(/\\n/g, " ")
        .replace(/\\r/g, " ")
        .replace(/\\t/g, " ")
        .replace(/&colon/g, ":");
    }
  }
  if (htmlCheck) {
    return <Interweave content={text} />;
  } else {
    return text;
  }
};

export const htmlParser = (text = "") => {
  return;
  // reactHtmlParser(
  getUTFDecodedText(text, false);
  // );
};

// // export const getEncodedJWTToken = (payload) => {
// //   var jwt = require("jsonwebtoken");
// //   var token = jwt.sign(payload, config.jwtSecretKey);
// //   return token;
// // };
export const getDecodedJWTToken = (token) => {
  var jwt = require("jsonwebtoken");
  // let token1 =
  // ("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX21vYmlsZSI6IjgyNzU1ODM0MTEiLCJjb3VudHJ5X2NvZGUiOiI5MSIsInNjaG9vbF9pZCI6MTU3NSwiaWF0IjoxNjA0MDQ4ODYzfQ.2_7E0vb6ysck7zCcaBSTrlbHPJaL0s9hEbrphwwuZEA");
  //console.log("$$#token", token);
  var decoded = jwt.decode(token);

  // console.log("decoded", decoded);
  if (decoded) return decoded;

  return "";
};
export const loginUserWithToken = async (token) => {
  let {
    country_code = "",
    school_id = "",
    user_mobile = "",
    grade_name = "",
    board_name = "",
    lang_pref = "",
    user_email="",
    first_name="",
    last_name=""
  } = await getDecodedJWTToken(token);

  if (String(school_id)?.length > 0 && String(user_mobile)?.length > 0) {
    //console.log("$$#", country_code, school_id, user_mobile);
    const inputData = {
      user_mobile: user_mobile,
      country_code: country_code,
      school_id: school_id,
      grade_name: grade_name,
      board_name:board_name,
      lang_pref: lang_pref,
      user_email:user_email,
      first_name:first_name,
      last_name:last_name,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    const userProfileData = await api.post(
      `${config.apiEndPoint}/user`,
      inputData,
      {
        headers: headers,
      }
    );

    try {
      let response = await api.post(
        `${config.apiEndPoint}/sync_lrs_mongo_to_elastic`,
        userProfileData.id,
        {
          headers:  {"Content-Type": "application/json"},
        })
        console.log("response_newAPI",response)
        debugger;
      }
      catch (error) {
         console.log("err -", error);
      }
      
    secureStorage.setItem("token", token);
    setAuthToken(token);
    setGlobal({ token: token });
    // store.dispatch(initIO(token));
    setGlobal({ user: userProfileData });
    store.dispatch(login({ user: userProfileData }));
    if (!window.sessionStorage.getItem("isloggedinWithToken")) {
      window.sessionStorage.setItem("isloggedinWithToken", "true");
    }
    await apiCallsForTokenLogin();
    return true;
  } else {
    return false;
  }
};

export const apiCallsForTokenLogin = async () => {
  const userData = await getSelectedBoardAndGradeFromLocal();
  //console.log("userData", userData);
  if (userData) {
    const queryString =
      userData.selectedBoard +
      `&gradeId=` +
      encodeURIComponent(userData.selectedGrade) +
      `&schoolId=` +
      userData.selectedSchool +
      `&language=` +
      userData.selectedLanguage +
      `&platform=web`;

    //`${userData.selectedBoard}&gradeId=${userData.selectedGrade}&schoolId=${schoolListData[0].id}&language=${userData.selectedLanguage}`;
    //console.log("queryString", queryString);

    localStorage.setItem("I18N_LANGUAGE", userData.selectedLanguage);
    // this.props.i18n.changeLanguage(this.state.selectedLanguage);
    const subjectontentData = await getUserContentIfAvailable(queryString);
    //console.log("subjectontentData", subjectontentData);
    if (subjectontentData) {
      if (
        subjectontentData.content.subjects &&
        subjectontentData.content.subjects.length != 0
      ) {
        window.mainData = subjectontentData.content.subjects;
        //this.props.save(subjectontentData.content.subjects);
        await saveSubjectDataToLocalStorage(subjectontentData.content.subjects);
        subjectontentData.feed.items.forEach((element, index) => {
          let indexObject = {
            index: index,
          };
          let newElement = Object.assign(element, indexObject);
          return newElement;
        });
        await saveFeedRailDataToLocalStorage(subjectontentData.feed.items);
      } else {
        alert("Sorry, no data available for the selected grade");
        // alert(this.props.t("onboarding.otp_login.no_grade_available"));
        window.location.replace("/nocontent");
        //this.navigateToSelectGrade();
      }
    } else {
      alert("Sorry, no data available for the selected grade");
      // alert(this.props.t("onboarding.otp_login.no_grade_available"));
      window.location.replace("/nocontent");
      //this.navigateToSelectGrade();
    }
    const queryStringForLRS =
      `&user_id=` +
      userData.userId +
      `&board_name=` +
      userData.selectedBoard +
      `&grade_name=` +
      encodeURIComponent(userData.selectedGrade);
    const lrsSyncData = await getLRSContent(queryStringForLRS);
    await saveLRSSyncDataToLocalStorage(lrsSyncData.data.body, true);
  } else {
    alert("Try again");
  }
};

export const handleExternalLinks = (link, type, props) => {
  props.history.push("/displayLink", { url: link, type: type });
};

export function selectLanguageConvert(language) {
  return parseLanguage(language);
  // switch (language) {
  //   case "en":
  //     return "English";
  //     break;
  //   case "mr":
  //     return "Marathi";
  //     break;
  //   case "gu":
  //     return "Gujarathi";
  //     break;
  //   case "hi":
  //     return "Hindi";
  //     break;
  //   case "ta":
  //     return "Tamil";
  //     break;
  //   case "te":
  //     return "Telgu";
  //     break;
  //   default:
  //     return language;
  // }
}

// export const shareUrlOnSocialMedia = (url) => {};

// export const redirectTo = (link) => {
//   if (!link) return;
//   window.location.href = link;
// };

export const objectToQS = (val) => {
  if (!val) return "";
  return stringify(val);
};

export const openInNewTab = (link) => {
  if (!link) return "";
  return window.open(link, "_blank");
};

export const getDataObjectFromSlug = (str,delim) => {
  
  let arr = str.split(delim)
  console.log("length_of_arr", arr)
  let data = {}
  if(str.indexOf("ib")!==-1) {
    //let new_arr=str.split("myp")
    //data = {
    //  gradeName: "MYP"+ " " +new_arr[1].substring(0,new_arr[0].length-3),
    //  boardName: arr[arr.length-1]
    //}
    //data.subject_name = new_arr[0].substring(0,new_arr[0].length-1);
    //return data;
    if(arr.length == 5){
      data= {
        gradeName: "MYP"+" "+arr[2]+"&"+ arr[3],
        boardName: arr[arr.length-1].toUpperCase(),
        subject_name: arr[0]
      }
    }
    if(arr.length == 4){
      data={
        gradeName: "MYP"+ " " + arr[2],
        boardName: arr[arr.length-1].toUpperCase(),
        subject_name: arr[0]
      }
    }
    return data
  } else {

    data = {
      boardName: arr.pop(),
      gradeName: arr.pop(),
    }

    let sub = arr.join('-')
    data.subject_name = sub;
    return data;
  }

}

export function selectGradeConvert(number) {
  switch (number) {
    case "1":
      return "I";
    case "2":
      return "II";
    case "3":
      return "III";
    case "4":
      return "IV";
    case "5":
      return "V";
    case "6":
      return "VI";
    case "7":
      return "VII";
    case "8":
      return "VIII";
    case "9":
      return "IX";
    case "10":
      return "X";
    case "11":
      return "XI";
    case "12":
      return "XII";
    default:
      return number;
  }
}

export function getQuizTheme(type) {
  let themeClass = "challenge-theme-4";
  switch (type) {
    case "1":
      themeClass = "challenge-theme-1";
      break;
    case "2":
      themeClass = "challenge-theme-2";
      break;
    case "3":
      themeClass = "challenge-theme-3";
      break;
    case "4":
      themeClass = "challenge-theme-4";
      break;
    default:
      themeClass = "challenge-theme-4";
      break;
  }
  return themeClass;
}

export function getIslandThemeClass(type) {
  // console.log("Theme", type);
  let themeClass = "island-theme-1";
  let themeModalClass = "island-modal-theme-" + type;
  // if (isNaN(parseInt(type))) {
  //   themeClass = "island-theme-" + type;
  //   themeModalClass = "island-modal-theme-" + type;
  //   return { themeClass: themeClass, themeModalClass: themeClass };
  // }
  switch (type) {
    case "1":
      themeClass = "island-theme-1";
      break;
    case "2":
      themeClass = "island-theme-2";
      break;
    case "3":
      themeClass = "island-theme-3";
      break;
    case "4":
      themeClass = "island-theme-4";
      break;
    case "5":
      themeClass = "island-theme-5";
      break;
    case "physics":
      themeClass = "island-theme-" + type;
      themeModalClass = "island-modal-theme-" + type;
      break;
    case "maths":
      themeClass = "island-theme-" + type;
      themeModalClass = "island-modal-theme-" + type;
      break;
    case "chemistry":
      themeClass = "island-theme-" + type;
      themeModalClass = "island-modal-theme-" + type;
      break;
    case "biology":
      themeClass = "island-theme-" + type;
      themeModalClass = "island-modal-theme-" + type;
      break;
    default:
      themeClass = "island-theme-default";
      themeModalClass = "island-modal";
      break;
  }
  return { themeClass: themeClass, themeModalClass: themeClass };
}
export function getThemeClass(type) {
  let themeClass = "challenge-theme-1 challenge-content";
  switch (type) {
    case "1":
      themeClass = "challenge-theme-1 challenge-content";
      break;
    case "2":
      themeClass = "challenge-theme-2 challenge-content";
      break;
    case "3":
      themeClass = "challenge-theme-3 challenge-content";
      break;
    case "4":
      themeClass = "challenge-theme-4 challenge-content";
      break;
    default:
      themeClass = "challenge-theme-1 challenge-content";
      break;
  }
  return themeClass;
}
export function getChallnegeModalTheme(type) {
  let themeClass = "challenge-modal-theme-1";
  switch (type) {
    case "1":
      themeClass = "challenge-modal-theme-1";
      break;
    case "2":
      themeClass = "challenge-modal-theme-2 ";
      break;
    case "3":
      themeClass = "challenge-modal-theme-3 ";
      break;
    case "4":
      themeClass = "challenge-modal-theme-4 ";
      break;
    default:
      themeClass = "challenge-modal-theme-1 ";
      break;
  }
  return themeClass;
}

export function queryStringValuesRetriever(search) {
  let values = queryString.parse(search);
  return values;
}

export function initMathJax() {
  window.MathJax = {
    loader: { load: ["input/asciimath"] },
    startup: {
      pageReady: function () {
      if(typeof window.MathJax.startup.defaultPageReady === "function")
        return window.MathJax.startup.defaultPageReady();
      },
      typeset: true,
    },
    tex: {
      //inlineMath: [['$', '$'], ['\\(', '\\)']],
      inlineMath: [
        ["$", "$"],
        //["$$", "$$"],
        ["\\(", "\\)"],
        // ["\\\\", "\\\\"],
        // ["\\[", "\\]"]
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"],
        ["\\\\", "\\\\"],
      ],
      processEscapes: true,
    },
  };
  // debugger;
  //Load MathJax //
  if (document.getElementById("MathJax-script")) {
    // debugger;
    var MathJax_script = document.getElementById("MathJax-script");
    MathJax_script.remove();
  }
  var script = document.createElement("script");
  script.src = "https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js";
  // script.src = "https://cdn.jsdelivr.net/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_CHTML";
  script.setAttribute("id", "MathJax-script");
  script.async = false;
  document.head.appendChild(script);
  // debugger;

   /*var script_ascii = document.createElement("script");
    script_ascii.src =
      // "https://cdn.jsdelivr.net/npm/mathjax@3/es5/input/asciimath.js";
      "https://cdn.jsdelivr.net/ajax/libs/mathjax/2.7.7/MathJax.js?config=AM_CHTML";
    script_ascii.setAttribute("id", "MathJax-ascii");
    script_ascii.async = false;
    document.head.appendChild(script_ascii); */
  //}
}

export function resetTex() {
  // setTimeout(function(){
  if (window.MathJax.texReset) {
    window.MathJax.texReset();
    window.MathJax.typesetClear();
    //window.MathJax.typeset();
    window.MathJax.typesetPromise()
      .then(() => {
        window.MathJax.typesetPromise();
      })
      .catch((err) => console.log(err.message));
  }
  // },700)
}

export function convertStringToLatex(string) {
  let GrammerGreekWords = [
    "alpha",
    "beta",
    "gamma",
    "delta",
    "epsilon",
    "varepsilon",
    "zeta",
    "eta",
    "theta",
    "vartheta",
    "iota",
    "kappa",
    "mu",
    "nu",
    "xi",
    "pi",
    "rho",
    "sigma",
    "tau",
    "upsilon",
    "phi",
    "varphi",
    "chi",
    "psi",
    "omega",
    "Gamma",
    "Delta",
    "Theta",
    "Lambda",
    "Xi",
    "Pi",
    "Sigma",
    "Phi",
    "Psi",
    "Omega",
  ];
  let GrammerMathOperations = ["/", "^"];
  let hasMathOperations = ["\\text", "\\frac", "\\mathrm", "\\"];
  let newString = string;

  const asciiMathCheck = (string) => {
    return GrammerMathOperations.some((substring) =>
      string.includes(substring)
    );
  };

  const mathExpressionCheck = (str, delim) => {
    var a = str.indexOf(delim);
    if (a == -1) return false;
    /* var b = str.indexOf(delim, a + 1);
    if (b == -1) return false; */
    return true;
  };

  const replace_String = (oldS, newS, fullS = "") => {
    let full_s = fullS;
    let cntr = 0;
    for (let i = 0; i < full_s.length; ++i) {
      if (full_s.substring(i, i + oldS.length) == oldS) {
        if (cntr == 0) {
          full_s =
            full_s.substring(0, i) +
            "$ " +
            newS +
            full_s.substring(i + oldS.length, full_s.length);
        } else {
          full_s =
            full_s.substring(0, i) +
            newS +
            " " +
            newS +
            full_s.substring(i + oldS.length, full_s.length);
        }
        cntr++;
      }
    }
    return full_s;
  };

  let String_with_math = hasMathOperations.some((substring) => {
    if (newString.includes(substring)) return true;
    newString.includes(substring);
  });

  if (!mathExpressionCheck(string, "\\\\")) {
    // console.log("here not $$: ", string)
    if (mathExpressionCheck(string, "$")) {
      // console.log("here mathExpressionCheck $");
      newString = string;
    } else if (asciiMathCheck(string)) {
      // console.log("here AsciiMath");
      if (string.indexOf("`") !== -1 || string.indexOf("\\[") !== -1) {
        //  console.log("here AsciiMath 1");
        newString = string;
      } else if (String_with_math) {
        //  console.log("here AsciiMath 2");
        newString = "$" + string + "$";
      } else {
        // console.log("here AsciiMath 3");
        newString = "`" + string + "`";
      }
    } else {
      if (!String_with_math) newString = getUTFDecodedText(string);
      else newString = "$" + string + "$";
    }
  } else {
    var checkifCloased = string.substr(string.length - 2);
    if (checkifCloased === "\\\\") {
      newString = string.substring(0, string.length - 2);
    }
    newString = replace_String("\\\\", "$$", newString);
    newString = "$" + newString + "$$";
  }
  return newString;
}

export function getSubjectFromSlug(slug) {
  if (slug) {
    var subject = slug.split("-").splice(0, 1).join("");
    return titleCase(subject);
  }
  return "";
}
export function dateSortFunction(a, b) {
  var dateA = new Date(a.updatedAt).getTime();
  var dateB = new Date(b.updatedAt).getTime();
  return dateA > dateB ? 1 : -1;
}

export function spayeeJWT(name, email, password) {
  const header = {
    alg: "HS256",
    typ: "JWT",
  };
  let UnixEpoch = moment().unix();
  const payload = {
    name: name,
    email: email,
    password: password,
    exp: UnixEpoch,
  };
  const headerJWT = Base64.stringify(hmacSHA256(header, config.secret));
  const payloadJWT = Base64.stringify(hmacSHA256(payload, config.secret));
  const signatureJWT = Base64.stringify(
    hmacSHA256(headerJWT + "." + payloadJWT, config.spayeeToken)
  );
  // console.log("signatureJWT ", signatureJWT);
  // console.log("payloadJWT ", payloadJWT);
  // console.log("headerJWT ", headerJWT);
  return headerJWT + "." + payloadJWT + "." + signatureJWT;
}

export function mcqSubjectNames(subject) {
  switch (subject) {
    case "maths":
      return subject;
    case "science":
      return subject;
    case "biology":
      return subject;
    case "chemistry":
      return subject;
    case "cyber":
      return subject;
    case "physics":
      return subject;
    case "english":
      return subject;
    case "history":
      return subject;
    case "economics":
      return subject;
    case "geography":
      return subject;
    case "political":
      return subject;
    case "cricket":
      return subject;
    default:
      return "default";
  }
}

export const studentAvatar=(value)=>{
  console.log("value",value)
     switch (value) {
       case "Doctor":
       return pro1
 
       case "Engineer":
         return pro1;
         
       case "Scientist":
         return pro1;
         
       case "II":
         return pro2;
         
       case "III":
         return pro3;
         
       case "IV":
         return pro4;
         
       case "V":
         return pro5;
         
       case "VI":
         return pro6;
 
       case "VII":
         return pro7;
             
         case "VIII":
           return pro8;
         
       default:
         return pro1;
         
     }
   }

export function getImageAsPerSubject(subject) {
  switch (subject) {
    case "maths":
      return mathsImg;
    case "biology":
      return biologyImg;
    case "chemistry":
      return chemistryImg;
    case "physics":
      return physicsImg;
    case "science":
      return scienceImg;
    case "cyber":
      return cyberImg;
    case "english":
      return englishImg;
    case "history":
      return historyImg;
    case "economics":
      return economicsImg;
    case "geography":
      return geographyImg;
    case "political":
      return politicalImg;
    case "cricket":
      return cricketImg;
    default:
      return sampleFeedImg;
  }
}



export default {
  parseQueryParams,
  // checkChallengeString,
  titleCase,
  getLanguages,
  parseLanguage,
  checkLanguagePresence,
  showSuccessMessage,
  showErrorMessage,
  showWarningMessage,
  showNotification,
  showInternetAlert,
  getUTFDecodedText,
  secureStorage,
  // htmlParser,
  openInNewTab,
  objectToQS,
  // redirectTo,
  getQuizTheme,
  getThemeClass,
  getIslandThemeClass,
  getChallnegeModalTheme,
  queryStringValuesRetriever,
  convertStringToLatex,
  initMathJax,
  resetTex,
  getEncodedJWTToken,
  getSubjectFromSlug,
  dateSortFunction,
  spayeeJWT,
  clearAllStoreData,
  mcqSubjectNames,
  getImageAsPerSubject,
};
