import React from "react";
import "./CustomQuestionCard.scss";
import questCorrectArrow from "../../../../assets/images/teacher_assessment/questCorrectArrow.png";
import {convertStringToLatex,resetTex,initMathJax} from "services/utility";

const CustomQuestionCard = ({
  ques,
  selectedQuestions,
  removeQuestion,
  addQuestion,
  index,
}) => {
  let arr = [];
  var anssArr = [];

  if(ques.type == "mcms") {
    ques.answer.map((item) => {
      anssArr.push(item.option_name)
    })
  }

  // initMathJax();

  var value = ques.questionText;
  if(ques.additional_info.has_latex === "Y") {
    value = convertStringToLatex(value);
    // resetTex();
  }

  const createMarkup = () => {
    return {__html: value};
  };


  //const handleClassName = () => {
  //  if (ques.data == null || ques.data.options == null || ques.data.options[0]?.option_value == null) return "options1"
  //  if (ques.type === "mcq") {
  //    let str = ques.data.options[0].option_value.toString();
  //    if (str.length < 60) {
  //      return "options"
  //    }
  //    return 'options1'
  //  }
  //  if (ques.type === "mcms") {
  //    let str = ques.data.options[0].option_value.toString();
  //    if (str.length < 60) {
  //      return "options"
  //    }
  //    return 'options1'
  //  }
  //  return 'options1'
  //}

  console.log('ques_is', ques)


  const createMarkupForOptions = (option_value) => {
    try {
      if(ques.additional_info.has_latex === "Y" && typeof option_value !== "number" && typeof option_value !== "undefined") {
        let optionValue = convertStringToLatex(option_value);
        // resetTex();
        return {__html: optionValue};
      }
      return {__html: option_value};
    } catch(err) {
      console.log(err)
    }

  };

  const colorList = ["#3cc1b9","#C15C3C","#3C99C1","#E1B929"];

  let stylesFordangerouslySetInnerHTML = {fontFamily: "Poppins"};

  return (
    <div
      className="questionCardContainer"
      style={{borderBottom: "8px solid #f1f4f6"}}
    >
      <span style={{color: '#FC8643', fontWeight: 700}}>{ques.type.toUpperCase()}</span>
      <div className="question_Button row" key={ques._id}>
        <div className="col-12 col-md-10 p-0" style={{display: "flex",alignItems: 'baseline'}}>
          {`${index + 1}`}.&nbsp;
          {ques.image ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <div
                className="qest-text "
                style={{display: "flex",flexWrap: "wrap"}}
                dangerouslySetInnerHTML={createMarkup()}
              />
              <img src={ques.image} alt="" style={{maxWidth: '250px',maxHeight: '250px', objectFit: 'contain'}} />
            </div>
          ) : ques.additional_info.has_latex === "Y" ?
            <div
              className="qest-text  "
              dangerouslySetInnerHTML={createMarkup()}
              style={stylesFordangerouslySetInnerHTML}
            />
            :
            <div className="qest-text">{ques.questionText}</div>
          }
        </div>
        <div className="buttons col-12 col-md-2" >
          {selectedQuestions.map((el) => {
            arr.push(el._id);
          })}
          {arr.includes(ques._id) ? (
            <button
              onClick={() => {
                removeQuestion(ques);
              }}
              style={{
                border: "1px solid #FC8643",
                backgroundColor: "#fff",
                color: "#2B2B2B",
              }}
            >
              <div
                style={{
                  fontWeight: 300,
                  fontSize: "15px",
                  fontFamily: "Poppins",
                }}
              >
                Remove
              </div>
            </button>
          ) : (
            <button
              onClick={() => {
                addQuestion(ques);
              }}
            >
              <div
                style={{
                  fontWeight: 300,
                  fontSize: "15px",
                  fontFamily: "Poppins",
                }}
              >
                Add&nbsp;question
              </div>
            </button>
          )}
        </div>
      </div>
      <div className="hrLine" />
      <div style={{display: 'grid',gap: '0.5rem'}}>
        {ques.type === "mcq" ?
          <>
            {ques.data.options.map((el,i) => {
              return (
                <>
                  {el.option_type === "image" ? (
                    <div
                      className="singleOption"
                      style={{display: 'flex', alignItems: 'center'}}
                    >
                      <div style={{color: '#2B2B2B'}}>{el.option_name}.</div>
                      <img src={el.option_image} alt="" style={{maxWidth: '250px',maxHeight: '250px', objectFit: 'contain'}} />
                      {el.option_name === ques.answer?.option_name && (
                        <div >
                          <img src={questCorrectArrow} alt="" style={{width: '15px',height: '15px'}} />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      className="singleOption"
                      style={
                        el.option_name === ques.answer?.option_name ? {backgroundColor: '#C8FCBF',color: '#124609'} : {backgroundColor: 'transparent',color: '#999898'}
                      }
                    >
                      <div style={{color: '#2B2B2B'}}>{el.option_name}.</div>
                      <div
                        className="qest-text"
                        dangerouslySetInnerHTML={createMarkupForOptions(
                          el.option_value
                        )}
                      />
                      {el.option_name === ques.answer?.option_name && (
                        <div >
                          <img src={questCorrectArrow} alt="" style={{width: '15px',height: '15px'}} />
                        </div>
                      )}
                    </div>
                  )}
                </>
              )
            })}
          </>
          :
          <>
            {ques.type === "subjective" ? (
              <>
                <div
                  className="singleOption"
                  style={{backgroundColor: '#C8FCBF',color: '#124609'}}
                >
                  <div style={{color: '#2B2B2B'}}>Ans.</div>
                  <div
                    dangerouslySetInnerHTML={createMarkupForOptions(
                      ques.answer
                    )}
                  />
                  <div >
                    <img src={questCorrectArrow} alt="" style={{width: '15px',height: '15px'}} />
                  </div>
                </div>
              </>
            ) : (
              <>
                {ques.type === "mcms" ?
                  <>
                    {ques.data.options.map((el,i) => {
                      return (
                        <>
                          {el.option_type === "image" ? (
                            <div
                              className="singleOption"
                              style={{display: 'flex', alignItems: 'center'}}
                            >
                              <div style={{color: '#2B2B2B'}}>{el.option_name}.</div>
                              <img src={el.option_image} alt="" style={{maxWidth: '250px',maxHeight: '250px', objectFit: 'contain'}} />
                              {anssArr.includes(el.option_name) && (
                                <div >
                                  <img src={questCorrectArrow} alt="" style={{width: '15px',height: '15px'}} />
                                </div>
                              )}
                            </div>
                          ) : (
                            <div
                              className="singleOption"
                              style={anssArr.includes(el.option_name) ? {backgroundColor: '#C8FCBF',color: '#124609'} : {backgroundColor: 'transparent',color: '#999898'}}
                            >
                              <div style={{color: '#2B2B2B'}}>{el.option_name}.</div>
                              <div
                                dangerouslySetInnerHTML={createMarkupForOptions(
                                  el.option_value
                                )}
                              />
                              {anssArr.includes(el.option_name) && (
                                <div >
                                  <img src={questCorrectArrow} alt="" style={{width: '15px',height: '15px'}} />
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      )
                    })}
                  </>
                  : (
                    <div
                      //className="optionBox"
                      //style={{
                      //  backgroundColor: colorList[Math.floor(Math.random() * 4)],
                      //}}
                      style={{display: 'flex',flexDirection: 'row',gap: '1rem',alignItems: 'center'}}
                    >
                      <div
                        dangerouslySetInnerHTML={createMarkupForOptions(
                          ques.answer.option_value
                        )}
                      />
                    </div>
                  )}
              </>
            )}
          </>
        }
      </div>
    </div>
  );
};

export default React.memo(CustomQuestionCard);