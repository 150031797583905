import React, {useEffect, useState} from "react";
import {Spinner, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {useGlobal} from "reactn";
import Credits from "./components/Credits";
import Logo from "./components/Logo";
import Input from "./components/Input";
import api from "services/api";
import config from "pwaConfig.json";
import "./Login.sass";
import Div100vh from "react-div-100vh";
import loginV2 from "../../actions/login";
import {login} from "./../../reducers/auth/actions";
import {setConfig} from "./../../reducers/config/actions";
import register from "../../actions/register";
import {useHistory} from "react-router-dom";
import jwtDecode from "jwt-decode";
import setAuthToken from "../../actions/setAuthToken";
import {useDispatch} from "react-redux";
import loginImg from "../../img/login_mes.png";
import {
  saveUserDataToLocalStorage,
  getSelectedBoardAndGradeFromLocal,
} from "../../services/dataServices/userProfileService";
import {
  getUserContentIfAvailable,
  saveSubjectDataToLocalStorage,
  saveFeedRailDataToLocalStorage,
  getLRSContent,
  saveLRSSyncDataToLocalStorage,
} from "../../services/dataServices/userContentService";
import {withTranslation} from "react-i18next";
import {secureStorage, spayeeJWT, getEncodedJWTToken} from "services/utility";
import {propTypes} from "react-tinder-card";
import {
  SuperAdminLogindata,
  TeacherLogindata,
  PrincipalLogindata,
} from "./userData";
import stepapp_final_logo from "../../img/STEPapp_final_logo.png";
import {AiOutlineUser, AiOutlineLock} from "react-icons/ai";
import {message} from "antd";
import UIkit from "uikit";
import Axios from "axios";
import {useToasts} from "react-toast-notifications";

const Login = (props) => {
  const dispatch = useDispatch();
  const {addToast} = useToasts();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [keep, setKeep] = useState(true);
  const [loginErrors, setLoginErrors] = useState({});
  const [registerUsername, setRegisterUsername] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerFirstName, setRegisterFirstName] = useState("");
  const [registerLastName, setRegisterLastName] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [registerRepeatPassword, setRegisterRepeatPassword] = useState("");
  const [registerErrors, setRegisterErrors] = useState({});
  const [loader, setLoader] = useState(false);

  const [showSignupModal, setShowSignupModal] = useState(false);
  const [userType, setUserType] = useState("");
  const [selectedSchool, setSelectedSchool] = useState("4688");
  const [selectedBoard, setSelectedBoard] = useState("CBSE");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("12");
  const [selectedCurrBatch, setSelectedCurrBatch] = useState("DSB");
  const [selectedBatch, setSelectedBatch] = useState([]);
  const [errorList, setErrorList] = useState({});
  const [GradeList, setGradeList] = useState([]);
  const [BatchList, setBatchList] = useState([]);

  const setToken = useGlobal("token")[1];
  const setUser = useGlobal("user")[1];
  const [entryPath, setEntryPath] = useGlobal("entryPath");
  const [spinnerForFeed, setSpinnerForFeed] = useState(false);
  const [allSchools, setAllSchools] = useState([]);
  const [batches_set, setBatches_set] = useState(new Set());

  const getSchools = async () => {
    setSpinnerForFeed(true);
    config.schoolChainId = 510;
    try {
      await api
        .get(
          `${config.apiEndPoint}/schoollist?schoolChainId=${config.schoolChainId}`
        )
        .then((res) => {
          setAllSchools([]);
          let sortedArray = res.data.sort((a, b) => {
            if (a.school_name < b.school_name) {
              return -1;
            }
          });
          setAllSchools(sortedArray);
          setSpinnerForFeed(false);
        });
    } catch (e) {
      setSpinnerForFeed(true);
    }
  };

  const history = useHistory();

  // async function loginFromStepApp(evt) {
  //   var token;
  //   if (
  //     evt.origin !== Config.url &&
  //     evt.origin !== "http://localhost:4000" &&
  //     evt.origin !== "http://localhost:3000"
  //   ) {
  //     console.log("here if: ", evt.origin);
  //     token = "here You are not worthy. request not from step app domain";
  //   } else {
  //     //console.log("here token: ", evt.data.jwt)
  //     token = evt.data.jwt;
  //     if (evt.data.jwt) {
  //       console.log("here JWT: ", token);
  //       // console.log("In padhao application ---->", token);
  //       if (keep) localStorage.setItem("token", token);
  //       if (keep)
  //         localStorage.setItem("user", JSON.stringify(jwtDecode(token)));
  //       setLoginErrors({});
  //       setAuthToken(token);
  //       setUser(jwtDecode(token));
  //       setToken(token);
  //       dispatch(initIO(token));
  //       history.replace(
  //         ["/login-padhao", "/"].includes(entryPath) ? "/" : entryPath
  //       );
  //       await setEntryPath(null);
  //     }
  //   }
  // }

  // if (window.addEventListener) {
  //   window.addEventListener("message", loginFromStepApp, false);
  // } else {
  //   // For IE
  //   window.attachEvent("onmessage", loginFromStepApp);
  // }

  useEffect(() => {
    // console.log(TeacherLogindata, SuperAdminLogindata);
  }, []);

  // useEffect(()=>{
  //   localStorage.clear();
  // })

  const handleClose = () => {
    setShowSignupModal(false);
  };

  const toggle = () => {
    setRegisterUsername("");
    setRegisterEmail("");
    setRegisterFirstName("");
    setRegisterLastName("");
    setRegisterPassword("");
    setRegisterRepeatPassword("");
    setSelectedSchool("4688");
    setSelectedBoard("CBSE");
    setSelectedSubject("");
    setSelectedGrade("12");
    setSelectedBatch([]);
    setBatches_set(new Set())
    setSelectedCurrBatch("DSB");
    setUserType("")
    setErrorList({});
    setLoader(false);
    setShowSignupModal(!showSignupModal);


  };

  const closeBtn = (
    <button
      style={{
        position: "absolute",
        top: "25px",
        right: "25px",
        transform: "translate(100%, -100%)",
        width: "48px",
        height: "48px",
        borderRadius: "50%",
        borderStyle: "none",
        border: "1px solid #ffffff",
        backgroundColor: "#fff",
        color: "#000000",
        font: "normal normal 400 24px/36px Poppins",
      }}
      type="button"
      onClick={toggle}
    >
      &times;
    </button>
  );

  let disabledAddButton = {
    height: "40px",
    backgroundColor: "#A5A5A5",
    border: "none",
  };
  let enabledAddButton = {
    height: "40px",
    backgroundColor: "#FC8643",
    cursor: "pointer",
    border: "none",
  };

  const passwordValidation = (e) => {
    let err = errorList;
    if (registerPassword !== e.target.value) {
      err.pw_err = "Password doesn't match";
    } else {
      err.pw_err = null;
    }
    setErrorList(err);
  };

  const validate = () => {
    const errors = {};
    let reg = RegExp(
      "^[_a-zA-Z0-9-]+(.[_a-zA-Z0-9-]+)@[a-z0-9-]+(.[a-z0-9-]+)(.[a-z]{2,4})$"
    ).test(registerEmail);
    if (registerUsername.length != 10) {
      errors.username = "must be of 10 digits";
    }
    if (registerUsername.length == 10) {
      let inputValue = registerUsername.toLowerCase();
      var alphabet = "abcdefghijklmnopqrstuvwxyz";
      var containsWord = false;

      for (var i = 0; i < alphabet.length; i++) {
        var letter = alphabet[i];
        if (inputValue.includes(letter)) {
          containsWord = true;
          break;
        }
      }
      if (containsWord) {
        errors.username = "Invalid number";
      }
    }
    if (registerUsername === "") {
      errors.username = "Required";
    }
    if (registerPassword === "") {
      errors.password = "password Required";
    }
    if (registerRepeatPassword === "") {
      errors.pw_err = "confirm password";
    }
    if (registerFirstName === "") {
      errors.firstname = "First Name Required";
    }
    if (registerLastName === "") {
      errors.lastname = "Last Name Required";
    }
    if (registerEmail == "" || !reg) {
      errors.email = "Invalid Email";
    }
    if (selectedSchool === "" || selectedSchool === undefined) {
      errors.school = "Select any school";
    }
    if (selectedBoard === "") {
      errors.board = "required";
    }
    if (userType === "") {
      errors.usertype = "select any type";
    }
    if (userType === "teacher") {
      if (selectedBatch.length == 0) {
        errors.batches = "Select atleast one batch";
      }
    }
    return Object.keys(errors).length === 0 ? null : errors;
  };

  const SignupModal = () => {
    return (
      <Modal
        isOpen={showSignupModal}
        size={"lg"}
        centered={true}
        backdrop="static"
        fade={true}
      >
        <ModalHeader
          toggle={handleClose}
          close={closeBtn}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "none",
            padding: "1rem 0rem 0rem",
          }}
        >
          <div className="d-flex flex-column align-items-center justify-content-center my-2">
            <div>
              <img src={stepapp_final_logo} alt="" width="146" />
            </div>
            <div
              className="my-2"
              style={{
                color: "#FC8643",
                font: "normal normal 500 1.2rem/1.6rem Poppins",
              }}
            >
              Sign Up
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="row width-100 mx-auto">
            <div className="col-md-4 my-2">
              <label
                className="uk-form-label"
                htmlFor="form-stacked-text"
                style={{color: "#AEAEAE"}}
              >
                Mobile number (+91)
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input border-radius-round"
                  type="text"
                  maxLength={10}
                  placeholder="Enter here"
                  //value={quizName}
                  onChange={(e) => {
                    let err = errorList;
                    err.username = null;
                    setErrorList(err);
                    setRegisterUsername(e.target.value);
                  }}
                />
              </div>
              {errorList?.username && (
                <div className="invalid-stepapp-feedback">
                  {errorList.username}
                </div>
              )}
            </div>
            <div className="col-6 col-md-4 my-2">
              <label
                className="uk-form-label"
                htmlFor="form-stacked-select1"
                style={{color: "#AEAEAE"}}
              >
                Enter password
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input border-radius-round"
                  style={{color: "#AEAEAE"}}
                  type="password"
                  placeholder="Enter password"
                  onChange={(e) => {
                    let err = errorList;
                    err.password = null;
                    setErrorList(err);
                    setRegisterPassword(e.target.value);
                  }}
                />
              </div>
              {errorList?.password && (
                <div className="invalid-stepapp-feedback">
                  {errorList.password}
                </div>
              )}
            </div>
            <div className="col-6 col-md-4 my-2">
              <label
                className="uk-form-label"
                htmlFor="form-stacked-select1"
                style={{color: "#AEAEAE"}}
              >
                Confirm password
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input border-radius-round"
                  style={{color: "#AEAEAE"}}
                  type="password"
                  placeholder="Re-enter password"
                  onChange={(e) => {
                    setRegisterRepeatPassword(e.target.value);
                    passwordValidation(e);
                  }}
                />
              </div>
              {errorList?.pw_err && (
                <div className="invalid-stepapp-feedback">
                  {errorList.pw_err}
                </div>
              )}
              {registerRepeatPassword.length === 0 &&
                errorList?.confirmPassword && (
                  <div className="invalid-stepapp-feedback">
                    {errorList.confirmPassword}
                  </div>
                )}
            </div>
          </div>

          <div className="row width-100 mx-auto">
            <div className="col-6 col-md-4 my-2">
              <label
                className="uk-form-label"
                htmlFor="form-stacked-text"
                style={{color: "#AEAEAE"}}
              >
                First Name
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input border-radius-round"
                  type="text"
                  placeholder="First Name"
                  //value={quizName}
                  //onChange={updateName}
                  onChange={(e) => {
                    let err = errorList;
                    err.firstname = null;
                    setErrorList(err);
                    setRegisterFirstName(e.target.value);
                  }}
                />
              </div>
              {errorList?.firstname && (
                <div className="invalid-stepapp-feedback">
                  {errorList.firstname}
                </div>
              )}
            </div>
            <div className="col-6 col-md-4 my-2">
              <label
                className="uk-form-label"
                htmlFor="form-stacked-text"
                style={{color: "#AEAEAE"}}
              >
                Last Name
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input border-radius-round"
                  type="text"
                  placeholder="Last Name"
                  onChange={(e) => {
                    let err = errorList;
                    err.lastname = null;
                    setErrorList(err);
                    setRegisterLastName(e.target.value);
                  }}
                />
              </div>
              {errorList?.lastname && (
                <div className="invalid-stepapp-feedback">
                  {errorList.lastname}
                </div>
              )}
            </div>
            <div className="col-md-4 my-2">
              <label
                className="uk-form-label"
                htmlFor="form-stacked-text"
                style={{color: "#AEAEAE"}}
              >
                Email
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input border-radius-round"
                  type="email"
                  placeholder="Email"
                  onChange={(e) => {
                    let err = errorList;
                    err.email = null;
                    setErrorList(err);
                    setRegisterEmail(e.target.value);
                  }}
                />
              </div>
              {errorList?.email && (
                <div className="invalid-stepapp-feedback">
                  {errorList.email}
                </div>
              )}
            </div>
          </div>

          <div className="row width-100 mx-auto">
            <div className="col-md-6 my-2">
              <label
                className="uk-form-label"
                htmlFor="form-stacked-select2"
                style={{color: "#AEAEAE"}}
              >
                Select School
              </label>
              {/*<div className="uk-form-controls">
                <select
                  className='uk-select  border-radius-round'
                  onChange={(e) => { let err = errorList; err.school = null; setErrorList(err); setSelectedSchool(e.target.value) }}
                >
                  <option selected disabled>Select</option>
                  {allSchools.map((d) => {
                          return (
                            <option value={d.id}>{d.school_name}</option>
                          )
                        })}
                </select>
              </div>*/}
              <div className="uk-form-controls">
                {/*<input list="schools" name="schools" id="schools" />
                <datalist id="schools">
                  {allSchools.map((d) => {
                return (
                  <option value={d.school_name}>{d.school_name}</option>
                )
                  })}
                </datalist>*/}
                {/*<input className="uk-input border-radius-round" list="schools" onChange={(e) => handleSchoolSelection(e)} placeholder="Search..." />
                <datalist id="schools">
                  {allSchools.map((d) => {
                    return (
                      <option key={d.id} value={d.school_name}>{d.school_name}</option>
                    )
                  })}
                </datalist>*/}
                <select
                  className="uk-select  border-radius-round"
                  onChange={(e) => handleSchoolSelection(e)}
                >
                  {/*<option disabled selected>
                    Select School
                  </option>*/}
                  {allSchools.map((d) => {
                    console.log('allSchools_are', allSchools[0].school_name)
                    return (
                      <option key={d.id} selected value={d.school_name}>
                        {d.school_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {errorList?.school && (
                <div className="invalid-stepapp-feedback">
                  {errorList.school}
                </div>
              )}
            </div>
            <div className="col-6 col-md-2 my-2">
              <label
                className="uk-form-label"
                htmlFor="form-stacked-select2"
                style={{color: "#AEAEAE"}}
              >
                Board
              </label>
              <div className="uk-form-controls">
                <select
                  className="uk-select  border-radius-round"
                  //value={`selected chapter`}
                  onChange={(e) => {
                    let err = errorList;
                    err.board = null;
                    setErrorList(err);
                    setSelectedBoard(e.target.value);
                  }}
                >
              
                  <option selected value="CBSE">CBSE</option>
                  {/* <option>ICSE</option>
                  <option>PACE</option>
                  <option>SSC</option>
                  <option>OTHER</option> */}
                </select>
              </div>
              {errorList?.board && (
                <div className="invalid-stepapp-feedback">
                  {errorList.board}
                </div>
              )}
            </div>
            <div className="col-6 col-md-4 my-2">
              <label
                className="uk-form-label"
                htmlFor="form-stacked-select2"
                style={{color: "#AEAEAE"}}
              >
                User Type
              </label>
              <div className="uk-form-controls">
                <select
                  className="uk-select  border-radius-round"
                  onChange={(e) => {
                    let err = errorList;
                    err.usertype = null;
                    setErrorList(err);
                    setUserType(e.target.value);
                  }}
                >
                  {" "}
                  <option selected disabled>
                    Select
                  </option>
                  <option value={"teacher"}>Teacher</option>
                  <option value={"principal"}>Principal</option>
                </select>
              </div>
              {errorList?.usertype && (
                <div className="invalid-stepapp-feedback">
                  {errorList.usertype}
                </div>
              )}
            </div>
          </div>
          {userType === "teacher" && (
            <div className="row width-100 mx-auto">
              <div className="col-6 col-md-3 my-2">
                <label
                  className="uk-form-label"
                  htmlFor="form-stacked-select2"
                  style={{color: "#AEAEAE"}}
                >
                  Select Subject
                </label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select  border-radius-round"
                    id="subject"
                    //value={selectedSubject}
                    onChange={(e) => {
                      setSelectedSubject(e.target.value);
                    }}
                  >
                    <option selected disabled>
                      Select
                    </option>
                    <option value={"Physics"}>Physics</option>
                    <option value={"Chemistry"}>Chemistry</option>
                    <option value={"Biology"}>Biology</option>
                    <option value={"Maths"}>Maths</option>
                  </select>
                </div>
              </div>
              <div className="col-6 col-md-3 my-2">
                <label
                  className="uk-form-label"
                  htmlFor="form-stacked-select2"
                  style={{color: "#AEAEAE"}}
                >
                  Select Grade
                </label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select  border-radius-round"
                    id="grade"
                    //value={selectedGrade}
                    onChange={(e) => {
                      setSelectedGrade(e.target.value);
                    }}
                  >
                    <option selected value={"12"}>
                      12
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-6 col-md-3 my-2">
                <label
                  className="uk-form-label"
                  htmlFor="form-stacked-select2"
                  style={{color: "#AEAEAE"}}
                >
                  Select Section
                </label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select  border-radius-round"
                    id="batch"
                    //value={selectedGrade}
                    onChange={(e) => {
                      setSelectedCurrBatch(e.target.value);
                    }}
                  >
                    <option selected value={"DSB"}>
                      DSB
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-md-3 my-2 position-relative">
                <label
                  className="uk-form-label"
                  htmlFor="form-stacked-select2"
                  style={{color: "#AEAEAE", visibility: "hidden"}}
                >
                  Add Batch
                </label>
                <div
                  className="uk-form-controls"
                  style={{display: "grid", placeItems: "center"}}
                >
                  <button
                    className="text-light rounded w-50"
                    style={
                      selectedGrade.length > 0 &&
                      selectedSubject.length > 0 &&
                      selectedCurrBatch.length > 0 &&
                      !batches_set.has(
                        `${selectedGrade} ${selectedSubject} ${selectedCurrBatch}`
                      )
                        ? enabledAddButton
                        : disabledAddButton
                    }
                    disabled={
                      selectedGrade.length > 0 &&
                      selectedSubject.length > 0 &&
                      selectedCurrBatch.length > 0 &&
                      !batches_set.has(
                        `${selectedGrade} ${selectedSubject} ${selectedCurrBatch}`
                      )
                        ? false
                        : true
                    }
                    onClick={handleAddBatch}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <div className="row flex-column width-100 mx-auto">
                  <label
                    className="uk-form-label"
                    htmlFor="form-stacked-select2"
                    style={{color: "#AEAEAE"}}
                  >
                    Selected Batch
                  </label>
                  {selectedBatch.length > 0 ? (
                    <div
                      className="uk-form-controls d-flex flex-wrap"
                      style={{gap: "1rem"}}
                    >
                      {selectedBatch.map((batch) => {
                        return (
                          <div
                            className="selected_pill"
                            key={batch.batch_display}
                            style={{
                              color: "#383838",
                              font: "normal normal 400 clamp(0.8rem, 2vw, 1.1rem)/normal Poppins",
                              borderRadius: "1.25rem",
                              border: "1px solid #FC8643",
                              width: "fit-content",
                              padding: "0.3rem 1rem",
                            }}
                          >
                            <span className="mr-2">{batch.batch_display}</span>
                            <span
                              onClick={() => handleSelectedBatchClick(batch)}
                              style={{cursor: "pointer"}}
                            >
                              &#10005;
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="row m-0 justify-content-center">
                      <span>No Batch Selected</span>
                    </div>
                  )}
                  {errorList?.batches && (
                    <div className="invalid-stepapp-feedback">
                      {errorList.batches}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter
          style={{
            borderTop: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!loader ? (
            <button
              style={{
                width: "300px",
                font: "normal normal 500 0.8rem/1.2rem Poppins",
                border: "none",
                borderRadius: "8px",
                height: "2.5rem",
                color: "#FFFFFF",
                backgroundColor: "#FC8643",
              }}
              onClick={onBoardTeacher}
            >
              SIGN UP
            </button>
          ) : (
            <div
              className="text-center card-button"
              style={{width: "300px", height: "2.5rem", borderRadius: "8px"}}
            >
              <Spinner
                className=" "
                animation="border"
                style={{color: "#fff"}}
                size="md"
              />
            </div>
          )}
        </ModalFooter>
      </Modal>
    );
  };

  const handleSchoolSelection = (e) => {
    let selectedSchoolId = "";
    selectedSchoolId = allSchools.find(
      (school) => school.school_name === e.target.value
    );
    let schoolId = selectedSchoolId?.id;
    setSelectedSchool(schoolId);

    setBatchList(["DSB"]);
    setGradeList(["12"]);
  };

  console.log("allSchool_list", allSchools);
  console.log("schoold_id_is", selectedSchool);

  const onBoardTeacher = async (e) => {
    e.preventDefault();
    setLoader(true);
    let err = await validate();
    console.log("err_is", err);
    setErrorList(err);

    if (err == null) {
      let str = "";
      selectedBatch.map((item) => {
        str += `${item.grade}$${item.subject_name}$${item.batch_name}%`;
      });
      str = str.substring(0, str.length - 1);
      let inputData = {
        TeacherAssignedSubjects: str,
        boardName: selectedBoard,
        schoolId: selectedSchool,
        userType: userType,
        mobileNo: registerUsername,
        password: registerPassword,
        user_email: registerEmail,
        firstName: registerFirstName,
        lastName: registerLastName,
      };
      const response = await Axios.post(
        config.apiEndPoint + "/register-teacher-principal",
        inputData
      );
      console.log("register-API_response", response);

      if (response.status == 200) {
        if (response.data.body.includes("already")) {
          toggle();
          addToast(`Profile already exists,`, {
            appearance: "warning",
            autoDismiss: true,
          });
        } else {
          toggle();
          addToast(`Profile created successfully`, {
            appearance: "success",
            autoDismiss: true,
          });
        }
      } else {
        toggle();
        addToast(`Profile creation failed,`, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
    setLoader(false);
  };

  const handleSelectedBatchClick = (batch) => {
    let newSet = batches_set;
    // newSet.add(batch.batch_display)
    if (batches_set.has(batch.batch_display)) {
      newSet.delete(batch.batch_display);
      setBatches_set(newSet);
    }

    let chapterToRemove = selectedBatch.filter(
      (assign_chap) => assign_chap.batch_display !== batch.batch_display
    );
    setSelectedBatch(chapterToRemove);
  };

  const handleAddBatch = () => {
    let batch_display = `${selectedGrade} ${selectedSubject} ${selectedCurrBatch}`;
    let newSet = new Set(batches_set);
    newSet.add(batch_display);
    setBatches_set(newSet);
    setSelectedBatch([
      ...selectedBatch,
      {
        batch_display: batch_display,
        grade: selectedGrade,
        subject_name: selectedSubject,
        batch_name: selectedCurrBatch,
      },
    ]);
    document.querySelector("#subject").querySelectorAll("option")[0].selected =
      "selected";
    document.querySelector("#grade").querySelectorAll("option")[0].selected =
      "selected";
    document.querySelector("#batch").querySelectorAll("option")[0].selected =
      "selected";
    setSelectedSubject("");
    setSelectedGrade("12");
    setSelectedCurrBatch("DSB");
  };

  const onLogin = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await loginV2(email, password);
      await saveUserDataToLocalStorage(res.data);
      const token = res.headers.token;
      // if (keep) localStorage.setItem("token", token);

      secureStorage.setItem("token", token);

      // let testToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX21vYmlsZSI6Ijk2ODk0MjIzMzQiLCJjb3VudHJ5X2NvZGUiOiI5MSIsImJvYXJkX25hbWUiOiJTU0MiLCJsYW5nX3ByZWYiOiJlbiIsImdyYWRlX25hbWUiOiIxMCIsImlhdCI6MTYwNDQwNzg2MX0.vO2yS36cF_jpmmzXvWJiXZYF7yYJs6RcDs8RuCYbTmc'
      // if (keep) localStorage.setItem("user", JSON.stringify(jwtDecode(token)));
      // if (keep) localStorage.setItem('user', JSON.stringify(jwtDecode(testToken)));
      setLoginErrors({});
      setAuthToken(token);
      console.log(jwtDecode(token));
      console.log("MOBILE NUMBER:", jwtDecode(token).user_mobile);
      console.log(typeof jwtDecode(token).user_mobile);
      // if (jwtDecode(token).user_mobile === "9713012989") {
      //   console.log(res.data);
      //   console.log(SuperAdminLogindata);
      //   var Logindata = SuperAdminLogindata
      //   await saveUserDataToLocalStorage(Logindata);
      //   const token = getEncodedJWTToken(Logindata);
      //   // if (keep) localStorage.setItem("token", token);

      //   secureStorage.setItem("token", token);

      //   // let testToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX21vYmlsZSI6Ijk2ODk0MjIzMzQiLCJjb3VudHJ5X2NvZGUiOiI5MSIsImJvYXJkX25hbWUiOiJTU0MiLCJsYW5nX3ByZWYiOiJlbiIsImdyYWRlX25hbWUiOiIxMCIsImlhdCI6MTYwNDQwNzg2MX0.vO2yS36cF_jpmmzXvWJiXZYF7yYJs6RcDs8RuCYbTmc'
      //   // if (keep) localStorage.setItem("user", JSON.stringify(jwtDecode(token)));
      //   // if (keep) localStorage.setItem('user', JSON.stringify(jwtDecode(testToken)));
      //   setLoginErrors({});
      //   setAuthToken(token);
      //   console.log(jwtDecode(token));
      //   await setUser(Logindata);
      //   let user_mobile = Logindata.user_mobile;
      //   let school_id = Logindata.school_id;
      //   let country_code = Logindata.country_code;
      //   let userType = Logindata.user_type;
      //   // let userType = 'superadmin';
      //   let username = Logindata.username;
      //   let user_email = Logindata.user_email;
      //   let spayeeJWTtoken = spayeeJWT(username, user_email, password);
      //   // console.log("spayeeJWTtoken ",spayeeJWTtoken);
      //   secureStorage.setItem("spayeeJWTtoken", spayeeJWTtoken);
      //   await getUserProfile(user_mobile, school_id, country_code);
      //   setToken(token);
      //   dispatch(initIO(token));
      //   let newObj = { ...res.data, user_type: 'superadmin' }
      //   localStorage.setItem('user_type','superadmin')
      //   console.log(newObj);
      //   dispatch(login({ user: newObj }));
      //   if (userType === "student") {
      //     history.replace("/concepts");
      //   } else {
      //     history.replace("/home");
      //   }
      //   // history.push( "/");
      //   await setEntryPath(null);
      // } else if (jwtDecode(token).user_mobile === "77710030149999") {
      //   var Logindata = PrincipalLogindata
      //   // var Logindata = TeacherLogindata
      //   await saveUserDataToLocalStorage(Logindata);
      //   const token = getEncodedJWTToken(Logindata);
      //   console.log(token);
      //   secureStorage.setItem("token", token);
      //   setLoginErrors({});
      //   setAuthToken(token);
      //   await setUser(Logindata);
      //   let user_mobile = Logindata.user_mobile;
      //   let school_id = Logindata.school_id;
      //   let country_code = Logindata.country_code;
      //   let userType = Logindata.user_type;
      //   let username = Logindata.username;
      //   let user_email = Logindata.user_email;
      //   let spayeeJWTtoken = spayeeJWT(username, user_email, password);
      //   secureStorage.setItem("spayeeJWTtoken", spayeeJWTtoken);
      //   await getUserProfile(user_mobile, school_id, country_code);
      //   setToken(token);
      //   dispatch(initIO(token));
      //   // localStorage.setItem('user_type','teacher')
      //   // let newObj = {...res.data,user_type:'principal'}
      //   // dispatch(login({ user: newObj }));

      //   if (userType === "student") {
      //     history.replace("/concepts");
      //   } else {
      //     history.replace("/home");
      //   }
      //   await setEntryPath(null);
      // } else {
      await setUser(jwtDecode(token));
      let user_mobile = jwtDecode(token).user_mobile;
      let school_id = jwtDecode(token).school_id;
      let country_code = jwtDecode(token).country_code;
      let userType = jwtDecode(token).user_type.trim();
      let username = jwtDecode(token).username;
      let user_email = jwtDecode(token).user_email;
      let spayeeJWTtoken = spayeeJWT(username, user_email, password);
      // console.log("spayeeJWTtoken ",spayeeJWTtoken);
      secureStorage.setItem("spayeeJWTtoken", spayeeJWTtoken);
      // await saveUserDataToLocalStorage(res)
      setToken(token);
      // dispatch(initIO(token));
      dispatch(login(res.data));
      await apiCall(res.data);
      // if (userType === "student") {
      //   history.replace(
      //     ["/student/login", "/"].includes(entryPath) ? "/concepts" : entryPath
      //   );
      // } else {
      //   history.replace(
      //     ["/teacher/login", "/"].includes(entryPath) ? "/" : entryPath
      //   );
      // }

      // await getUserProfile(user_mobile, school_id, country_code)
      //   .then(() => {

      //   });
      setLoader(false);
      if (userType === "student" || userType === "content_editor") {
        history.replace("/concepts");
      } else {
        if (userType === "superadmin") {
          history.replace("/dashboard");
        } else {
          history.replace("/dashboard");
        }
      }
      // history.push( "/");
      setEntryPath(null);
      // }
    } catch (e) {
      setLoader(false);
      console.log("here Error: ", e);
      let errors = {};
      if (!e.response || typeof e.response.data !== "object")
        errors.generic = "Could not connect to server.";
      else errors = e.response.data;
      setLoginErrors(errors);
    }
  };

  const apiCall = async (user) => {
    // alert("hello");
    localStorage.setItem(
      "I18N_LANGUAGE",
      user.lang_pref ? user.lang_pref : "en"
    );
    props.i18n.changeLanguage(user.lang_pref ? user.lang_pref : "en");
    // debugger;
    try {
      const userData = await getSelectedBoardAndGradeFromLocal();
      //console.log("userData", userData);
      if (userData) {
        const queryString =
          userData.selectedBoard +
          `&gradeId=` +
          encodeURIComponent(userData.selectedGrade) +
          `&schoolId=` +
          userData.selectedSchool +
          `&language=` +
          userData.selectedLanguage +
          `&platform=web`;

        //`${userData.selectedBoard}&gradeId=${userData.selectedGrade}&schoolId=${schoolListData[0].id}&language=${userData.selectedLanguage}`;
        //console.log("queryString", queryString);

        const subjectontentData = await getUserContentIfAvailable(queryString);
        //console.log("subjectontentData", subjectontentData);
        if (subjectontentData) {
          if (
            subjectontentData.content.subjects &&
            subjectontentData.content.subjects.length != 0
          ) {
            window.mainData = subjectontentData.content.subjects;
            //this.props.save(subjectontentData.content.subjects);
            saveSubjectDataToLocalStorage(subjectontentData.content.subjects);
            subjectontentData.feed.items.forEach((element, index) => {
              let indexObject = {
                index: index,
              };
              let newElement = Object.assign(element, indexObject);
              return newElement;
            });
            saveFeedRailDataToLocalStorage(subjectontentData.feed.items);
            //  const route = this.props.redirectUrl || "/";
            //  this.props.history.push(route);

            if (user.user_type === "student") {
              // this.props.history.replace(
              //   ["/login-otp", "/"].includes("entryPath")
              //     ? "entryPath"
              //     : "/concepts"
              // );
              props.history.replace("/concepts");
            } else {
              // this.props.history.replace(
              //   ["/login-otp", "/"].includes("entryPath") ? "/" : "entryPath"
              // );
              if (user.user_type === "superadmin") {
                history.replace("/dashboard");
              } else {
                history.replace("/dashboard");
              }
              // props.history.replace("/dashboard");
            }

            dispatch(setConfig({redirectUrl: ""}));
          } else {
            alert(props.t("onboarding.otp_login.no_grade_available"));
            props.history.push("/nocontent");
            //this.navigateToSelectGrade();
          }
        } else {
          alert(props.t("onboarding.otp_login.no_grade_available"));
          props.history.push("/nocontent");
          //this.navigateToSelectGrade();
        }
        const queryStringForLRS =
          `&user_id=` +
          userData.userId +
          `&board_name=` +
          userData.selectedBoard +
          `&grade_name=` +
          encodeURIComponent(userData.selectedGrade);
        const lrsSyncData = await getLRSContent(queryStringForLRS);
        await saveLRSSyncDataToLocalStorage(lrsSyncData.data.body, true);
      }
    } catch (error) {
      alert(error + ", Try again");
    }
  };

  const loginInfo = Object.keys(loginErrors).map((key) => (
    <div className="uk-text-center" key={key}>
      <span className="uk-text-imp">{loginErrors[key]}</span>
    </div>
  ));

  const registerInfo = Object.keys(registerErrors).map((key) => (
    <div className="uk-text-center" key={key}>
      <span className="uk-text-imp">{registerErrors[key]}</span>
    </div>
  ));

  return (
    <Div100vh>
      <div className="login uk-cover-container uk-flex uk-flex-center uk-flex-middle uk-overflow-hidden uk-light">
        {/* <div className="uk-position-cover" /> */}
        <div className="login-scrollable uk-flex uk-flex-center uk-flex-middle uk-position-z-index">
          <Credits />
          <div className="login_message uk-animation-slide-left">
            <img src={loginImg} alt="" />
          </div>
          <div className="login-inner uk-width-medium uk-animation-customslide">
            <div
              style={{
                width: "min(90vw, 400px)",
                backgroundColor: "#FFFFFF",
                borderRadius: "15px",
                padding: "1rem clamp(1rem, 4vw, 3rem)",
              }}
            >
              <div className="d-flex flex-column align-items-center justify-content-center my-2">
                <div>
                  <img src={stepapp_final_logo} alt="" width="146" />
                </div>
                <div
                  className="my-2"
                  style={{
                    color: "#FC8643",
                    font: "normal normal 500 1.2rem/1.6rem Poppins",
                  }}
                >
                  Sign In
                </div>
              </div>
              <div className="d-flex flex-column mb-3">
                <div
                  className="d-flex justify-content-start"
                  style={{
                    color: "#767676",
                    font: "normal normal 400 0.7rem/1.2rem Poppins",
                  }}
                >
                  Username
                </div>
                <div
                  className="d-flex justify-content-center p-2"
                  style={{
                    border: "1px solid #969696",
                    height: "45px",
                    borderRadius: "8px",
                  }}
                >
                  <AiOutlineUser color="#969696" size={"20"} />
                  <p style={{color: "gray"}}>+91</p>
                  <input
                    className="w-100 pl-2"
                    type="text"
                    placeholder="Mobile number / 14 digit code"
                    maxLength={14}
                    style={{
                      border: "none",
                      font: "normal normal 500 0.9rem/1.2rem Poppins",
                      color: "black",
                      background: "#ffffff",
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="d-flex flex-column mb-3">
                <div
                  className="d-flex justify-content-start"
                  style={{
                    color: "#767676",
                    font: "normal normal 400 0.7rem/1.2rem Poppins",
                  }}
                >
                  Password
                </div>
                <div
                  className="d-flex justify-content-center p-2"
                  style={{
                    border: "1px solid #969696",
                    height: "45px",
                    borderRadius: "8px",
                  }}
                >
                  <AiOutlineLock color="#969696" size={"20"} />
                  <input
                    className="w-100 pl-4"
                    type="password"
                    placeholder="Enter password"
                    style={{
                      border: "none",
                      font: "normal normal 500 0.9rem/1.2rem Poppins",
                      color: "black",
                      background: "#ffffff",
                    }}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="row m-0">
                {!loader ? (
                  <button
                    className="w-100"
                    style={{
                      font: "normal normal 500 0.8rem/1.2rem Poppins",
                      border: "none",
                      borderRadius: "8px",
                      height: "3rem",
                      color: "#FFFFFF",
                      backgroundColor: "#FC8643",
                    }}
                    onClick={onLogin}
                  >
                    LOG IN
                  </button>
                ) : (
                  <div
                    className="text-center card-button"
                    style={{height: "3rem", borderRadius: "8px"}}
                  >
                    <Spinner
                      className=" "
                      animation="border"
                      style={{color: "#fff"}}
                      size="md"
                    />
                  </div>
                )}
              </div>
              <div className="row m-0 justify-content-center my-2">
                <div
                  style={{
                    font: "normal normal 400 0.7rem/1.2rem Poppins",
                    color: "#2B2B2B",
                  }}
                >
                  Don't have an account?&nbsp;
                  <button
                    style={{
                      border: "none",
                      color: "#FC8643",
                      fontWeight: "700",
                      backgroundColor: "#ffffff",
                    }}
                    onClick={() => {
                      getSchools();
                      setShowSignupModal(true);
                    }}
                  >
                    SIGN UP
                  </button>
                </div>
              </div>
              <div className="row m-0 text-center">
                <div
                  style={{
                    font: "normal normal 400 0.7rem/1.2rem Poppins",
                    color: "#767676",
                  }}
                >
                  By signing up, you agree to our{" "}
                  <a
                    href="https://www.stepapp.ai/privacy"
                    target="_blank"
                    style={{color: "#767676"}}
                  >
                    <u>privacy</u>
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.stepapp.ai/term"
                    target="_blank"
                    style={{color: "#767676"}}
                  >
                    <u>terms & conditions</u>
                  </a>{" "}
                  of use.
                </div>
              </div>
            </div>
          </div>
          {/*<div
            className="login-inner uk-width-medium uk-padding-small uk-animation-customslide"            
          >*/}
          {/*<Logo />
            <div className="toggle-credits">*/}
          {/*<form className="toggle-class" onSubmit={onLogin}>
                <fieldset className="uk-fieldset">
                  {loginInfo}
                  <Input
                    icon="user"
                    placeholder="Mobile Number/14 digit code"
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Input
                    icon="lock"
                    placeholder="Password"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="uk-margin-small">
                    <label>
                      <input
                        className="uk-checkbox"
                        type="checkbox"
                        onChange={(e) => setKeep(e.target.checked)}
                        checked={keep}
                      />{" "}
                      Keep me logged in
                    </label>
                  </div>
                  {!loader ? (
                    <div className="uk-margin-bottom">
                      <button
                        type="submit"
                        className="uk-button uk-button-primary uk-border-pill uk-width-1-1"
                      >
                        LOG IN
                      </button>
                    </div>
                  ) : (
                    <div className="text-center card-button">
                      <Spinner
                        className=" "
                        animation="border"
                        style={{ color: "#fff" }}
                        size="md"
                      />
                    </div>
                  )}
                </fieldset>
              </form>
              <div className="uk-margin-bottom">
                <button
                  className="uk-button uk-border-pill uk-width-1-1"
                  style={{backgroundColor: "#fff", color: "#FC8643", fontWeight: "600"}}
                  onClick={()=> setShowSignupModal(true)}
                >
                  SIGN UP
                </button>
              </div>

              <form className="toggle-class" onSubmit={onRegister} hidden>
                {registerInfo}
                <Input
                  icon="user"
                  placeholder="Username"
                  type="text"
                  onChange={(e) => setRegisterUsername(e.target.value)}
                />
                <Input
                  icon="mail"
                  placeholder="Email"
                  type="email"
                  onChange={(e) => setRegisterEmail(e.target.value)}
                />
                <Input
                  icon="pencil"
                  placeholder="First Name"
                  type="text"
                  onChange={(e) => setRegisterFirstName(e.target.value)}
                />
                <Input
                  icon="pencil"
                  placeholder="Last Name"
                  type="text"
                  onChange={(e) => setRegisterLastName(e.target.value)}
                />
                <Input
                  icon="lock"
                  placeholder="Password"
                  type="password"
                  onChange={(e) => setRegisterPassword(e.target.value)}
                />
                <Input
                  icon="lock"
                  placeholder="Repeat Password"
                  type="password"
                  onChange={(e) => setRegisterRepeatPassword(e.target.value)}
                />
                <div className="uk-margin-bottom">
                  <button
                    type="submit"
                    className="uk-button uk-button-primary uk-border-pill uk-width-1-1"
                  >
                    REGISTER
                  </button>
                </div>
              </form>

              <form className="toggle-password" hidden>
                <Input icon="mail" placeholder="Email" type="email" />
                <div className="uk-margin-bottom">
                  <button
                    type="submit"
                    className="uk-button uk-button-primary uk-border-pill uk-width-1-1"
                  >
                    SEND CODE
                  </button>
                </div>
              </form>*/}

          {/*<div>
                <div className="uk-text-center">*/}

          {/* <span className="display_flex">
                    <a className="uk-link-reset uk-text-small toggle-class"
                                       data-uk-toggle="target: .toggle-class ;animation: uk-animation-fade">Forgot Password</a>
                                    <a className="uk-link-reset uk-text-small toggle-class"
                                       data-uk-toggle="target: .toggle-class ;animation: uk-animation-fade">Need an account?</a>
                  </span> */}
          {/*<a
                    className="uk-link-reset uk-text-small toggle-class"
                    data-uk-toggle="target: .toggle-class ;animation: uk-animation-fade"
                    hidden
                  >
                    <span data-uk-icon="arrow-left" /> Back to Login
                  </a>
                </div>
              </div>*/}
          {/*</div>*/}
          {/*<div className="w-100">
                <p>By signing up, you agree to our <a href="https://www.stepapp.ai/privacy/" target="_blank">privacy policy</a> and <a href="https://www.stepapp.ai/term/" target="_blank">terms & conditions</a> of use.</p>
            </div>*/}
        </div>
      </div>
      {/*</div>*/}
      {SignupModal()}
    </Div100vh>
  );
};

export default withTranslation()(Login);
