import React, { Fragment } from "react";
import { Row, Col, Container, Spinner, ButtonToolbar, Alert } from "reactstrap";
import logo_dark from "assets/images/logo/logo-dark.svg";
import logo_light from "assets/images/logo/logo-light.svg";
import { Link, withRouter } from "react-router-dom";
import { setGlobal } from "reactn";
import api from "services/api";
import config from "pwaConfig.json";
import { connect } from "react-redux";
import { login } from "./../../../reducers/auth/actions";
import { save } from "./../../../reducers/DataContent/actions";
import { setConfig } from "./../../../reducers/config/actions";
import { withTranslation } from "react-i18next";
import db from "services/IndexDBStorage";
import Md5 from "crypto-api/src/hasher/md5";
import Hmac from "crypto-api/src/mac/hmac";
import { toHex } from "crypto-api/src/encoder/hex";
import { fromUtf } from "crypto-api/src/encoder/utf";
import SelectLanguage from "../SelectLanguage/SelectLanguage";
import SelectBoard from "../SelectBoard/SelectBoard";
import SelectGrade from "../SelectGrade/SelectGrade";
import initIO from "./../../../actions/initIO";
import store from "./../../../store/store";
import setAuthToken from "./../../../actions/setAuthToken";
import loginImg from "../../../img/login_mes.png";
import UIkit from "uikit";
import {
  saveSubjectDataToLocalStorage,
  saveFeedRailDataToLocalStorage,
  getLRSContent,
  saveLRSSyncDataToLocalStorage,
  getUserContentIfAvailable,
} from "services/dataServices/userContentService";
import { getSelectedBoardAndGradeFromLocal } from "services/dataServices/userProfileService";
import analytics from "services/firebaseServices/firebaseAnalytics";
import {
  getEncodedJWTToken,
  showSuccessMessage,
  showNoInternetAlert,
} from "services/utility";
import stepapp_final_logo from '../../../img/STEPapp_final_logo.png'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { secureStorage } from "services/utility";
import FillName from "../FillName/FillName";
import SelectSchoolNew from "../SelectSchool/SelectSchool_new";
import SelectBatch from "../SelectBatch/SelectBatch";

window.boardList = [];
window.languageList = [];
window.activatedGradesObject = [];
window.activatedGradesList = [];
window.activatedBatchesList = [];
window.profileData = [];

class StudentLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      isOTPGenerated: false,
      otpHash: "",
      spinner: false,
      selectedLanguage: "en",
      selectedGrade: "12",
      selectedBoard: "CBSE",
      selectedBatch: "DSB",
      varifySpinner: false,
      selectLanguagePopup: false,
      selectBoardPopup: false,
      selectBatchPopup: false,
      selectGradePopup: false,
      spinnerForFeed: false,
      country_code: "91",
      validationLength: 10,
      rawPhone: "",
      timer_refresh: null,
      showResend: false,
      enteredCode: "",
      isJoinedClicked: false,
      fillNamePopup: false,
      firstName: "",
      lastName: "",
      email: "",
      selectSchoolPopup: false,
      selectedSchool: null
    };
    window.UserName = this.state.rawPhone;
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.checkStatusOfSelection = this.checkStatusOfSelection.bind(this);
  }

  handleUsernameChange = (value, data, event, formattedValue) => {
    let formatlength = data.format.split(".").length - 1;
    let validationLength = formatlength - data.dialCode.length;
    // console.log("data", data);
    this.setState({
      rawPhone: value.slice(data.dialCode.length),
      country_code: data.dialCode,
      userName: value,
      validationLength: validationLength,
    });

    //this.setState({ userName: event.target.value });
  };

  handleCodeChange = (event) => {
    this.setState({
      enteredCode: event.target.value,
    });

    //this.setState({ userName: event.target.value });
  };
  handlePasswordChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ password: e.target.value });
    }
  };

  handleSubmit = (event) => {
     if (this.state.password.length > 0) {           // comment this if line to remove otp login
     clearInterval(this.state.timer_refresh);
     this.setState({ showResend: true });
    if (this.isValidOTP(this.state.password)) {
      this.saveSelectedSchoolToLocalStorage();
      //  setUserLoggedIn();
      this.getUserProfile();
      clearInterval(this.state.timer_refresh);
      this.setState({ showResend: false });
    } else {
      this.setState({ varifySpinner: false, password: "" });
      alert(this.props.t("onboarding.otp_login.incorrect_OTP"));
    }
    if (event) event.preventDefault();
     } else {                                                        // comment this else code to remove otp login
       alert(this.props.t("onboarding.otp_login.enter_OTP"));
     }
  };
  saveSelectedSchoolToLocalStorage = async () => {
    const school = await db.preSelectedSchool.toArray();
    if (school && school.length > 0) {
      db.selectedSchool.clear();
      db.selectedSchool.put(school[0]).then((school) => { });
    }
  };

  navigateToSelectGrade = () => {
    window.UserName = "";
    this.setState({ selectBoardPopup: false });
    this.getGradesList(window.profileData);
    this.setState({ spinnerForFeed: false });
    this.setState({ selectGradePopup: true });
    // console.log("Grade selection");
  };
  isValidOTP = (OTP) => {
    //return true; // uncomment this code to remove otp login
    let HASHKey = "StepAppSecretKey";
    let hasher = new Md5();
    let hmac = new Hmac(fromUtf(HASHKey), hasher);
    hmac.update(fromUtf("'" + this.state.password + "'"));
    let hmacresult = toHex(hmac.finalize());
    if (hmacresult === this.state.otpHash) {
      return true;
    }
    return false;
  };
  /*
  isValidOTP = async () => {
    this.setState({ varifySpinner: true });
    const inputData = {
      user_mobile: this.state.userName,
      country_code: "+91",
      otp: this.state.password,
      logoutAll: 1,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const { data: validationResponse } = await api.post(
        `${config.sendOtpBaseUrl}/verify/mobile/otp`,
        inputData,
        {
          headers: headers,
        }
      );
      console.log("validationResponse", validationResponse);
      if (validationResponse && validationResponse.status === "success") {
        this.saveSelectedSchoolToLocalStorage();
        setUserLoggedIn();
        this.getUserProfile();
      } else {
        this.setState({ varifySpinner: false });
        alert("Incorrect OTP");
      }
    } catch (error) {
      console.log("validationResponse inner", error);
      this.setState({ varifySpinner: false });
      alert("Incorrect OTP Error");
    }
  };
*/

  sendOTPRequest = async (inputData) => {
    const headers = {
      "Content-Type": "application/json",
    };
    // const { data: userProfileData } = await api.post(
    //   `${config.smsUrl}/send/otp-to-verify-mobile`,
    //   inputData,
    //   {
    //     headers: headers,
    //   }
    // );
    const userProfileData = await api.post(
      `${config.smsUrl}/send/otp-to-verify-mobile`,
      inputData,
      {
        headers: headers,
      }
    );
    return userProfileData;
  };
  sendOTP = async (event) => {
    if (navigator.onLine) {
      if (this.state.rawPhone.length > 5) {
        if (this.state.isOTPGenerated) {
          this.setState({ showResend: false });
          this.circularTimer(0);
          this.setState({ varifySpinner: true, password: "" });
        } else {
          this.setState({ spinner: true, password: "" });
        }
        const inputData = {
          user_mobile: this.state.rawPhone,
          country_code: this.state.country_code,
          native_app_call: "true",
        };
        let userProfileData = await this.sendOTPRequest(inputData);
        if (userProfileData && userProfileData.statusCode === 200) {
          this.setState({
            otpHash: userProfileData.body,
            isOTPGenerated: true,
          });
        }

        this.circularTimer(0);
        // showSuccessMessage(
        //   this.props.t("onboarding.otp_login.otp_success_message"),
        //   1
        // );
        this.setState({ spinner: false, varifySpinner: false });
        if (event) event.preventDefault();
      } else {
        alert(
          this.props.t("onboarding.otp_login.please_enter_valid_mobile_number")
        );
        // `Please enter ${this.state.validationLength} digit valid Mobile Number`
      }
    } else {
      showNoInternetAlert();
    }
    //this.handleSubmit(); // uncomment this code to remove otp login
  };
  sendOTPforCode = async (event) => {
    if (navigator.onLine) {
      if (this.state.enteredCode.length === 14) {
        if (this.state.isOTPGenerated) {
          this.setState({ showResend: false });
          this.circularTimer(0);
          this.setState({ varifySpinner: true, password: "" });
        } else {
          this.setState({ spinner: true, password: "" });
        }
        const inputData = {
          user_mobile: this.state.enteredCode,
          country_code: "91",
          native_app_call: "true",
        };
        let userProfileData = await this.sendOTPRequest(inputData);
        if (userProfileData && userProfileData.statusCode === 200) {
          this.setState({
            otpHash: userProfileData.body,
            isOTPGenerated: true,
            isJoinedClicked: true,
          });
        }
        this.circularTimer(0);
        // showSuccessMessage(
        //   "Otp sent successfully on registered mobile number",
        //   1
        // );
        this.setState({
          spinner: false,
          varifySpinner: false,
          rawPhone: this.state.enteredCode,
        });
        if (event) event.preventDefault();
      } else {
        alert(
          this.props.t("onboarding.otp_login.please_enter_valid_14_digit_code")
        );
        // `Please enter ${this.state.validationLength} digit valid Mobile Number`
      }
    } else {
      showNoInternetAlert();
    }
  };
  getUserProfile = async () => {
    this.setState({ varifySpinner: true });
    const inputData = {
      user_mobile: this.state.rawPhone,
      country_code: this.state.country_code,
      school_id: this.props.match.params.schlId,
    };
    let authToken = getEncodedJWTToken(inputData);
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };
    // const { data: userProfileData } = await api.post(
    //   `${config.apiEndPoint}/user`,
    //   inputData,
    //   {
    //     headers: headers,
    //   }
    // );

    // const userDetailData = await api.post(
    //   `https://4810dh8vqi.execute-api.ap-south-1.amazonaws.com/PROD/verify/mobile/otp`,
    //   {
    //     "user_mobile": this.state.rawPhone,
    //     "country_code": "+91",
    //     "otp": this.state.password,
    //     "logoutAll": 1
    // },
    //   {handleSubmit
    //     headers: headers,
    //   }
    // );

    // console.log(userDetailData);
    // let newUserObj = {}
    // newUserObj.boardAlpaId = userDetailData.board_id
    // newUserObj.gradeAlpaId = userDetailData.grade_id
    // this.props.login({ user: newUserObj });
    console.log("inputData_inputData", inputData)
    const userProfileData = await api.post(
      `${config.apiEndPoint}/user`,
      inputData,
      {
        headers: headers,
      }
    );

    try {
      let response = await api.post(
        `${config.apiEndPoint}/sync_lrs_mongo_to_elastic`,
        userProfileData.id,
        {
          headers: { "Content-Type": "application/json" },
        })
      console.log("response_newAPI", response)
      // ;
    }
    catch (error) {
      console.log("err -", error);
    }

    this.props.login({ user: userProfileData });
    analytics.logEvent("Login", { login: true });
    this.setState({ userData: userProfileData });
    //  this.saveUserDataToLocalStorage(userProfileData);
    this.showUserData(userProfileData);
    // console.log("userdata", userProfileData);
  };

  // saveUserDataToLocalStorage = (data) => {
  //   if (data) {
  //     db.userData.clear();
  //     db.userData.put({ ...data }).then((user) => {});
  //     this.setState({ userData: data });
  //   }
  // };

  setUserProfile = async () => {
    this.setState({ varifySpinner: true });
    const inputData = {
      user_mobile: this.state.rawPhone,
      country_code: this.state.country_code,
      //school_id: this.props.match.params.schlId,
      //school_id: parseInt(this.state.selectedSchool) ? parseInt(this.state.selectedSchool) : this.state.userData.school_id,
      school_id: this.state.userData.school_id==1?'4688':this.state.userData.school_id,
      // school_id:4048,
      first_name: this.state.firstName ? this.state.firstName : this.state.userData.first_name,
      last_name: this.state.lastName ? this.state.lastName : this.state.userData.last_name,
      ...(this.state.email || this.state.userData.user_email ? { user_email: this.state.email ? this.state.email : this.state.userData.user_email } : {}),
      board_name: 'CBSE',
      lang_pref: 'en',
      grade_name: '12',
      user_batch: 'DSB',
      migratedId: this.state.userData.migratedId, // this is for padhao login token generation
      id: this.state.userData.id,
    };
    console.log("here userData: ", this.state.userData, inputData);
    let authToken = getEncodedJWTToken(inputData);
    secureStorage.setItem("token", authToken);
    setAuthToken(authToken);
    setGlobal({ token: authToken });
    // store.dispatch(initIO(authToken));
    setGlobal({ user: this.state.userData });

    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };
    // const { data: userProfileData } = await api.post(
    //   `${config.apiEndPoint}/user`,
    //   inputData,
    //   {
    //     headers: headers,
    //   }
    // );
    var userProfileData
    try {

      console.log("payload ", JSON.stringify(inputData))
      console.log("payload header ", JSON.stringify(headers))
      userProfileData = await api.post(
        `${config.apiEndPoint}/user`,
        inputData,
        {
          headers: headers,
        }
      );
    }
    catch (error) {
      console.log("err -", error);
    }

    try {
      let response = await api.post(
        `${config.apiEndPoint}/sync_lrs_mongo_to_elastic`,
        userProfileData.id,
        {
          headers: { "Content-Type": "application/json" },
        })
      console.log("response_newAPI", response)
    }
    catch (error) {
      console.log("err -", error);
    }

    window.profileData = userProfileData;
    this.props.login({ user: userProfileData });
    this.setState({ userData: userProfileData });
    //this.saveUserDataToLocalStorage(userProfileData);
    console.log("inputData_after_creation", inputData)
  };

  showUserData = (profileData) => {
    if (this.idUsageAllowed(profileData.config)) {
      this.parseProfileData(profileData);
      this.getLanguageList(profileData);
      this.getBoardList(profileData);
      window.profileData = profileData;
      this.checkStatusOfSelection();
    }
  };

  checkStatusOfSelection = async () => {
     
    console.log(
      "OTP login ",
      this.state.selectedLanguage,
      this.state.selectedBoard,
      this.state.selectedGrade,
      this.state.selectedBatch
    );
    if (
      this.state.firstName.length > 0

    ) {
      // Language, Board and Grade are available
      localStorage.setItem("I18N_LANGUAGE", this.state.selectedLanguage);
      // console.log("Language, Board and Grade selected");
      await this.setUserProfile();
      this.apiCall();

      //Deleting content
      window.boardList = [];
      window.languageList = [];
      window.activatedGradesObject = [];
      window.activatedGradesList = [];
      window.activatedBatchesList = []
      // window.profileData = [];
    } else {
      if (this.state.userData) {
        if (this.state.userData.school?.activatedLanguages?.length) {
          const activatedLanguages =
            this.state.userData.school.activatedLanguages;
          let selectedLanguage = this.state.selectedLanguage;

          if (activatedLanguages.length === 1) {
            selectedLanguage = activatedLanguages[0];
          } else if (
            this.state.userData.lang_pref &&
            activatedLanguages.includes(this.state.userData.lang_pref)
          ) {
            selectedLanguage = this.state.userData.lang_pref;
          }
          this.setState({ selectedLanguage });
        }
        if (
          this.state.userData.school.activatedBoards &&
          this.state.userData.school.activatedBoards.length === 1
        ) {
          let board = this.state.userData.school.activatedBoards[0];
          this.setState({
            selectedBoard: board.name,
          });
          if (board.activatedGrades && board.activatedGrades.length === 1) {
            this.setState({
              selectedGrade: board.activatedGrades[0],
            });
          }
        }
      }
      if (
        this.state.firstName.length > 0

      ) {
        // Language, Board and Grade are available
        localStorage.setItem("I18N_LANGUAGE", this.state.selectedLanguage);

        await this.setUserProfile();
        this.apiCall();

        //Deleting content
        window.boardList = [];
        window.languageList = [];
        window.activatedGradesObject = [];
        window.activatedGradesList = [];
        window.activatedBatchesList = [];
        // window.profileData = [];
      }
    }
    if (
      //Where nothing is selected
      this.state.firstName === "" && this.state.email === "" &&
      this.state.selectedLanguage.length == 0 &&
      this.state.selectedBoard.length == 0 &&
      this.state.selectedGrade.length == 0
    ) {
      this.setState({ fillNamePopup: true })
    }

  };

  getBoardList = (data) => {
    data.school.activatedBoards.forEach((element) => {
      window.boardList.push(element.name);
    });
  };

  getLanguageList = (data) => {
    data.school.activatedLanguages.forEach((element) => {
      window.languageList.push(element);
    });
  };

  getGradesList = (data) => {
    let temp = window.profileData.school.activatedBoards.filter((element) =>
      element.name.includes(this.state.selectedBoard)
    );
    window.activatedGradesList = [];
    temp[0].activatedGrades &&
      temp[0].activatedGrades.forEach((element) => {
        window.activatedGradesList.push(element);
      });

    window.activatedBatchesList = ["MA", "MB", "MC", "MD", "ME", "MF", "WA", "WB", "WC"]
  };

  parseProfileData = (data) => {
     
    this.setState({
      firstName: data.first_name,
      selectedSchool: data.school_id,
      selectedBoard: data.board_name,
      selectedLanguage: data.lang_pref,
      selectedGrade: data.grade_name,
      selectedBatch: data.user_batch
    });
  };
  idUsageAllowed = (configData) => {
    if (configData.allowUsage === "true") {
      return true;
    } else {
      this.setState({ varifySpinner: false });
      alert(configData.cta.message);
      return false;
    }
  };

  apiCall = async () => {
    localStorage.setItem("I18N_LANGUAGE", this.state.selectedLanguage);
    this.props.i18n.changeLanguage(this.state.selectedLanguage);
    this.setState({ spinnerForFeed: true });
    this.setState({ varifySpinner: true });
    try {
      const userData = await getSelectedBoardAndGradeFromLocal();
      console.log("userData_store", userData);
      if (userData) {
        const queryString =
          userData.selectedBoard +
          `&gradeId=` +
          encodeURIComponent(userData.selectedGrade) +
          `&schoolId=` +
          userData.selectedSchool +
          `&language=` +
          userData.selectedLanguage +
          `&platform=web`;

        //`${userData.selectedBoard}&gradeId=${userData.selectedGrade}&schoolId=${schoolListData[0].id}&language=${userData.selectedLanguage}`;
        //console.log("queryString", queryString);

        const subjectontentData = await getUserContentIfAvailable(queryString);
        //console.log("subjectontentData", subjectontentData);
        if (subjectontentData) {
          this.setState({ spinnerForFeed: false });
          this.setState({ varifySpinner: false });
          if (
            subjectontentData.content.subjects &&
            subjectontentData.content.subjects.length != 0
          ) {
            window.mainData = subjectontentData.content.subjects;
            //this.props.save(subjectontentData.content.subjects);
            saveSubjectDataToLocalStorage(subjectontentData.content.subjects);
            subjectontentData.feed.items.forEach((element, index) => {
              let indexObject = {
                index: index,
              };
              let newElement = Object.assign(element, indexObject);
              return newElement;
            });
            saveFeedRailDataToLocalStorage(subjectontentData.feed.items); // storing feed rail data
            //  const route = this.props.redirectUrl || "/";
            //  this.props.history.push(route);

            if (window.profileData.user_type === "student") {
              // this.props.history.replace(
              //   ["/login-otp", "/"].includes("entryPath")
              //     ? "entryPath"
              //     : "/concepts"
              // );
              this.props.history.replace("/concepts");
            } else if (window.profileData.user_type === "superadmin") {
              // this.props.history.replace(
              //   ["/login-otp", "/"].includes("entryPath") ? "/" : "entryPath"
              // );
              this.props.history.replace("/select-school");
            } else {
              // this.props.history.replace(
              //   ["/login-otp", "/"].includes("entryPath") ? "/" : "entryPath"
              // );
              this.props.history.replace("/home");
            }

            this.props.setConfig({ redirectUrl: "" });
          } else {
            alert(this.props.t("onboarding.otp_login.no_grade_available"));
            this.props.history.push("/nocontent");
            //this.navigateToSelectGrade();
            this.setState({ spinnerForFeed: false });
            this.setState({ varifySpinner: false });
          }
        } else {
          alert(this.props.t("onboarding.otp_login.no_grade_available"));
          this.props.history.push("/nocontent");
          //this.navigateToSelectGrade();
          this.setState({ spinnerForFeed: false });
          this.setState({ varifySpinner: false });
        }
        const queryStringForLRS =
          `&user_id=` +
          userData.userId +
          `&board_name=` +
          userData.selectedBoard +
          `&grade_name=` +
          encodeURIComponent(userData.selectedGrade);
        const lrsSyncData = await getLRSContent(queryStringForLRS);
        await saveLRSSyncDataToLocalStorage(lrsSyncData.data.body, true);
      }
    } catch (error) {
      alert(error + ", Try again");
    }
  };
  /*
  async apiCall() {
    localStorage.setItem("I18N_LANGUAGE", this.state.selectedLanguage);
    this.props.i18n.changeLanguage(this.state.selectedLanguage);
    this.setState({ varifySpinner: true });
    await api
      .get(
          `${config.apiEndPoint}/feed/learning?boardId=` +
          this.state.selectedBoard +
          `&gradeId=` +
          this.state.selectedGrade +
          `&schoolId=` +
          this.props.match.params.schlId +
          `&language=` +
          this.state.selectedLanguage
      )
      .then((res) => {countdown-number
        this.setState({ varifySpinner: false });
        if (
          res.data.content.subjects &&
          res.data.content.subjects.length != 0
        ) {
          window.mainData = res.data.content.subjects;
          this.props.history.push("/selectsubject");
        } else {
          alert("Sorry, no data available for the selected grade");
          this.navigateToSelectGrade();
          this.setState({ varifySpinner: false });
        }
      })
      .catch((error) => alert(error + ", Try again"));
  }*/
  //circularTimer on quiz
  circularTimer = (index) => {
    var countdownNumberEl = document.getElementById("countdown-number");
    if (countdownNumberEl) {
      var countdown = 30;
      countdownNumberEl.textContent = countdown + " s";
      this.state.timerCircle = true;
      this.state.timer_refresh = setInterval(async () => {
        countdown = --countdown < 0 ? 30 : countdown;
        countdownNumberEl.textContent = countdown + " s";
        if (countdown == 0) {
          await clearInterval(this.state.timer_refresh);

          this.setState({ showResend: true });
        }
      }, 1000);
    }
  };
  handleKeyPress = (event) => {
    // if (event.key === "Enter") {
    //   event.preventDefault();
    //   this.sendOTP();
    // }
  };
  handleCodeKeyPress = (event) => {
    // if (event.key === "Enter") {
    //   event.preventDefault();
    //   this.sendOTPforCode();
    // }
  };
  handleKeyDown = (event) => {
    // if (event.key === "Enter") {
    //   event.preventDefault();
    //   this.sendOTP();
    //   return;
    // }
    if ([69, 187, 188, 189, 190].includes(event.keyCode)) {
      event.preventDefault();
    }
  };
  handleCodeKeyDown = (event) => {
    // if (event.key === "Enter") {
    //   event.preventDefault();
    //   this.sendOTPforCode();
    //   return;
    // }
    if ([69, 187, 188, 189, 190].includes(event.keyCode)) {
      event.preventDefault();
    }
  };
  handleKeyPressForOTP = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.handleSubmit();
    } else {
      var charCode = event.which ? event.which : event.keyCode;
      return !(charCode > 31 && (charCode < 48 || charCode > 57));
    }
  };
  maxLengthValidation = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  componentDidMount() {
    localStorage.setItem("user_type", "student");
  }

  render() {
    let selectLanguageClose = () => this.setState({ selectLanguagePopup: false });
    let selectBoardClose = () => this.setState({ selectBoardPopup: false });
    let selectGradeClose = () => this.setState({ selectGradePopup: false });
    let selectBatchClose = () => this.setState({ selectBatchPopup: false });

    let selectedNameFunc = async (first, last, email) => {
      await this.setState({ firstName: first, lastName: last, email: email, fillNamePopup: false })
      this.checkStatusOfSelection();
    }

    let selectedSchoolFunc = async (value) => {
      await this.setState({ selectedSchool: value, selectSchoolPopup: false })
      this.checkStatusOfSelection();
    }

    console.log("username_is", this.state.userName)

    let selectedLanguageFun = async (value) => {
      await this.setState({ selectedLanguage: value });
      this.checkStatusOfSelection();
    };
    let selectedBoardFun = async (value) => {
      await this.setState({ selectedBoard: value });
      this.checkStatusOfSelection();
    };
    let selectedGradeFun = async (value) => {
      await this.setState({ selectedGrade: value });
      this.checkStatusOfSelection();
    };
    let selectedBatchFun = async (value) => {
      await this.setState({ selectedBatch: value });
      this.checkStatusOfSelection();
    };

    let disabledAddButton = { backgroundColor: "#A5A5A5", border: "none", borderRadius: "5px" }
    let enabledAddButton = { backgroundColor: "#FC8643", cursor: "pointer", border: "none", borderRadius: "5px", color: "#fff" }
    console.log("enteredCode_is", this.state.enteredCode)

    const { t } = this.props;

    return (
      <div>
        <section className="login">
          {/*<div className="container-fluid login-container uk-animation-customslide" style={{display: "none"}}>
            <div className="login_message" style={{ visibility: "hidden" }}>
              <img src={loginImg} />
            </div>
            <div id="header" className="row no-gutters my-auto">
              <div className="col-xs-12 col-sm-12 col-md-12 my-auto text-center">
                <img
                  src={logo_light}
                  width="200px"
                  height="auto"
                  className="img-fluid"
                />
              </div>
            </div>
            <div id="content">
              <div
                className="col-md-12 no-gutters"
                // id="onboarding-content-card "
              >
                {!this.state.selectLanguagePopup &&
                  !this.state.selectBoardPopup &&
                  !this.state.selectGradePopup &&
                  !this.state.fillNamePopup &&
                  !this.state.selectSchoolPopup && (
                    <div className="width-100">
                      <div className="m-auto loginCard ">
                        <div className="row no-gutters my-auto">
                          <div className="col-xs-12 col-sm-12 col-md-12 my-auto text-center">
                            <img
                              src={logo_light}
                              width="200px"
                              height="auto"
                              className="img-fluid"
                            />
                          </div>
                          <div className="cardtitle text-center text-white">
                            {this.state.isJoinedClicked
                              ? this.props.t(
                                  "onboarding.otp_login.sign_in_with_code"
                                )
                              : this.props.t(
                                  "onboarding.otp_login.mobile_sign_in"
                                )}
                          </div>
                        </div>
                        <div className="row my-2 ">
                          <div className="col-sm-7 mx-auto">
                            <div className="width-100 ">
                              {!this.state.isJoinedClicked && (
                                <div className="form-group">
                                  <div className="custom-input-style-normal border-radius-pill p-1">
                                    <PhoneInput
                                      country={"in"}
                                      enableSearch={true}
                                      value={this.state.userName}
                                      id="email"
                                      type="number"
                                      pattern="[0-9]*"
                                      inputmode="numeric"
                                      maxLength={14}
                                      onInput={this.maxLengthValidation}
                                      className=""
                                      name="email"
                                      placeholder="Enter your Mobile Number"
                                      onKeyPress={this.handleKeyPress}
                                      onChange={this.handleUsernameChange}
                                      onKeyDown={this.handleKeyDown}
                                      disabled={this.state.isOTPGenerated}
                                    />
                                  </div>
                                </div>
                              )}
                              {!this.state.spinner ? (
                                !this.state.isOTPGenerated && (
                                  <Fragment>
                                    {" "}
                                    <div className="form-group">
                                      <button
                                        type="button"
                                        className="card-button"
                                        onClick={this.sendOTP}
                                      >
                                        {this.props.t(
                                          "onboarding.otp_login.proceed"
                                        )}
                                      </button>
                                    </div>
                                    <div>
                                      <div className=" form-group text-center text-white">
                                        -{" "}
                                        {this.props.t(
                                          "onboarding.otp_login.OR"
                                        )}
                                        -
                                      </div>
                                      <div>
                                        <input
                                          id="code"
                                          type="number"
                                          pattern="[0-9]*"
                                          inputMode="numeric"
                                          maxLength={14}
                                          onInput={this.maxLengthValidation}
                                          className="input-with-button"
                                          placeholder={this.props.t(
                                            "onboarding.otp_login.join_with_14_digit_code"
                                          )}
                                          onKeyPress={this.handleCodeKeyPress}
                                          onChange={this.handleCodeChange}
                                          onKeyDown={this.handleCodeKeyDown}
                                          disabled={this.state.isOTPGenerated}
                                        />{" "}
                                        <button
                                          type="button"
                                          className="card-button-join"
                                          onClick={this.sendOTPforCode}
                                        >
                                          {this.props.t(
                                            "onboarding.otp_login.join"
                                          )}
                                        </button>
                                        
                                      </div>
                                    </div>
                                  </Fragment>
                                )
                              ) : (
                                <div className="text-center card-button">
                                  <Spinner
                                    className=" "
                                    animation="border"
                                    style={{ color: "#fff" }}
                                    size="md"
                                  />
                                </div>
                              )}
                            </div>

                            {this.state.isOTPGenerated &&
                              this.state.isJoinedClicked && ( // uncomment this code to remove otp login
                                <div>
                                  <div className="input-group form-group">
                                    <input
                                      type="password"
                                      pattern="[0-9]*"
                                      inputmode="numeric"
                                      value={this.state.password}
                                      onChange={this.handlePasswordChange}
                                      className="form-control border-radius-pill text-center"
                                      placeholder={
                                        this.state.isJoinedClicked
                                          ? this.props.t(
                                              "onboarding.otp_login.password"
                                            )
                                          : this.props.t(
                                              "onboarding.otp_login.OTP"
                                            )
                                      }
                                      autoComplete="off"
                                      onKeyPress={this.handleKeyPressForOTP}
                                      maxLength={
                                        this.state.isJoinedClicked ? 20 : 4
                                      }
                                      onInput={this.maxLengthValidation}
                                      required
                                    />
                                  </div>
                                  <div
                                    className="input-group form-group"
                                    style={{ borderRadius: "50px" }}
                                  >
                                    <div className=" col-md-12  time">
                                      {!this.state.showResend &&
                                        !this.state.isJoinedClicked && (
                                          <div
                                            id="countdown-number"
                                            className="timer-text-otp text-center text-white"
                                          ></div>
                                        )}
                                    </div>
                                    <div className=" col-md-12 text-right">
                                      {this.state.showResend &&
                                        !this.state.isJoinedClicked && (
                                          <a
                                            href="#"
                                            className="white-link-u cus_link_lineheight"
                                          >
                                            <Link
                                              onClick={this.sendOTP}
                                              className="white-link-u cus_link_lineheight"
                                            >
                                              {this.props.t(
                                                "onboarding.otp_login.resend_OTP"
                                              )}
                                            </Link>
                                          </a>
                                        )}
                                    </div>
                                  </div>
                                  {!this.state.varifySpinner ? (
                                    <div className="form-group">
                                      <button
                                        type="button"
                                        className="card-button"
                                        id
                                        onClick={this.handleSubmit}
                                      >
                                        {this.props.t(
                                          "onboarding.otp_login.proceed"
                                        )}
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="text-center card-button">
                                      <Spinner
                                        className=" "
                                        animation="border"
                                        style={{ color: "#fff" }}
                                        size="lg"
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                        </div>
                                   
                      </div>
                    </div>
                  )}

                  {this.state.selectSchoolPopup && ( 
                    <SelectSchoolNew
                    spinnerForFeed={this.state.spinnerForFeed}
                    selectedSchoolFunc={selectedSchoolFunc}
                    />
                  )}

                  {this.state.fillNamePopup && ( 
                    <FillName
                    selectedName={selectedNameFunc}
                    />
                    )}
                  
                {this.state.selectLanguagePopup && (
                  <SelectLanguage
                    selectedlanguage={selectedLanguageFun}
                    // show={this.state.selectLanguagePopup}
                    spinnerForFeed={this.state.spinnerForFeed}
                    hidefunction={selectLanguageClose}
                  />
                )}
                {this.state.selectBoardPopup && (
                  <SelectBoard
                    selectedboard={selectedBoardFun}
                    // show={this.state.selectBoardPopup}
                    spinnerForFeed={this.state.spinnerForFeed}
                    hidefunction={selectBoardClose}
                  />
                )}
                {this.state.selectGradePopup && (
                  <SelectGrade
                    selectedgrade={selectedGradeFun}
                    // show={this.state.selectGradePopup}
                    spinnerForFeed={this.state.spinnerForFeed}
                    hidefunction={selectGradeClose}
                  />
                )}
              </div>
              <div
                id="copyright"
                className="uk-position-bottom-center uk-position-small uk-visible@m uk-position-z-index"
              >
                <div className="col-sm-12 mx-auto my-auto ">
                  <div className=" text-center my-auto footer-text ">
                    {" "}
                    {this.props.t("footer.all_right_reserved")} |{" "}
                    {this.props.t("footer.stepapp")} {new Date().getFullYear()}{" "}
                    | {this.props.t("footer.version")}{" "}
                    {process.env.REACT_APP_VERSION}
                  </div>
                </div>
              </div>
            </div>
          </div>*/}

          {/* JQUERY STEP */}
          {/* Popper.JS */}
          {/* jQuery if needed */}
          <div className="login uk-cover-container uk-flex uk-flex-center uk-flex-middle uk-overflow-hidden uk-light">
            <div className="login-scrollable uk-flex uk-flex-center uk-flex-middle uk-position-z-index">
              <div className="login-inner uk-width-medium uk-animation-customslide">
                {!this.state.selectLanguagePopup &&
                  !this.state.selectBoardPopup &&
                  !this.state.selectGradePopup &&
                  !this.state.selectBatchPopup &&
                  !this.state.fillNamePopup &&
                  !this.state.selectSchoolPopup && (
                    <div style={{ width: "min(90vw, 400px)", backgroundColor: "#FFFFFF", borderRadius: "15px", padding: "1rem clamp(1rem, 4vw, 3rem)" }}>
                      <div className="d-flex flex-column align-items-center justify-content-center my-2">
                        <div>
                          <img
                            src={stepapp_final_logo}
                            alt=""
                            width="146"
                          />
                        </div>
                        <div className="my-2" style={{ color: "#FC8643", font: "normal normal 500 1.2rem/1.6rem Poppins" }}>Sign In</div>
                      </div>
                      {!this.state.isJoinedClicked && (
                        <div className="d-grid">
                          <div className="d-flex justify-content-start" style={{ color: "#767676", font: "normal normal 400 0.7rem/1.2rem Poppins" }}>Mobile number</div>
                          <div className="custom-input-style-normal p-1 mt-1 mb-2" style={{ border: "1px solid #969696", height: "2.8rem !important", borderRadius: "0.5rem" }}>
                            <PhoneInput
                              country={"in"}
                              enableSearch={true}
                              value={this.state.userName}
                              // onChange={(phone) =>
                              //   this.setState({ userName: phone })
                              // }
                              id="email"
                              type="number"
                              pattern="[0-9]*"
                              autoFormat={false}
                              // inputmode="numeric"
                              inputProps={{ maxLength: 13 }}
                              onInput={this.maxLengthValidation}
                              className=""
                              name="email"
                              placeholder="Enter your Mobile Number"
                              onKeyPress={this.handleKeyPress}
                              onChange={this.handleUsernameChange}
                              onKeyDown={this.handleKeyDown}
                              disabled={this.state.isOTPGenerated}
                            />
                          </div>
                        </div>
                      )}
                      {this.state.isOTPGenerated &&(
                        // this.state.isJoinedClicked && ( // uncomment this code to remove otp login
                          <div>
                            <div className="my-2">
                              <input
                                type="password"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                value={this.state.password}
                                onChange={this.handlePasswordChange}
                                className="form-control text-center"
                                placeholder={
                                  this.state.isJoinedClicked
                                    ? this.props.t(
                                      "onboarding.otp_login.password"
                                    )
                                    : this.props.t(
                                      "onboarding.otp_login.OTP"
                                    )
                                }
                                autoComplete="off"
                                onKeyPress={this.handleKeyPressForOTP}
                                maxLength={
                                  this.state.isJoinedClicked ? 20 : 4
                                }
                                onInput={this.maxLengthValidation}
                                required
                                style={{ border: "1px solid #969696", height: "2.8rem !important", borderRadius: "0.5rem" }}
                              />
                            </div>
                            <div className="my-3">
                              {!this.state.showResend &&
                                !this.state.isJoinedClicked && (
                                  <div className="width-100 time">
                                    <div
                                      className="timer-text-otp text-center"
                                      id="countdown-number"
                                      style={{ color: "#000000", font: "normal normal 600 1rem/normal Poppins" }}
                                    />
                                  </div>
                                )}
                              {this.state.showResend &&
                                !this.state.isJoinedClicked && (
                                  <div className="width-100 text-center resend_otp">
                                    <a
                                      href="#"
                                      style={{ font: "normal normal 600 0.8rem/normal Poppins" }}
                                    >
                                      <Link
                                        onClick={this.sendOTP}
                                        style={{ font: "normal normal 600 1rem/normal Poppins" }}
                                      >
                                        {this.props.t(
                                          "onboarding.otp_login.resend_OTP"
                                        )}
                                      </Link>
                                    </a>
                                  </div>
                                )}
                            </div>
                            {!this.state.varifySpinner ? (
                              <div className="row mx-0 my-2" style={{ height: "2.8rem" }}>
                                <button
                                  type="button"
                                  className="w-100"
                                  disabled={this.state.password.length >= 4 ? false : true}
                                  style={this.state.password.length >= 4 ? enabledAddButton : disabledAddButton}
                                  onClick={this.handleSubmit}
                                >
                                  {this.props.t(
                                    "onboarding.otp_login.proceed"
                                  )}
                                </button>
                              </div>
                            ) : (
                              <div className="text-center card-button" style={{ height: "2.8rem", borderRadius: "8px" }}>
                                <Spinner
                                  className=" "
                                  animation="border"
                                  style={{ color: "#fff" }}
                                  size="md"
                                />
                              </div>
                            )}
                          </div>
                        )}
                      {!this.state.spinner ? (
                        !this.state.isOTPGenerated && (
                          <>
                            <div className='row mx-0 my-2' style={{ height: "2.8rem" }}>
                              <button
                                className='w-100'
                                disabled={this.state.userName.length == 12 ? false : true}
                                style={this.state.userName.length == 12 ? enabledAddButton : disabledAddButton}
                                onClick={this.sendOTP}
                              >
                                {this.props.t("onboarding.otp_login.proceed")}
                              </button>
                            </div>
                            <div className="d-flex justify-content-center my-2">
                              <span style={{ font: "normal normal 400 1.2rem/1.8rem Poppins", color: "#2B2B2B" }}>-{this.props.t("onboarding.otp_login.OR")}-</span>
                            </div>

                            <div className="row mx-0 mt-0 mb-2 justify-content-between" style={{ border: "1px solid #969696", borderRadius: "0.5rem", height: "2.8rem" }}>
                              <div className="col-9">
                                <input
                                  id="code"
                                  type="number"
                                  pattern="[0-9]*"
                                  inputMode="numeric"
                                  maxLength={14}
                                  onInput={this.maxLengthValidation}
                                  className="input-with-button"
                                  placeholder={this.props.t(
                                    "onboarding.otp_login.join_with_14_digit_code"
                                  )}
                                  onKeyPress={this.handleCodeKeyPress}
                                  onChange={this.handleCodeChange}
                                  onKeyDown={this.handleCodeKeyDown}
                                  disabled={this.state.isOTPGenerated}
                                  style={{ width: "100%", border: "none", backgroundColor: "#ffffff", color: "#000000" }}
                                />
                              </div>
                              <div className="col-3 col-3 px-2 my-auto">
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    className="px-3 py-1"
                                    disbaled={this.state.enteredCode.length >= 10 ? false : true}
                                    style={this.state.enteredCode.length >= 10 ? enabledAddButton : disabledAddButton}
                                    onClick={this.sendOTPforCode}
                                  >
                                    {this.props.t(
                                      "onboarding.otp_login.join"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      )
                        :
                        <div className="text-center card-button" style={{ height: "2.8rem", borderRadius: "8px" }}>
                          <Spinner
                            className=" "
                            animation="border"
                            style={{ color: "#fff" }}
                            size="md"
                          />
                        </div>
                      }
                      <div className="row m-0 text-center my-2">
                        <div style={{ font: "normal normal 400 0.8rem/1.2rem Poppins", color: "#767676" }}>By signing up, you agree to our <a href="https://www.stepapp.ai/privacy" target="_blank" style={{ color: "#767676" }}><u>privacy</u></a> and <a href="https://www.stepapp.ai/term" target="_blank" style={{ color: "#767676" }}><u>terms & conditions</u></a> of use.</div>
                      </div>

                    </div>
                  )
                }
                {this.state.selectSchoolPopup && (
                  <SelectSchoolNew
                    spinnerForFeed={this.state.spinnerForFeed}
                    selectedSchoolFunc={selectedSchoolFunc}
                  />
                )}

                {this.state.fillNamePopup && (
                  <FillName
                    selectedName={selectedNameFunc}
                  />
                )}

                {this.state.selectLanguagePopup && (
                  <SelectLanguage
                    selectedlanguage={selectedLanguageFun}
                    // show={this.state.selectLanguagePopup}
                    spinnerForFeed={this.state.spinnerForFeed}
                    hidefunction={selectLanguageClose}
                  />
                )}
                {this.state.selectBoardPopup && (
                  <SelectBoard
                    selectedboard={selectedBoardFun}
                    // show={this.state.selectBoardPopup}
                    spinnerForFeed={this.state.spinnerForFeed}
                    hidefunction={selectBoardClose}
                  />
                )}
                {this.state.selectGradePopup && (
                  <SelectGrade
                    selectedSchool={this.state.selectedSchool}
                    selectedgrade={selectedGradeFun}
                    // show={this.state.selectGradePopup}
                    spinnerForFeed={this.state.spinnerForFeed}
                    hidefunction={selectGradeClose}
                  />
                )}
                {this.state.selectBatchPopup && (
                  <SelectBatch
                    selectedSchool={this.state.selectedSchool}
                    selectedbatch={selectedBatchFun}
                    spinnerForFeed={this.state.spinnerForFeed}
                    hidefunction={selectBatchClose}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            id="copyright"
            className="uk-position-bottom-center uk-position-small uk-visible@m uk-position-z-index"
          >
            <div className="col-sm-12 mx-auto my-auto ">
              <div className=" text-center my-auto footer-text ">
                {this.props.t("footer.all_right_reserved")} |{" "}
                {this.props.t("footer.stepapp")} {new Date().getFullYear()}{" "}
                | {this.props.t("footer.version")}{" "}
                {process.env.REACT_APP_VERSION}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = { login, save, setConfig };

const mapStateToProps = ({ config }) => ({
  redirectUrl: config?.redirectUrl,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(StudentLogin)));
