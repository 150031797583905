import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./index_media.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import init from "./init";
import { Provider } from "react-redux";
import store from "./store/store";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import "./external-dashboard-css.css";
import './katex.min.css'

init();

UIkit.use(Icons);

console.log = () => {}
console.error = () => {}
console.debug = () => {}


ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <App />     
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
