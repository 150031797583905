import React from 'react'
import {Doughnut} from 'react-chartjs-2';

const gaugeChart = ({displayChapterData}) => {
  const gauge_data = displayChapterData;
  console.log("copy",JSON.stringify(displayChapterData))

  const data = {
    labels: [
      'Completed',
      'Not completed'
    ],
    datasets: [{
      label: 'HW user activity',
      data: [gauge_data?.active_student_count,gauge_data?.inactive_student_count],
      backgroundColor: [
        '#FC8643',
        '#FFF',

      ],
      hoverOffset: 4,
      borderColor: "#FC8643",
    }]
  };

  const options = {
    rotation: 1 * Math.PI,
    circumference: 1 * Math.PI,
    legend: {
      display: false
    },
    tooltip: {
      enabled: true
    },
    cutoutPercentage: 75,

    animation: {animateScale: "true",animateRotate: "true"},
    elements: {
      arc: {
        borderJoinStyle: "bevel",
        borderRadius: "10px",
      }
    }

  }

  return (
    <div className='mx-2' style={{padding: "36px 26px 36px 26px",border: "1px solid #FC8643",boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",borderRadius: "16px"}}>
      <div style={{position: "relative",backgroundColor: "#FFF",borderRadius: "16px",padding: "10px"}} className="chart-wrapper width-100">
        <Doughnut data={data} options={options} />
        <div className='d-sm-grid position-absolute' style={{bottom: 0,fontSize: "24px",justifyContent: "center",alignItems: "center"}}>
          <span className='pt-small font-weight-normal' style={{color: '#615E83'}}>Total</span>
          <span className='pt-small font-weight-normal' style={{color: '#615E83'}}> Students</span>
          <p className='pt-header font-weight-bold text-center' style={{color: '#2B2B2B'}}>{gauge_data?.total_count}</p>
        </div>
      </div>

      <div className='legends d-flex flex-column ' style={{position: "relative",top: "20px"}}>

        <div className='completed d-flex align-items-center ' style={{paddingLeft: "10px"}}>
          <div className='circle p-2' style={{borderRadius: "50%",backgroundColor: "#FC8643",border: "1px solid #FC8643",minWidth: "14.22px",minHeight: "14.22px",maxWidth: "14.22px",maxHeight: "14.22px"}} />
          <div className='pt-small' style={{color: '#2B2B2B',padding: "16px"}}>Completed</div>
          <div className='pt-small' style={{textAlign: "right",color: "#776677"}}>{gauge_data?.active_student_count}</div>
        </div>
        <div className='not_completed d-flex align-items-center ' style={{paddingLeft: "10px"}}>
          <div className='circle p-2' style={{borderRadius: "50%",backgroundColor: "#FFF",border: "1px solid #FC8643",minWidth: "14.22px",minHeight: "14.22px",maxWidth: "14.22px",maxHeight: "14.22px"}} />
          <div className='pt-small' style={{color: '#2B2B2B',padding: "16px"}}>Not completed</div>
          <div className='pt-small' style={{textAlign: "right",color: "#776677"}}>{gauge_data?.inactive_student_count}</div>
        </div>

      </div>
    </div>
  )
}

export default gaugeChart