import React from 'react'
import { Bar } from 'react-chartjs-2';
import "../Homework/HomeWork.scss";

const HWStudentScore = (props) => { 
  let histo =[]
    if(props.displayChapterData){
      histo=props.displayChapterData.histogram
    }
  const options={
    responsive: true,
    title:  {display:false},
    legend: {display: false},
    scales: {
      xAxes:  [{
        gridLines: {
           display: false
        }
     }],
      yAxes:  [{
        gridLines: {
           display: true,
           
        }
     }],
     y: {
      beginAtZero: true
    }
    }
  
  }

  const labels=[]

    histo.map((lab)=> labels.push(lab.key) )


  let count=[]

  histo.map((hist)=> count.push(hist.count) )


  const data = {
      labels,
      datasets: [
        {
          label: 'count',
          data: count,
          backgroundColor: "#FC8643",
          borderColor: "#FC8643",
          fill: false,
          lineTension: 0,
          borderWidth: 1.3131,
          borderRadius: 4
        },
      ]
    };

    const downloadUserData = (myId) => {
      let data = [];
      let data1=props.displayChapterData?.active_student_list.map((s)=> ({
        "full_name": s.full_name,
        "score": s.score,
        "rank": s.rank,
        "active/inactive": "active",
      }))

      let data2=props.displayChapterData?.inactive_student_list.map((s)=> ({
        "full_name": s.full_name,
        "score": s.score,
        "rank": s.rank,
        "active/inactive": "inactive",
      }))

      data=[...data1, data2]

      return data;

  }



    return (
        <div className="allContainer mt-1">
            <div className='studnetScoreHeader'>
                <div className="leftSideTextContainer">                   
                    <span className='pt-small font-weight-bold'>Student score</span>
                    <p className='pt-small font-weight-normal' style={{color:"#9291A5",marginTop:"8px"}}>Average score : {props.displayChapterData?.score.toFixed(2)}</p>
                </div>
            </div>
            <Bar options={options} data={data} updateMode={"resize"}/>
        </div>
    )
}

export default HWStudentScore