import React, { Fragment } from "react";
import { Row, Col, Container, Spinner, ButtonToolbar, Alert } from "reactstrap";
import logo_light from "assets/images/logo/logo-light.svg";

//added by mahesh
import { Link, withRouter } from "react-router-dom";
import { setGlobal } from "reactn";
import api from "services/api";
import config from "pwaConfig.json";
import { connect } from "react-redux";
import { login } from "./../../../reducers/auth/actions";
import { save } from "./../../../reducers/DataContent/actions";
import { setConfig } from "./../../../reducers/config/actions";
import { withTranslation } from "react-i18next";
import db from "services/IndexDBStorage";
import Md5 from "crypto-api/src/hasher/md5";
import Hmac from "crypto-api/src/mac/hmac";
import { toHex } from "crypto-api/src/encoder/hex";
import { fromUtf } from "crypto-api/src/encoder/utf";
import SelectLanguage from "../SelectLanguage/SelectLanguage";
import SelectBoard from "../SelectBoard/SelectBoard";
import SelectGrade from "../SelectGrade/SelectGrade";
import initIO from "./../../../actions/initIO";
import store from "./../../../store/store";
import setAuthToken from "./../../../actions/setAuthToken";
import {
  saveSubjectDataToLocalStorage,
  saveFeedRailDataToLocalStorage,
  getLRSContent,
  saveLRSSyncDataToLocalStorage,
  getUserContentIfAvailable,
} from "services/dataServices/userContentService";
import { getSelectedBoardAndGradeFromLocal } from "services/dataServices/userProfileService";
import analytics from "services/firebaseServices/firebaseAnalytics";
import {
  getEncodedJWTToken,
  showNoInternetAlert,
} from "services/utility";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import FillName from "../FillName/FillName";

import { secureStorage } from "services/utility";

window.boardList = [];
window.languageList = [];
window.activatedGradesObject = [];
window.activatedGradesList = [];
window.profileData = [];

class StudentLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      isOTPGenerated: false,
      otpHash: "",
      spinner: false,
      selectedLanguage: "",
      selectedGrade: "",
      selectedBoard: "",
      varifySpinner: false,
      selectLanguagePopup: false,
      selectBoardPopup: false,
      selectGradePopup: false,
      spinnerForFeed: false,
      country_code: "91",
      validationLength: 10,
      rawPhone: "",
      timer_refresh: null,
      showResend: false,
      enteredCode: "",
      isJoinedClicked: false,

    };
    window.UserName = this.state.rawPhone;
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.checkStatusOfSelection = this.checkStatusOfSelection.bind(this);
    localStorage.clear();
  }

  handleUsernameChange = (value, data, event, formattedValue) => {
    let formatlength = data.format.split(".").length - 1;
    let validationLength = formatlength - data.dialCode.length;
    this.setState({
      rawPhone: value.slice(data.dialCode.length),
      country_code: data.dialCode,
      userName: value,
      validationLength: validationLength,
    });

  };

  handleCodeChange = (event) => {
    this.setState({
      enteredCode: event.target.value,
    });

  };
  handlePasswordChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ password: e.target.value });
    }
  };

  handleSubmit = (event) => {
    if (this.state.password.length > 0) {           // comment this if line to remove otp login
      clearInterval(this.state.timer_refresh);
      this.setState({ showResend: true });
      if (this.isValidOTP(this.state.password)) {
        this.saveSelectedSchoolToLocalStorage();
        this.getUserProfile();
        clearInterval(this.state.timer_refresh);
        this.setState({ showResend: false });
      } else {
        this.setState({ varifySpinner: false, password: "" });
        alert(this.props.t("onboarding.otp_login.incorrect_OTP"));
      }
      if (event) event.preventDefault();
    } else {                                                        // comment this else code to remove otp login
      alert(this.props.t("onboarding.otp_login.enter_OTP"));
    }
  };
  saveSelectedSchoolToLocalStorage = async () => {
    const school = await db.preSelectedSchool.toArray();
    if (school && school.length > 0) {
      db.selectedSchool.clear();
      db.selectedSchool.put(school[0]).then((school) => { });
    }
  };

  navigateToSelectGrade = () => {
    window.UserName = "";
    this.setState({ selectBoardPopup: false });
    this.getGradesList(window.profileData);
    this.setState({ spinnerForFeed: false });
    this.setState({ selectGradePopup: true });
  };
  isValidOTP = (OTP) => {
    let HASHKey = "StepAppSecretKey";
    let hasher = new Md5();
    let hmac = new Hmac(fromUtf(HASHKey), hasher);
    hmac.update(fromUtf("'" + this.state.password + "'"));
    let hmacresult = toHex(hmac.finalize());
    if (hmacresult === this.state.otpHash) {
      return true;
    }
    return false;
  };
 

  sendOTPRequest = async (inputData) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const userProfileData = await api.post(
      `${config.smsUrl}/send/otp-to-verify-mobile`,
      inputData,
      {
        headers: headers,
      }
    );
    return userProfileData;
  };
  sendOTP = async (event) => {
    if (navigator.onLine) {
      if (this.state.rawPhone.length > 5) {
        if (this.state.isOTPGenerated) {
          this.setState({ showResend: false });
          this.circularTimer(0);
          this.setState({ varifySpinner: true, password: "" });
        } else {
          this.setState({ spinner: true, password: "" });
        }
        const inputData = {
          user_mobile: this.state.rawPhone,
          country_code: this.state.country_code,
          native_app_call: "true",
        };
        let userProfileData = await this.sendOTPRequest(inputData);
        if (userProfileData && userProfileData.statusCode === 200) {
          this.setState({
            otpHash: userProfileData.body,
            isOTPGenerated: true,
          });
        }
        this.circularTimer(0);

        this.setState({ spinner: false, varifySpinner: false });
        if (event) event.preventDefault();
      } else {
        alert(
          this.props.t("onboarding.otp_login.please_enter_valid_mobile_number")
        );
      }
    } else {
      showNoInternetAlert();
    }
  };
  sendOTPforCode = async (event) => {
    if (navigator.onLine) {
      if (this.state.enteredCode.length === 14) {
        if (this.state.isOTPGenerated) {
          this.setState({ showResend: false });
          this.circularTimer(0);
          this.setState({ varifySpinner: true, password: "" });
        } else {
          this.setState({ spinner: true, password: "" });
        }
        const inputData = {
          user_mobile: this.state.enteredCode,
          country_code: "91",
          native_app_call: "true",
        };
        let userProfileData = await this.sendOTPRequest(inputData);
        if (userProfileData && userProfileData.statusCode === 200) {
          this.setState({
            otpHash: userProfileData.body,
            isOTPGenerated: true,
            isJoinedClicked: true,
          });
        }
        this.circularTimer(0);
           this.setState({
          spinner: false,
          varifySpinner: false,
          rawPhone: this.state.enteredCode,
        });
        if (event) event.preventDefault();
      } else {
        alert(
          this.props.t("onboarding.otp_login.please_enter_valid_14_digit_code")
        );
      }
    } else {
      showNoInternetAlert();
    }
  };
  getUserProfile = async () => {
    this.setState({ varifySpinner: true });
    const inputData = {
      user_mobile: this.state.rawPhone,
      country_code: this.state.country_code,
      school_id: this.props.match.params.schlId,
    };
    let authToken = getEncodedJWTToken(inputData);
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };
   
    const userProfileData = await api.post(
      `${config.apiEndPoint}/user`,
      inputData,
      {
        headers: headers,
      }
    );

    try {
      let response = await api.post(
        `${config.apiEndPoint}/sync_lrs_mongo_to_elastic`,
        userProfileData.id,
        {
          headers:  {"Content-Type": "application/json"},
        })
        console.log("response_newAPI",response)
        //debugger;
      }
      catch (error) {
         console.log("err -", error);
      }

    this.props.login({ user: userProfileData });
    analytics.logEvent("Login", { login: true });
    this.setState({ userData: userProfileData });
    //  this.saveUserDataToLocalStorage(userProfileData);
    this.showUserData(userProfileData);
    // console.log("userdata", userProfileData);
  };

  // saveUserDataToLocalStorage = (data) => {
  //   if (data) {
  //     db.userData.clear();
  //     db.userData.put({ ...data }).then((user) => {});
  //     this.setState({ userData: data });
  //   }
  // };

  setUserProfile = async () => {
    this.setState({ varifySpinner: true });
    const inputData = {
      user_mobile: this.state.rawPhone,
      country_code: this.state.country_code,
      //school_id: this.props.match.params.schlId,
      school_id: this.state.userData.school_id,
      board_name: this.state.selectedBoard,
      lang_pref: this.state.selectedLanguage,
      grade_name: this.state.selectedGrade,
      migratedId: this.state.userData.migratedId, // this is for padhao login token generation
      id: this.state.userData.id,
    };
    console.log("here userData: ", this.state.userData, inputData);
    let authToken = getEncodedJWTToken(inputData);
    secureStorage.setItem("token", authToken);
    setAuthToken(authToken);
    setGlobal({ token: authToken });
    store.dispatch(initIO(authToken));
    setGlobal({ user: this.state.userData });

    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };

    const userProfileData = await api.post(
      `${config.apiEndPoint}/user`,
      inputData,
      {
        headers: headers,
      }
    );

    try {
      let response = await api.post(
        `${config.apiEndPoint}/sync_lrs_mongo_to_elastic`,
        userProfileData.id,
        {
          headers:  {"Content-Type": "application/json"},
        })
        console.log("response_newAPI",response)
      }
      catch (error) {
         console.log("err -", error);
      }

    window.profileData = userProfileData;
    this.props.login({ user: userProfileData });
    this.setState({ userData: userProfileData });
  };

  showUserData = (profileData) => {
    if (this.idUsageAllowed(profileData.config)) {
      this.parseProfileData(profileData);
      this.getLanguageList(profileData);
      this.getBoardList(profileData);
      window.profileData = profileData;
      this.checkStatusOfSelection();
    }
  };

  checkStatusOfSelection = async () => {
    if (
      this.state.selectedLanguage.length > 0 &&
      this.state.selectedBoard.length > 0 &&
      this.state.selectedGrade.length > 0
    ) {
      // Language, Board and Grade are available
      localStorage.setItem("I18N_LANGUAGE", this.state.selectedLanguage);
      await this.setUserProfile();
      this.apiCall();

      //Deleting content
      window.boardList = [];
      window.languageList = [];
      window.activatedGradesObject = [];
      window.activatedGradesList = [];
    } else {
      if (this.state.userData) {
        if (this.state.userData.school?.activatedLanguages?.length) {
          const activatedLanguages =
            this.state.userData.school.activatedLanguages;
          let selectedLanguage = this.state.selectedLanguage;

          if (activatedLanguages.length === 1) {
            selectedLanguage = activatedLanguages[0];
          } else if (
            this.state.userData.lang_pref &&
            activatedLanguages.includes(this.state.userData.lang_pref)
          ) {
            selectedLanguage = this.state.userData.lang_pref;
          }
          this.setState({ selectedLanguage });
        }
        if (
          this.state.userData.school.activatedBoards &&
          this.state.userData.school.activatedBoards.length === 1
        ) {
          let board = this.state.userData.school.activatedBoards[0];
          this.setState({
            selectedBoard: board.name,
          });
          if (board.activatedGrades && board.activatedGrades.length === 1) {
            this.setState({
              selectedGrade: board.activatedGrades[0],
            });
          }
        }
      }
      if (
        this.state.selectedLanguage.length > 0 &&
        this.state.selectedBoard.length > 0 &&
        this.state.selectedGrade.length > 0
      ) {
        // Language, Board and Grade are available
        localStorage.setItem("I18N_LANGUAGE", this.state.selectedLanguage);

        await this.setUserProfile();
        this.apiCall();

        //Deleting content
        window.boardList = [];
        window.languageList = [];
        window.activatedGradesObject = [];
        window.activatedGradesList = [];
      }
    }
    if (
      //Where nothing is selected
      this.state.selectedLanguage.length == 0 &&
      this.state.selectedBoard.length == 0 &&
      this.state.selectedGrade.length == 0
    ) {
      // Language, Board and Grade selection
      this.setState({ selectLanguagePopup: true });
    }
    if (
      this.state.selectedLanguage.length > 0 &&
      this.state.selectedBoard.length == 0 &&
      this.state.selectedGrade.length == 0
    ) {
      // Board and Grade selection
      this.setState({ selectLanguagePopup: false });
      this.setState({ selectBoardPopup: true });
    }
    if (
      this.state.selectedLanguage.length == 0 &&
      this.state.selectedBoard.length > 0 &&
      this.state.selectedGrade.length == 0
    ) {
      // Language and Grade selection
      this.setState({ selectLanguagePopup: true });
    }
    if (
      this.state.selectedLanguage.length == 0 &&
      this.state.selectedBoard.length == 0 &&
      this.state.selectedGrade.length > 0
    ) {
      // Language and Board selection
      this.setState({ selectLanguagePopup: true });
    }
    if (
      this.state.selectedLanguage.length > 0 &&
      this.state.selectedBoard.length > 0 &&
      this.state.selectedGrade.length == 0
    ) {
      // Grade selection
      this.setState({ selectBoardPopup: false });
      this.getGradesList(window.profileData);
      this.setState({ selectGradePopup: true });
    }
    if (
      this.state.selectedLanguage.length == 0 &&
      this.state.selectedBoard.length > 0 &&
      this.state.selectedGrade.length > 0
    ) {
      // Language selection
      this.setState({ selectLanguagePopup: true });
    }
    if (
      this.state.selectedLanguage.length > 0 &&
      this.state.selectedBoard.length == 0 &&
      this.state.selectedGrade.length > 0
    ) {
      // Board selection
      this.setState({ selectLanguagePopup: false });
      this.setState({ selectBoardPopup: true });
    }
  };

  getBoardList = (data) => {
    data.school.activatedBoards.forEach((element) => {
      window.boardList.push(element.name);
    });
  };

  getLanguageList = (data) => {
    data.school.activatedLanguages.forEach((element) => {
      window.languageList.push(element);
    });
  };

  getGradesList = (data) => {
    let temp = window.profileData.school.activatedBoards.filter((element) =>
      element.name.includes(this.state.selectedBoard)
    );
    window.activatedGradesList = [];
    temp[0].activatedGrades &&
      temp[0].activatedGrades.forEach((element) => {
        window.activatedGradesList.push(element);
      });
  };

  parseProfileData = (data) => {
    this.setState({
      selectedBoard: data.board_name,
      selectedLanguage: data.lang_pref,
      selectedGrade: data.grade_name,
    });
  };
  idUsageAllowed = (configData) => {
    if (configData.allowUsage === "true") {
      return true;
    } else {
      this.setState({ varifySpinner: false });
      alert(configData.cta.message);
      return false;
    }
  };

  apiCall = async () => {
    localStorage.setItem("I18N_LANGUAGE", this.state.selectedLanguage);
    this.props.i18n.changeLanguage(this.state.selectedLanguage);
    this.setState({ spinnerForFeed: true });
    this.setState({ varifySpinner: true });
    try {
      const userData = await getSelectedBoardAndGradeFromLocal();
      if (userData) {
        const queryString =
          userData.selectedBoard +
          `&gradeId=` +
          encodeURIComponent(userData.selectedGrade) +
          `&schoolId=` +
          userData.selectedSchool +
          `&language=` +
          userData.selectedLanguage +
          `&platform=web`;

        const subjectontentData = await getUserContentIfAvailable(queryString);
        if (subjectontentData) {
          this.setState({ spinnerForFeed: false });
          this.setState({ varifySpinner: false });
          if (
            subjectontentData.content.subjects &&
            subjectontentData.content.subjects.length != 0
          ) {
            window.mainData = subjectontentData.content.subjects;
            saveSubjectDataToLocalStorage(subjectontentData.content.subjects);
            subjectontentData.feed.items.forEach((element, index) => {
              let indexObject = {
                index: index,
              };
              let newElement = Object.assign(element, indexObject);
              return newElement;
            });
            saveFeedRailDataToLocalStorage(subjectontentData.feed.items); // storing feed rail data
     

            if ((window.profileData.user_type).trim() === "student") {
             
              this.props.history.replace("/concepts");
            } else if ((window.profileData.user_type).trim() === "superadmin") {
           
              this.props.history.replace("/select-school");
            } else {            
              this.props.history.replace("/");
            }

            this.props.setConfig({ redirectUrl: "" });
          } else {
            alert(this.props.t("onboarding.otp_login.no_grade_available"));
            this.props.history.push("/nocontent");
            this.setState({ spinnerForFeed: false });
            this.setState({ varifySpinner: false });
          }
        } else {
          alert(this.props.t("onboarding.otp_login.no_grade_available"));
          this.props.history.push("/nocontent");
          this.setState({ spinnerForFeed: false });
          this.setState({ varifySpinner: false });
        }
        const queryStringForLRS =
          `&user_id=` +
          userData.userId +
          `&board_name=` +
          userData.selectedBoard +
          `&grade_name=` +
          encodeURIComponent(userData.selectedGrade);
        const lrsSyncData = await getLRSContent(queryStringForLRS);
        await saveLRSSyncDataToLocalStorage(lrsSyncData.data.body, true);
      }
    } catch (error) {
      alert(error + ", Try again");
    }
  }
  //circularTimer on quiz
  circularTimer = (index) => {
    var countdownNumberEl = document.getElementById("countdown-number");
    if (countdownNumberEl) {
      var countdown = 30;
      countdownNumberEl.textContent = countdown + " s";
      this.state.timerCircle = true;
      this.state.timer_refresh = setInterval(async () => {
        countdown = --countdown < 0 ? 30 : countdown;
        countdownNumberEl.textContent = countdown + " s";
        if (countdown == 0) {
          await clearInterval(this.state.timer_refresh);

          this.setState({ showResend: true });
        }
      }, 1000);
    }
  };
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.sendOTP();
    }
  };
  handleCodeKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.sendOTPforCode();
    }
  };
  handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.sendOTP();
      return;
    }
    if ([69, 187, 188, 189, 190].includes(event.keyCode)) {
      event.preventDefault();
    }
  };
  handleCodeKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.sendOTPforCode();
      return;
    }
    if ([69, 187, 188, 189, 190].includes(event.keyCode)) {
      event.preventDefault();
    }
  };
  handleKeyPressForOTP = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.handleSubmit();
    } else {
      var charCode = event.which ? event.which : event.keyCode;
      return !(charCode > 31 && (charCode < 48 || charCode > 57));
    }
  };
  maxLengthValidation = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  componentDidMount() {
    localStorage.setItem("user_type", "student");
  }

  render() {
    let selectLanguageClose = () =>
      this.setState({ selectLanguagePopup: false });
    let selectBoardClose = () => this.setState({ selectBoardPopup: false });
    let selectGradeClose = () => this.setState({ selectGradePopup: false });
    let selectedLanguageFun = async (value) => {
      await this.setState({ selectedLanguage: value });
      this.checkStatusOfSelection();
    };
    let selectedBoardFun = async (value) => {
      await this.setState({ selectedBoard: value });
      this.checkStatusOfSelection();
    };
    let selectedGradeFun = async (value) => {
      await this.setState({ selectedGrade: value });
      this.checkStatusOfSelection();
    };


    const { t } = this.props;
    return (
      <div>
        <section className="login">
          <div className="container-fluid login-container uk-animation-customslide">

            <div id="content">
              <div
                className="col-md-12 no-gutters"
              >
                {!this.state.selectLanguagePopup &&
                  !this.state.selectBoardPopup &&
                  !this.state.selectGradePopup && (
                    <div className="width-100">
           
                      <div style={{backgroundColor:"#6da6ce",paddingBottom:80}} className="m-auto loginCard ">
                        <div className="row no-gutters my-auto">


                          <div className="col-xs-12 col-sm-12 col-md-12 my-auto text-center">
                            <img
                              src={logo_light}
                              width="200px"
                              height="auto"
                              className="img-fluid"
                            />
                          </div>

                          <div className="cardtitle text-center text-white">
                            {this.state.isJoinedClicked
                              ? this.props.t(
                                "onboarding.otp_login.sign_in_with_code"
                              )
                              : this.props.t(
                                "onboarding.otp_login.mobile_sign_in"
                              )}
                          </div>

                        </div>
                        <div className="row mt-2 ">
                          <div className="col-sm-7 mx-auto">
                            <div className="width-100 ">
                              {!this.state.isJoinedClicked && (
                                <div className="form-group">
                                  <div className="custom-input-style-normal border-radius-pill p-1">
                                    <PhoneInput
                                      country={"in"}
                                      enableSearch={true}
                                      value={this.state.userName}
                                                                    id="email"
                                      type="number"
                                      pattern="[0-9]*"
                                      inputmode="numeric"
                                      maxLength={14}
                                      onInput={this.maxLengthValidation}
                                      className=""
                                      name="email"
                                      placeholder="Enter your Mobile Number"
                                      onKeyPress={this.handleKeyPress}
                                      onChange={this.handleUsernameChange}
                                      onKeyDown={this.handleKeyDown}
                                      disabled={this.state.isOTPGenerated}
                                    />
                                  </div>
                                </div>
                              )}
                              {!this.state.spinner ? (
                                !this.state.isOTPGenerated && (
                                  <Fragment>
                                    {" "}
                                    <div className="form-group">
                                      <button
                                        type="button"
                                        className="card-button"
                                        onClick={this.sendOTP}
                                      >
                                        {this.props.t(
                                          "onboarding.otp_login.proceed"
                                        )}
                                      </button>
                                    </div>
                                    <div>
                                      <div className=" form-group text-center text-white">
                                        -{" "}
                                        {this.props.t(
                                          "onboarding.otp_login.OR"
                                        )}
                                        -
                                      </div>
                                      <div>
                                        <input
                                          id="code"
                                          type="number"
                                          pattern="[0-9]*"
                                          inputMode="numeric"
                                          maxLength={14}
                                          onInput={this.maxLengthValidation}
                                          className="input-with-button"
                                          placeholder={this.props.t(
                                            "onboarding.otp_login.join_with_14_digit_code"
                                          )}
                                          onKeyPress={this.handleCodeKeyPress}
                                          onChange={this.handleCodeChange}
                                          onKeyDown={this.handleCodeKeyDown}
                                          disabled={this.state.isOTPGenerated}
                                        />{" "}
                                        <button
                                          type="button"
                                          className="card-button-join"
                                          onClick={this.sendOTPforCode}
                                        >
                                          {this.props.t(
                                            "onboarding.otp_login.join"
                                          )}
                                        </button>
                                      </div>
                                    </div>
               
                                  </Fragment>
                                                      
                                )
                              ) : (
                                <div className="text-center card-button">
                                  <Spinner
                                    className=" "
                                    animation="border"
                                    style={{ color: "#fff" }}
                                    size="md"
                                  />
                                </div>
                              )}
                              
                            </div>
                            

                            {this.state.isOTPGenerated && (
                              <div>
                                <div className="input-group form-group">
                                  <input
                                    type="password"
                                    pattern="[0-9]*"
                                    inputmode="numeric"
                                    value={this.state.password}
                                    onChange={this.handlePasswordChange}
                                    className="form-control border-radius-pill text-center"
                                    placeholder={
                                      this.state.isJoinedClicked
                                        ? this.props.t(
                                          "onboarding.otp_login.password"
                                        )
                                        : this.props.t(
                                          "onboarding.otp_login.OTP"
                                        )
                                    }
                                    autoComplete="off"
                                    onKeyPress={this.handleKeyPressForOTP}
                                    maxLength={
                                      this.state.isJoinedClicked ? 20 : 4
                                    }
                                    onInput={this.maxLengthValidation}
                                    required
                                  />
                                </div>
                                <div
                                  className="input-group form-group"
                                  style={{ borderRadius: "50px" }}
                                >
                                  <div className=" col-md-12  time">
                                    {!this.state.showResend &&
                                      !this.state.isJoinedClicked && (
                                        <div
                                          id="countdown-number"
                                          className="timer-text-otp text-center text-white"
                                        ></div>
                                      )}
                                  </div>
                                  <div className=" col-md-12 text-right">
                                    {this.state.showResend &&
                                      !this.state.isJoinedClicked && (
                                        <a
                                          href="#"
                                          className="white-link-u cus_link_lineheight"
                                        >
                                          <Link
                                            onClick={this.sendOTP}
                                            className="white-link-u cus_link_lineheight"
                                          >
                                            {this.props.t(
                                              "onboarding.otp_login.resend_OTP"
                                            )}
                                          </Link>
                                        </a>
                                      )}
                                  </div>
                                </div>
                                {!this.state.varifySpinner ? (
                                  <div className="form-group">
                                    <button
                                      type="button"
                                      className="card-button"
                                      id
                                      onClick={this.handleSubmit}
                                    >
                                      {this.props.t(
                                        "onboarding.otp_login.proceed"
                                      )}
                                    </button>
                                  </div>
                                ) : (
                                  <div className="text-center card-button">
                                    <Spinner
                                      className=" "
                                      animation="border"
                                      style={{ color: "#fff" }}
                                      size="lg"
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className=" row px-2 d-flex justify-content-center">
                          <div style={{position:"absolute",bottom:"10px",fontSize:"13px",marginTop:"15px",color:"#fff"}}>
                           <p style={{position:"relative",paddingLeft:"30px",paddingRight:'30px'}}>By signing up, you agree to our <a style={{color: "#fff"}} href="https://www.stepapp.ai/privacy/" target="_blank"><u>privacy policy</u></a> and <a style={{"color": "#fff"}} href="https://www.stepapp.ai/term/" target="_blank"><u>terms & conditions</u></a> of use.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {this.state.selectLanguagePopup && (
                  <SelectLanguage
                    selectedlanguage={selectedLanguageFun}
                    spinnerForFeed={this.state.spinnerForFeed}
                    hidefunction={selectLanguageClose}
                  />
                )}
                {this.state.selectBoardPopup && (
                  <SelectBoard
                    selectedboard={selectedBoardFun}
                    spinnerForFeed={this.state.spinnerForFeed}
                    hidefunction={selectBoardClose}
                  />
                )}
                {this.state.selectGradePopup && (
                  <SelectGrade
                    selectedgrade={selectedGradeFun}
                    spinnerForFeed={this.state.spinnerForFeed}
                    hidefunction={selectGradeClose}
                  />
                )}
                {this.state.FillNamePopup && (
                  <FillName
                    hidefunction={selectGradeClose}
                  />
                )}
              </div>
              <div
                id="copyright"
                className="uk-position-bottom-center uk-position-small uk-visible@m uk-position-z-index"
              >
                <div className="col-sm-12 mx-auto my-auto ">
                  <div className=" text-center my-auto footer-text ">
                    {" "}
                    {this.props.t("footer.all_right_reserved")} |{" "}
                    {this.props.t("footer.stepapp")} {new Date().getFullYear()}{" "}
                    | {this.props.t("footer.version")}{" "}
                    {process.env.REACT_APP_VERSION}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = { login, save, setConfig };

const mapStateToProps = ({ config }) => ({
  redirectUrl: config?.redirectUrl,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(StudentLogin)));
