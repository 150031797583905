import React,{useState} from 'react'
import {useEffect} from 'react';
import {Spinner} from 'reactstrap';
import {showErrorMessage} from "services/utility";

const FillName = (props) => {


  const [firstName,setFirstName] = useState("")
  const [lastName,setLastName] = useState("")
  const [email,setEmail] = useState("")
  const [spinnerForFeed,setSpinnerForFeed] = useState(false)

  const onProceed = (e) => {
    e.preventDefault()
    if(firstName === "" || lastName === "" || email === "") {
      showErrorMessage("Kindly enter all Details",3);
    } else if(email.length > 0 && !new RegExp('^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)@[a-z0-9-]+(\.[a-z0-9-]+)(\.[a-z]{2,4})$').test(email)) {
      showErrorMessage("Email Not valid",3);
    } else if(firstName === "" || lastName === "" || email === "") {
      showErrorMessage("Kindly enter all Details",3);
    } else if(firstName.length > 0 && firstName.length > 0 && new RegExp('^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)@[a-z0-9-]+(\.[a-z0-9-]+)(\.[a-z]{2,4})$').test(email)) {
      setSpinnerForFeed(true)
      props.selectedName(firstName,lastName,email)
    }
  }

  let disabledAddButton={ height: "40px", backgroundColor: "#A5A5A5", border: "none", borderRadius: "0.5rem"}
  let enabledAddButton={ height: "40px", backgroundColor: "#FC8643", cursor: "pointer", border: "none", borderRadius: "0.5rem", color: "#ffffff"}

  console.log("firstname_is", firstName)

  return (
    <div className="uk-cover-container uk-flex uk-flex-center uk-flex-middle uk-overflow-hidden uk-light">
      <div className="uk-flex uk-flex-center uk-flex-middle uk-position-z-index">
        <div style={{width: "min(90vw, 400px)",borderRadius: "2rem",backgroundColor: "#fff",padding: "0.7rem 2rem"}}>
          <div className='text-left my-4' style={{font: "normal normal 500 1.2rem/1.6rem Poppins",color: "#FC8643"}}>Personal Details</div>
          <div className="d-flex flex-column mb-3">
      <div className="d-flex justify-content-start" style={{color: "#767676",font: "normal normal 400 0.7rem/1.2rem Poppins"}}>First name</div>
        <div className="d-flex justify-content-center p-2" style={{border: "1px solid #969696",height: "45px",borderRadius: "8px"}}>
          <input 
            className="w-100 pl-2" 
            type="text"
            placeholder="Enter here"
            style={{border: "none",font: "normal normal 500 0.9rem/1.2rem Poppins",color: "black", background: "#ffffff"}}
            onChange={(e)=> setFirstName(e.target.value)}
            />
        </div>
      </div>
      <div className="d-flex flex-column mb-3">
      <div className="d-flex justify-content-start" style={{color: "#767676",font: "normal normal 400 0.7rem/1.2rem Poppins"}}>Last name</div>
        <div className="d-flex justify-content-center p-2" style={{border: "1px solid #969696",height: "45px",borderRadius: "8px"}}>
          <input 
            className="w-100 pl-2" 
            type="text"
            placeholder="Enter here"
            style={{border: "none",font: "normal normal 500 0.9rem/1.2rem Poppins",color: "black", background: "#ffffff"}}
            onChange={(e)=> setLastName(e.target.value)}
            />
        </div>
      </div>
      <div className="d-flex flex-column mb-3">
      <div className="d-flex justify-content-start" style={{color: "#767676",font: "normal normal 400 0.7rem/1.2rem Poppins"}}>Parent Email</div>
        <div className="d-flex justify-content-center p-2" style={{border: "1px solid #969696",height: "45px",borderRadius: "8px"}}>
          <input 
            className="w-100 pl-2" 
            type="email"
            placeholder="Enter here"
            style={{border: "none",font: "normal normal 500 0.9rem/1.2rem Poppins",color: "black", background: "#ffffff"}}
            onChange={(e)=> setEmail(e.target.value)}
            />
        </div>
      </div>
          <div className='row m-0'>
            <button
              className='w-100'
              disabled={firstName === "" || lastName === "" || email === "" ? true : false}
              style={firstName === "" || lastName === "" || email === "" ? disabledAddButton : enabledAddButton}
              onClick={onProceed}
            >
              Proceed
            </button>
          </div>
          <div className='row m-0 justify-content-center my-2'>
            <div style={{font: "normal normal 600 0.7rem/1.2rem Poppins", color: "#2B2B2B"}}>These details will be useful for sending progress reports and certificates. The email will be of further assistance in recovering your password.</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FillName