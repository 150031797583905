import React, { useEffect } from 'react'
import * as moment from "moment"
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next"
import { useState } from 'react';
import store from 'store/store';
import api from '../../../../services/api'
import { Spinner } from 'reactstrap';
import '../../QuizListing.scss'
import GaugeChart from "../common/gaugeChart"
import Histogram from "../common/Histogram"
import StudentListTable from "../common/StudentListTable"
import PyramidChart from "../../../Quizzes/components/Quiz/components/Pyramid Chart";
import StudentLeaderBoardTable from './StudentLeaderBoardTable';
import QuestionwiseTable from './QuestionwiseTable';

const QuizReport = (props) => {

    const [activeButton, setActiveButton] = useState("overview")
    const [studentData, setStudentData] = useState({})
    const [loader, setLoader] = useState(true)
    const [questionsList, setQuestionsList] = useState([])

    let value = props.location.state
    let t = props.t

    useEffect(() => {
        studentDetails();
        QuestionDetails()
    }, [])

    const studentDetails = async () => {
        setLoader(true)
        const { user } = store.getState();

        const queryString =

            `?batch=` +
            value.batch +
            `&quiz_id=` +
            value.assessmentID +
            `&board_name=` +
            value.board +
            `&grade_name=` +
            encodeURIComponent(value.grade) +
            `&school_id=` +
            user.school_id +
            `&report_type=quiz-report`

        let url = `https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/teacher-chapter-homework-report${queryString}`
        let data = await api.get(url.split(' ').join(''))

        if (data.status === 200) {
            setStudentData({})
            setStudentData(data.data)
            setLoader(false)

        }
    }

    const QuestionDetails = async () => {
        const queryString = `?report_type=question-report&quiz_id=` + value.assessmentID

        let url = `https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/teacher-chapter-homework-report${queryString}`
        let data = await api.get(url.split(' ').join(''))

        console.log("ques_data", data)
        if (data.status === 200) {
            setQuestionsList([])
            setQuestionsList(data.data.body)
        }
    }

    const goBack = () => {
        props.history.goBack();
    };

    const UpdatedAt = (time) => {
        var currentdate = new Date()
        var offset = currentdate.getTimezoneOffset()
        var start_date = moment(time).subtract(offset, "minutes")
        var end_date = moment(currentdate, "YYYY-MM-DD HH:mm:ss")
        var duration = moment.duration(end_date.diff(start_date))
        let days = duration.days()
        let hours = duration.hours()
        let minutes = duration.minutes()
        if (duration.days() === 1) {
            return `${days} ${props.t(
                "teacher_create_quiz.quiz_listing.day_ago"
            )}`
        }
        if (duration.days() > 1) {
            return `${days} ${props.t(
                "teacher_create_quiz.quiz_listing.days_ago"
            )}`
        }
        if (duration.days() === 0 && duration.hours() === 1) {
            return `${hours} ${props.t(
                "teacher_create_quiz.quiz_listing.hour_ago"
            )}`
        }
        if (duration.days() === 0 && duration.hours() > 1) {
            return `${hours} ${props.t(
                "teacher_create_quiz.quiz_listing.hours_ago"
            )}`
        }
        if (duration.days() === 0 && duration.hours() === 0) {
            return `${minutes} ${props.t(
                "teacher_create_quiz.quiz_listing.mins_ago"
            )}`
        }
    }


    const buttonClickHandler = (val) => {
        setActiveButton(val)
    }


    return (
        <div id="" className="mb-2" style={{ height: "100%", overflow: "scroll", }}>
            <div className="container uk-animation-customslide">
                <div className="row no-gutters mb-4 head-bread-crumb">
                    <div className="back-nav" onClick={goBack}>
                        <span className="icon-back-arrow"></span>
                    </div>
                </div>
                {loader ? <div className='row justify-content-center'><Spinner /></div> : <div className='main_wrapper'>
                    <div className="row no-gutters justify-content-center my-3">
                        <div className="p-3 col-sm-12" style={{ maxWidth: "550px" }}>
                            <div className="border border-primary p-3 rounded">
                                <div>
                                    <div className="d-flex align-center justify-content-between">
                                        <span className='pt-normal font-weight-bold'>
                                            {value.quiz}
                                        </span>
                                        <div>
                                            <div className="rounded ml-1" style={{ backgroundColor: "#FF8855", padding: '0.5rem' }}>
                                                <span style={{ font: "normal normal 400 18px/27px Poppins", color: "#fff" }}>{value.subject}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-center justify-content-between">
                                        {value.type === "quiz" ? (
                                            <span className="pt-small rounded px-2 py-1 text-light font-weight-normal" style={{ backgroundColor: "#15B0BF" }}>{t("report_page.quiz")}</span>
                                        ) :
                                            <span style={{ background: "#FEC00F" }}>Test</span>
                                        }
                                    </div>
                                    <div className="row py-3 d-flex align-center">
                                        <div className="col-6 pt-small font-weight-bold">
                                            Grade {value.grade} {value.batch}
                                        </div>
                                        <div className="col-6 pt-small font-weight-normal" style={{ color: "#767676" }}>
                                            Updated {UpdatedAt(value.updated_at)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters w-100 py-3 mb-4 rounded pl-2 d-flex justify-content-center justify-content-md-start" style={{ background: "#FFF2EB" }}>
                        <div className='col-xs-2'>
                            <div
                                className={`classListItem ${activeButton === "overview" ? 'active' : ""} border border-primary`}
                                onClick={() => buttonClickHandler("overview")} style={{ color: "2B2B2B" }}>Overview</div>
                        </div>
                        <div className='col-xs-2'>
                            <div
                                className={`classListItem ${activeButton === "stuWise" ? 'active' : ""} border border-primary`}
                                onClick={() => buttonClickHandler("stuWise")} style={{ color: "2B2B2B" }}>Student wise</div>
                        </div>
                        <div className='col-xs-2'>
                            <div
                                className={`classListItem ${activeButton === "queWise" ? 'active' : ""} border border-primary`}
                                onClick={() => buttonClickHandler("queWise")} style={{ color: "2B2B2B" }}>Question wise</div>
                        </div>
                    </div>
                    {<div className="">
                        {activeButton === "overview" && <div>
                            <div className="row d-flex justify-content-between">
                                <div className="col-md-7 col-sm-12 ">
                                    <div className="">
                                        <Histogram displayChapterData={studentData} />
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-10">
                                    <div>
                                        <GaugeChart displayChapterData={studentData} />
                                    </div>
                                </div>
                            </div>
                            <div className="divider" />
                            <div className="row">
                                <div className='col-md-7 col-sm-12'>
                                    <PyramidChart
                                        bloomRecallData={studentData.bloom.rightRecallCount + "/" + (studentData.bloom.rightRecallCount + studentData.bloom.wrongRecallCount)}
                                        bloomUnderstandData={studentData.bloom.rightUnderstandCount + "/" + (studentData.bloom.rightUnderstandCount + studentData.bloom.wrongUnderstandCount)}
                                        bloomApplyData={studentData.bloom.rightApplyCount + "/" + (studentData.bloom.rightApplyCount + studentData.bloom.wrongApplyCount)}
                                        bloomAnalyseData={studentData.bloom.rightAnalyseCount + "/" + (studentData.bloom.rightAnalyseCount + studentData.bloom.wrongAnalyseCount)}
                                        bloomEvaluateData={studentData.bloom.rightEvaluateCount + "/" + (studentData.bloom.rightEvaluateCount + studentData.bloom.wrongEvaluateCount)}
                                        bloomCreateData={studentData.bloom.rightCreateCount + "/" + (studentData.bloom.rightCreateCount + studentData.bloom.wrongCreateCount)}
                                    />
                                </div>
                                <div style={{ width: "90%", height: "20px", backgroundColor: "#fff", display: "flex", marginTop: "-12px", position: 'relative' }} />
                            </div>
                            <div className="divider" />
                            <div className="listingTable">

                                <StudentListTable totalMarks={studentData.maximum_marks} displayChapterData={studentData} {...props} mail_type="quiz" />

                            </div>
                        </div>
                        }
                        {activeButton === "stuWise" && <div>
                            <StudentLeaderBoardTable totalMarks={studentData.maximum_marks} participate={studentData.active_student_list} notparticipate={studentData.inactive_student_list} />
                        </div>}
                        {activeButton === "queWise" && <div>
                            <QuestionwiseTable questionsList={questionsList} />
                        </div>}
                    </div>}
                    <div className='row my-3' style={{ visibility: "hidden" }}>
                        <h1>Space</h1>
                    </div>
                </div>}
            </div>

            <div style={{ visibility: 'hidden', minHeight: '55px', backgroundColor: '#000' }}>

            </div>
        </div>
    )
}

export default withTranslation()(withRouter(QuizReport))