import api from "../../services/api.js";
import db from "../../services/IndexDBStorage";
import config from "../../pwaConfig.json";
import { syncLRSDataWithServer } from "./userContentService";
import { secureStorage, getEncodedJWTToken } from "services/utility";
import store from "../../store/store";
import { save as saveUser } from "reducers/user/actions";

export async function getUserProfileData() {
  var { user } = store.getState();

  if (user) {
    return user;
  }
  return null;
}

export async function saveUserDataToLocalStorage(data) {
  if (data) {
    store.dispatch(saveUser({ user: data }));
  }
}

export async function getSelectedBoardAndGradeFromLocal() {
  const { user } = store.getState();
  
  if (user) {
    let selectedBoard = user.board_name;
    let selectedGrade = user.grade_name;
    let selectedLanguage = user.lang_pref;
    let selectedSchool = user.school_id;
    let userId = user.id;

    if (
      selectedBoard &&
      selectedBoard.length > 0 &&
      selectedGrade &&
      selectedGrade.length > 0 &&
      selectedLanguage &&
      selectedLanguage.length > 0 &&
      selectedSchool &&
      selectedSchool > 0 &&
      userId &&
      userId.length > 0
    ) {
      return {
        selectedBoard: selectedBoard,
        selectedGrade: selectedGrade,
        selectedLanguage: selectedLanguage,
        selectedSchool: selectedSchool,
        userId: userId,
      };
    }
  }
  //return null;
}

export async function setUserProfile(userdata) {
  const inputData = {
    avatar:userdata.avatar,
    user_mobile: userdata.user_mobile,
    country_code: userdata.country_code,
    school_id: userdata.school_id,
    board_name: userdata.board_name,
    lang_pref: userdata.lang_pref,
    grade_name: userdata.grade_name,
    first_name: userdata.first_name,
    last_name: userdata.last_name,
    user_email: userdata.user_email,
  };

  let authToken = getEncodedJWTToken(inputData);
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  try {
    const userProfileData = await api.post(
      `${config.apiEndPoint}/user`,
      inputData,
      {
        headers: headers,
      }    
    );
    //check here API response not bind to userProfileData
    console.log("user profile data userProfileData--->", userProfileData);
 
    try {
    let response = await api.post(
      `${config.apiEndPoint}/sync_lrs_mongo_to_elastic`,
      userProfileData.id,
      {
        headers:  {"Content-Type": "application/json"},
      })
      console.log("response_newAPI",response)
      
    }
    catch (error) {
       console.log("err -", error);
    }

    await saveUserDataToLocalStorage(userProfileData);
    return userProfileData;
  } catch (error) {
    return null;
  }
}

export async function getUserProfile(mobileNo, schoolId, countryCode) {
  let inputData = {
    user_mobile: mobileNo,
    country_code: countryCode,
    school_id: schoolId,
  };
  if (!countryCode || String(countryCode).length === 0) {
    inputData = {
      user_mobile: mobileNo,
      school_id: schoolId,
    };
  }
  let authToken = await getEncodedJWTToken(inputData);
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  try {
    api
      .post(`${config.apiEndPoint}/user`, inputData, {
        headers: headers,
      })
      .then((response) => {
        var res = response;
        
        saveUserDataToLocalStorage(res);
        // update your context here instead of returning
        return res;
      });
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function clearData() {
  await db.lrsData.clear();
  await db.subjectContent.clear();
  await db.lrsSync.clear();
  await db.feedRailDataUpdated.clear();
  await db.bookmarks.clear();
  await db.questionBookmark.clear();
  await db.chapterData.clear();
}
export async function setUserLoggedOut() {
  await syncLRSDataWithServer();
  await clearData();

  // await db.close();
  secureStorage.removeItem("userLoggedIn");
}

export async function getCompletedTopicsFromUserData(subjectslug, chapterslug) {
  const { user } = store.getState();
  
  console.log("User 1", user)
  if (user) {
    var subjects = await user.subjectDetails;
  }
  console.log("User 2", subjects)

  if (subjects && subjects.length > 0) {
    let filteredSubjects = subjects.filter(
      (subject) => subject.slug === subjectslug
    );
    console.log("User 3", filteredSubjects)
    if (filteredSubjects && filteredSubjects.length > 0) {
      let filteredChapter = filteredSubjects[0].chapter.filter(
        (chapter) => chapter.slug === chapterslug
      );
      console.log("User 4", filteredChapter)
      if (filteredChapter && filteredChapter.length > 0) {
        console.log("User 5", filteredChapter[0].topic)
        return filteredChapter[0].topic;
        
      }
    }
  }
  return null;
}

export async function getSelectedTopicFromUserData(
  subjectslug,
  chapterslug,
  topicslug
) {
  let completedTopics = await getCompletedTopicsFromUserData(
    subjectslug,
    chapterslug
  );
  if (completedTopics) {
    let filteredTopic = completedTopics.filter(
      (topic) => topic.slug === topicslug
    );
    if (filteredTopic && filteredTopic.length > 0) {
      return filteredTopic[0];
    }
  }
  return null;
}
