import React, { Component } from "react";
import { Spinner } from "reactstrap";
import PropTypes from "prop-types";
import { selectLanguageConvert, showErrorMessage } from "services/utility";
import analytics from "services/firebaseServices/firebaseAnalytics";
import { withTranslation } from "react-i18next";
import '../onboarding.scss'

export class SelectLanguage extends Component {
  constructor(props) {
    super(props);

    analytics.logEvent("language_screen_launched_in_onboarding", {
      languageScreen: true,
    });
  }
  state = {
    selectedLanguage: "",
    spinnerForFeed: this.props.spinnerForFeed,
  };
  // componentWillReceiveProps() {
  //   this.setState({ spinnerForFeed: this.props.spinnerForFeed });
  // }

  spinnerState() {
    this.setState({ spinnerForFeed: true });
  }

  onProceed(e) {
    e.preventDefault();
    if (this.state.selectedLanguage.length > 0) {
      this.spinnerState();
      this.props.selectedlanguage(this.state.selectedLanguage);
    } else {
      showErrorMessage("Please select Language", 1);
    }
  }

  disabledButton={ height: "40px", backgroundColor: "#A5A5A5", border: "none", borderRadius: "8px"}
  enabledButton={ height: "40px", backgroundColor: "#FC8643", cursor: "pointer", border: "none", borderRadius: "8px", color: "#ffffff"}

  render() {
    let selectedLanguageState =  (value) => {
      console.log("recived_value",value)
      this.setState({ selectedLanguage: value});
      analytics.logEvent("language_selected_in_onboarding", {
        selectedLanguage: value,
      });
    };

    return (
      <div className="uk-cover-container uk-flex uk-flex-center uk-flex-middle uk-overflow-hidden uk-light">
      <div className="uk-flex uk-flex-center uk-flex-middle uk-position-z-index">
      <div style={{width: "min(90vw, 400px)",borderRadius: "2rem",backgroundColor: "#fff",padding: "0.7rem 2rem"}}>
        <div className='text-left my-4' style={{font: "normal normal 500 1.2rem/1.6rem Poppins",color: "#FC8643"}}>Select Language</div>
        <div style={{display: "grid",placeItems:"center"}}>
            {["en"].map((element,i) => {
              return (
                <div
                  key={i}
                //  style={{ border: "1px solid #969696",borderRadius: "8px", height: "2.8rem", position: "relative", display: "grid", placeItems: "center"}}
                  onClick={(e) => {
                    selectedLanguageState(element)
                  }}
                  className="input_label_container"
                >
                  <input
                    type="radio"
                    name="radio1"
                    id={element}
                    defaultValue={element}
                  />
                  <label className="mb-0 label_styles px-3" htmlFor={element} 
                  //style={{color: "#767676", border: "none", display: "grid", placeItems: "center", width: "100%", height: "100%"}}
                  >{selectLanguageConvert(element)}</label>
                </div>
              )
            })}
        </div>
        {!this.props.spinnerForFeed ? 
              <button
                type="submit"
                className="w-100 mt-3"
                style={this.state.selectedLanguage.length > 0 ? this.enabledButton : this.disabledButton }
                disabled={this.state.selectedLanguage.length > 0 ? false : true }
                onClick={(e) => {this.onProceed(e)}}
              >
                Proceed
              </button>
              :
              <div className="text-center mt-2" style={{height: "3rem", borderRadius: "8px"}}>
                <Spinner
                  animation="border"
                  style={{ color: "#1b1f5f" }}
                  size="lg"
                />
              </div>
            }
            <div className='mt-3' style={{font: "normal normal 600 0.7rem/1.2rem Poppins", color: "#2B2B2B"}}>
              Select the language to get started.
            </div>
      </div>
      </div>
    </div>
    
    );
  }
}

export default withTranslation()(SelectLanguage);
